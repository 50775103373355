import React, { useCallback, useMemo, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import block from 'bem-cn'

import { MODAL_ROUTE_PATH, MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

import { useOutsideClick } from 'shared/hooks/useOutsideClick'
import { mediaSize } from 'shared/style/var'
import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { selectLocaleDict } from 'features/locale/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { ReactComponent as Live1Icon } from './img/live1.svg'
import { ReactComponent as Live2Icon } from './img/live2.svg'
import { ReactComponent as Sport1Icon } from './img/sport1.svg'
import { ReactComponent as Sport2Icon } from './img/sport2.svg'

import './ChooseSport.scss'

export type ChooseSportType = 'sport' | 'live'

const b = block('choose-sport')

export const ChooseSport = ({ path }: { path: string }) => {
  const { closeModal } = useModalContext()
  const history = useHistory()

  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })

  const modalRef = useRef<HTMLDivElement>(null)

  const params: { type: ChooseSportType } | null = getRouteParamsByStrings(MODAL_ROUTE_PATH.CHOOSE_SPORT, path)

  useOutsideClick(modalRef, () => {
    closeModal(MODAL_ROUTE_URLS.CHOOSE_SPORT(params?.type!))()
  })

  const locale = useSelector(selectLocaleDict, shallowEqual).common

  const onChooseSport = useCallback((route: string) => {
    history.push(route)
  }, [history])

  const digitainLiveLink = useMemo(
    () => isMobileOrTablet
    ? ROUTE_URLS.DIGITAIN_SPORT_PAGE('Live')
    : ROUTE_URLS.DIGITAIN_SPORT_PAGE('Overview'),
    [isMobileOrTablet],
  )

  return (
    <div ref={modalRef} className={b()}>
      <div className={b('content')}>
        {params?.type === 'live' ? (
          <>
            <div
              className={b('sport')}
              onClick={() => onChooseSport(digitainLiveLink)}
            >
              <Live1Icon className={b('sport-icon')} />
              <span className={b('sport-text')}>{`${locale.liveSport} 1`}</span>
            </div>
            <div className={b('sport')} onClick={() => onChooseSport(ROUTE_URLS.BETBY_SPORT('live'))}>
              <Live2Icon className={b('sport-icon')} />
              <span className={b('sport-text')}>{`${locale.liveSport} 2`}</span>
            </div>
          </>
        ) : (
          <>
            <div className={b('sport')} onClick={() => onChooseSport(ROUTE_URLS.DIGITAIN_SPORT_PAGE('Home'))}>
              <Sport1Icon className={b('sport-icon')} />
              <span className={b('sport-text')}>{`${locale.sport} 1`}</span>
            </div>
            <div className={b('sport')} onClick={() => onChooseSport(ROUTE_URLS.BETBY_SPORT())}>
              <Sport2Icon className={b('sport-icon')} />
              <span className={b('sport-text')}>{`${locale.sport} 2`}</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
