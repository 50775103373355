import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { INotifiesState } from '../types'

export default combineReducers<INotifiesState['communications']>({
  getLastFirstDeposit: makeCommunicationReducer(actions.getFirstDeposit),
  getCashbackList: makeCommunicationReducer(actions.getCashbackList),
  getPersonalJackpots: makeCommunicationReducer(actions.getPersonalJackpots),
})
