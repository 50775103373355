import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

export const getJackpotList = makeCommunicationActionCreator({
  loading: '@jackpots/GET_JACKPOT_LIST_LOADING',
  success: '@jackpots/GET_JACKPOT_LIST_SUCCESS',
  error: '@jackpots/GET_JACKPOT_LIST_ERROR',
  reset: '@jackpots/GET_JACKPOT_LIST_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.jackpots.getJackpots()
    return response.data
  },
)
