/* eslint-disable max-len */
import { QueryTypes } from 'services/api/data'

import { slotsCasinoRoute } from 'shared/constants/routes/slotsRoutes'
import { languagesType, shortLanguages } from 'shared/locale'

import GameCatalogConverter from './providersData/GameCatalog/GameCatalogConverter'
import OutcomeConverter from './providersData/Outcome/OutcomeConverter'
import SoftwissConverter from './providersData/Softswiss/SoftwissConverter'
import { ISlotProvidersConfiguration, Providers } from './types/configuration'
import { ISlotViewItem } from './types/interfaces/Slots/Slots'

type ConvertorsTypes = OutcomeConverter | SoftwissConverter | GameCatalogConverter

const OutcomeConverterClass = new OutcomeConverter()
// const SoftwissConvterClass = new SoftwissConverter()
const GameCatalogConverterClass = new GameCatalogConverter()

export const slotProvidersConfiguration: ISlotProvidersConfiguration<ConvertorsTypes> = {
  // [Providers.softwiss]: {
  //   gameListUrl: 'api/integrations/softswiss/v2/games/',
  //   converter: SoftwissConvterClass,
  //   multiProvider: true,
  //   slotsCount: 2939,
  //   sessionUrlData: {
  //     url: 'api/integrations/softswiss/createsession/',
  //     demoUrl: 'api/integrations/softswiss/demostart/',
  //     method: QueryTypes.POST,
  //     getBody: params => ({
  //       game: params.gameId,
  //       return_url: `${location.origin}${slotsCasinoRoute}`,
  //       client_type: params.isDesktop ? 'desktop' : 'mobile',
  //       language: params?.lang ? shortLanguages[params.lang as languagesType] : undefined,
  //     }),
  //     converter: SoftwissConvterClass.convertGameUrl,
  //   },
  // },

  [Providers.otc]: {
    // authRequired: true,
    gameListUrl: 'api/integrations/outcome/v2/gamelist/',
    converter: OutcomeConverterClass,
    slotsCount: 878,
    sessionUrlData: {
      url: 'api/integrations/outcome/v2/session/create/',
      demoUrl: 'api/integrations/outcome/v2/session/create/demo/',
      freeSpinUrl: 'api/integrations/outcome/v2/freespin/session/create/',
      freeSpinInfoUrl: 'api/users/freespin-session/',
      method: QueryTypes.POST,
      getBody: params => ({
        GameId: params.gameId,
        Language: params?.lang ? shortLanguages[params.lang as languagesType] : undefined,
      }),
      converter: OutcomeConverterClass.convertGameUrl,
      freeSpinInfoConverter: OutcomeConverterClass.convertFreeSpinInfo,
      getHeaders: () => ({ 'Content-Type': 'application/json' }),
    },
    multiProvider: true,
  },

  // [Providers['028142ca']]: {
  //   // gameListUrl: 'services/game-catalog/gamelist?integration=rebelbet&page_size=1000',
  //   gameListUrl:
  //     'https://new.bcraftservices.com/services/game-catalog/gamelist?integration=028142ca&producer=evosw,pgsoft,spribe&page_size=1000',
  //   converter: GameCatalogConverterClass,
  //   slotsCount: 101,
  //   sessionUrlData: {
  //     url: 'api/integrations/028142ca/login',
  //     method: QueryTypes.POST,
  //     getBody: params => ({
  //       is_mobile: +!params.isDesktop,
  //       system: params.gameList.find((t: ISlotViewItem) => getPureGameId(t.gameId) === params.gameId)?.additionalData
  //         ?.system,
  //       page_code: params.gameId,
  //       language: params?.lang ? shortLanguages[params.lang as languagesType] : undefined,
  //     }),
  //     converter: GameCatalogConverterClass.convertGameUrl,
  //   },
  // },

  [Providers['08f21347']]: {
    gameListUrl:
      // eslint-disable-next-line max-len
      'https://new.bcraftservices.com/services/game-catalog/gamelist?integration=08f21347&page_size=1000',
    converter: GameCatalogConverterClass,
    slotsCount: 101,
    sessionUrlData: {
      url: 'api/integrations/08f21347/login',
      method: QueryTypes.POST,
      getBody: params => ({
        symbol: params.gameId,
        platform: params.isDesktop ? 'WEB' : 'MOBILE',
        play_mode: 'REAL',
        language: params?.lang ? shortLanguages[params.lang as languagesType] : undefined,
      }),
      converter: GameCatalogConverterClass.convertGameUrlPragmatic,
    },
  },

  // b480999a: {
  //   gameListUrl:
  //     'https://new.bcraftservices.com/services/game-catalog/gamelist?integration=b480999a&page_size=1000',
  //   converter: GameCatalogConverterClass,
  //   sessionUrlData: {
  //     url: 'api/integrations/b480999a/login',
  //     method: QueryTypes.POST,
  //     getBody: params => ({
  //       game_id: params.gameId,
  //       language: params?.lang ? shortLanguages[params.lang as languagesType] : undefined,
  //     }),
  //     converter: GameCatalogConverterClass.convertGameUrlBcraft,
  //   },
  // },
}

export const liveGamesProviders: string[] = Object.values(slotProvidersConfiguration).reduce(
  (acc, t) => [...acc, ...(t.liveGamesProviders || [])],
  [] as string[],
)

// В default list не забывать задавать поле isDefault, щобы не дублировалось
