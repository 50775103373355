import { ICashbackHistoryFilters, IGetBonusHistoryFilters } from 'shared/models/BonusSystem'
import { deleteEmptyFields } from 'shared/utils/filters'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { BonusSystemConverter } from './BonusSystemConverter'

class BonusSystemApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/bonus-system`

    this.converter = new BonusSystemConverter()
  }

  async exchangeBonusBalanceToBalance({ amount }: { amount: number }) {
    return this.sendQuery({
      url: `${this.baseUrl}/bonusbalance-to-balance/`,
      type: QueryTypes.POST,
      data: { amount },
    })
  }

  async loadBonusHistory(filters: IGetBonusHistoryFilters) {
    const filtersWithFields = deleteEmptyFields(filters)

    return this.sendQuery({
      url: `${this.baseUrl}/bonusbalance/history`,
      type: QueryTypes.GET,
      data: filtersWithFields,
      converterSuccess: this.converter.convertBonusHistory,
    })
  }

  async loadCashbackHistory(filters: ICashbackHistoryFilters) {
    const filtersWithFields = deleteEmptyFields(filters)

    return this.sendQuery({
      url: `${this.baseUrl}/cashback/history`,
      type: QueryTypes.GET,
      data: filtersWithFields,
      converterSuccess: this.converter.convertBonusHistory,
    })
  }
}

export default BonusSystemApi