import styled from 'styled-components'

import { device } from 'shared/style/var'

interface IStyledAuthInputWrapperProps {
  withBottomText?: boolean
}

export const StyledAuthForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 14px;
  width: 100%;
  padding: 20px;
`
export const StyledAuthHeader = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 15px;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
`

export const StyledAuthLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  svg {
    height: 50px;
    path:first-child {
      fill: rgb(117, 86, 190);
    }
  }
`

export const StyledAuthBody = styled.div`
  color: #000;
  text-align: center;
  margin: 1rem 0;
  font-size: 1.3rem;
`

export const StyledAuthPaginator = styled.div`
  border-top: 1px solid #ccc;
  align-items: center;
  justify-content: center;
  height: 9rem;
  color: rgb(77, 77, 77);
  padding: 2rem 2rem;
  display: block;
  width: 100%;
  text-align: center;
`
export const StyledAuthPaginatorItem = styled.span`
  align-items: center;
  justify-content: center;
  color: rgb(77, 77, 77);
  font-size: 1.4rem;
  display: block;
  width: 100%;
  text-align: center;
`
export const StyledAuthPaginatorLink = styled.div`
  display: inline;
  text-align: center;
  text-decoration: none;
  margin-left: 5px;
  color: #095a89;
  cursor: pointer;
`

export const StyledAuthInputWrapper = styled.div<IStyledAuthInputWrapperProps>`
  width: 100%;
  height: 42px;
  margin-bottom: 10px;
  position relative;
  input {
    &:disabled {
      color: #000;
    }
  }
  ${props => (props.withBottomText ? 'height: 75px' : '')}
`

export const StyledAuthHeaderLocale = styled.span`
  text-transform: lowercase;
  margin-left: 0.5rem;
`

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: inline-block;
  margin: 1.7rem 0;
  height: 48px;
`

export const StyledAuthModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  @media screen and ${device.laptop} {
    width: 435px;
  }
  @media screen and ${device.laptopMax} {
    width: 310px;
  }
`

export const StyledAuthHr = styled.hr`
  margin: 0;
  width: 100%;
  border: 1px solid #2b2b2b;
  border-bottom: none;
`

export const StyledAuthHeaderCloseIcon = styled.div``

export const StyledAuthInputLabel = styled.label`
  color: #fff;
`
