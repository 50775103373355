import React from 'react'
import { Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import { ROUTE_PATHS } from 'router/routes'

const DigitainSportPage = React.lazy(() => import('pages/DigitainSportPage/DigitainSportPage'))

export class DigitainSportModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.DIGITAIN_SPORT_PAGE} exact path={ROUTE_PATHS.DIGITAIN_SPORT_PAGE}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <DigitainSportPage />
        </QueryParamProvider>
      </Route>
    )
  }
}
