import { combineReducers } from 'redux'

import { makeCommunicationByIdReducer } from 'shared/utils/communication/reducer/makeCommunicationByIdReducer'
import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { ISlotsState } from '../types'

export default combineReducers<ISlotsState['communications']>({
  getGameList: makeCommunicationByIdReducer(actions.getGameList),
  getSessionUrl: makeCommunicationByIdReducer(actions.getSessionUrl),
  getFreeSpinSessionUrl: makeCommunicationByIdReducer(actions.getFreeSpinSessionUrl),
  addFavoriteSlot: makeCommunicationReducer(actions.addFavoriteSlot),
  getFreeSpinInfo: makeCommunicationReducer(actions.getFreeSpinInfo),
  removeFavoriteSlot: makeCommunicationReducer(actions.removeFavoriteSlot),
  getProducers: makeCommunicationReducer(actions.getProducers),
})
