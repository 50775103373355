import React, { useCallback, useMemo,useState } from 'react'
import { shallowEqual, useDispatch,useSelector } from 'react-redux'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { hidePersonalJackpotNotify } from 'features/notifies/actions'
import { selectNotifies } from 'features/notifies/selectors'

import NotifyContainer from '../NotifyContainer/NotifyContainer'
import PersonalJackpotPolling from './PersonalJacpotPolling/PersonalJackpotPolling'

const PersonalJackpotNotify = () => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).bonuses
  const personalJackpotNotify = useSelector(selectNotifies, shallowEqual).personalJackpot

  const [temporarilyClosedJackpots, setTemporarilyClosedJackpots] = useState<number[]>([])

  const hideNotify = useCallback((id: number) => {
    dispatch(hidePersonalJackpotNotify(id))
  }, [dispatch])

  const temporarilyHideNotify = useCallback((id: number) => {
    setTemporarilyClosedJackpots(prev => [...prev, id])
  }, [])

  const items = useMemo(
    () =>
      Object.keys(personalJackpotNotify.all)
        .filter(
          jackpotId =>
            !personalJackpotNotify.hide.includes(+jackpotId) && !temporarilyClosedJackpots.includes(+jackpotId),
        )
        .map(jackpot => {
          const currId = Number(jackpot)
          const amount = personalJackpotNotify.all[currId]?.amount
          const currency = personalJackpotNotify.all[currId].amountCurrency

          return (
            <NotifyContainer
              key={currId}
              title={locale.yourJackpot}
              amount={amount}
              currency={currency}
              callback={() => hideNotify(currId)}
              backgroundCallback={() => temporarilyHideNotify(currId)}
            />
          )
        }),
    [locale, personalJackpotNotify, temporarilyClosedJackpots, hideNotify, temporarilyHideNotify],
  )

  return (
    <>
      {isAuth ? <PersonalJackpotPolling /> : null}
      {items}
    </>
  )
}

export default PersonalJackpotNotify
