import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IProfileState } from '../types'

const initialState: IProfileState['data'] = {
  user: null,
  userInfo: null,
  historyInfo: {
    history: [],
    page: 1,
    count: 0,
  },
  slotsHistory: {
    count: 0,
    items: [],
  },
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getProfileInfo.success, (state, action) => {
      state.user = action.payload || initialState.user
    })
    .addCase(actions.getUserInfo.success, (state, action) => {
      state.userInfo = action.payload || initialState.userInfo
    })
    .addCase(actions.getPayHistory.success, (state, action) => {
      state.historyInfo = action.payload
    })
    .addCase(actions.getSlotsHistory.success, (state, action) => {
      state.slotsHistory = action.payload
    })
})
