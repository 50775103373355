import { combineReducers } from 'redux'
import { MigrationManifest } from 'redux-persist'
import createMigrate from 'redux-persist/es/createMigrate'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { INotifiesState } from '../types'
import communications from './communications'
import data from './data'
import { notifiesMigration } from './migrations/migrations'

export const reducedWithComm = combineReducers<INotifiesState>({
  communications,
  data,
})

const persistConfig = {
  key: 'notifies',
  storage,
  version: 1,
  migrate: createMigrate(notifiesMigration as unknown as MigrationManifest, { debug: false }),
  blacklist: ['communications'],
}

export const reducer = persistReducer(persistConfig, reducedWithComm)
