import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { ISliderState } from '../types'

export default combineReducers<ISliderState['communications']>({
  loadCarousels: makeCommunicationReducer(actions.loadCarousels),
})
