import dayjs from 'dayjs'

import { ISignInResponse } from 'shared/models/Auth'
import { IServerUser, IUser } from 'shared/models/User'
import { ISignIn } from 'shared/utils/types/Auth.type'

import { getUserType } from './utils/getUserType'

export class AuthConverter {
  convertUserData = (data: IServerUser): IUser => ({
    id: +data.id,
    login: data.email,
    currency: data.balance_currency === 'FNT' ? 'RUB' : data.balance_currency,
    firstName: data.first_name,
    lastName: data.last_name,
    role: data.role,
    username: data.username,
    balance: +(+data.balance).toFixed(2),
    parent: data.parent,
    email: data.email,
    dateOfBirth: data.info?.date_of_birth,
    createdAt: dayjs(data.created_at).format('DD/MM/YYYY HH:mm'),
    dateJoined: data.date_joined,
    isSelfRegistered: data.is_self_registered,
    userTypes: getUserType(data),
    bonusBalance: Math.floor(+data.bonus_balance * 100) / 100,
  })

  convertSignInData = ({ access, refresh, ...userData }: ISignInResponse): ISignIn => {
    const user = this.convertUserData(userData)
    return {
      user,
      session: {
        access,
        refresh,
      },
    }
  }
}
