import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { selectUserData } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ReactComponent as WalletSVG } from './img/wallet.svg'

import './UserInfo.scss'

const b = block('user-info-mobile')

const UserInfo: React.FC = () => {
  const userData = useSelector(selectUserData, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).profile

  return (
    <div className={b()}>
      <div className={b('username')}>{userData?.username}</div>
      <div className={b('balance')}>
        <div className={b('balance-item')}>
          <WalletSVG className={b('balance-icon')} />
          <div className={b('balance-label')}>{locale.balance}</div>
          <div className={b('balance-value')}>{`${userData?.balance} ${userData?.currency}`}</div>
        </div>
        <div className={b('circle')} />
        <div className={b('balance-item')}>
          <WalletSVG className={b('balance-icon')} />
          <div className={b('balance-label')}>{locale.bonusBalance}</div>
          <div className={b('balance-value')}>{`${userData?.bonusBalance} ${userData?.currency}`}</div>
        </div>
      </div>
    </div>
  )
}

export default UserInfo
