import React, { useCallback, useContext } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import useHideOverflow from 'shared/hooks/useHideOverFlow'

import { logout } from 'features/auth/actions'
import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import ProfileMobile from 'components/ProfileMobile/ProfileMobile'
import SetLanguage from 'components/SetLanguage/SetLanguage'

import './ProfileMenu.scss'

interface IProfileMenuProps {
  isOpenProfileMenu: boolean
  onToggleProfileMenu: (val?: boolean) => void
}

const b = block('profile-settings-menu')

const ProfileMenu: React.FC<IProfileMenuProps> = ({ isOpenProfileMenu, onToggleProfileMenu }) => {
  const dispatch = useDispatch()
  const { pushNewModal } = useContext(ModalContext)

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).common

  const onCloseProfileMenu = () => {
    onToggleProfileMenu(false)
  }

  const onLogoutClick = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const onLoginClick = () => {
    pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())()
  }

  useHideOverflow(isOpenProfileMenu)

  return (
    <aside style={{ right: '-100%' }} className={b({ open: isOpenProfileMenu })}>
      <div className={b('content')}>
        {!isAuth ? (
          <>
            <div className={b('button-wrapper')} onClick={onLoginClick}>
              {locale.login}
            </div>
            <div className={b('content-item')}>
              <SetLanguage />
            </div>
          </>
        ) : (
          <>
            <ProfileMobile onCloseProfileMenu={onCloseProfileMenu} logOut={onLogoutClick} />
          </>
        )}
      </div>
    </aside>
  )
}

export default ProfileMenu
