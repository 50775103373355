import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IProfileState } from '../types'

export default combineReducers<IProfileState['communications']>({
  changePassword: makeCommunicationReducer(actions.changePassword),
  getPayHistory: makeCommunicationReducer(actions.getPayHistory),
  activatePromocode: makeCommunicationReducer(actions.activatePromocode),
  withdawCashier: makeCommunicationReducer(actions.withdrawCashier),
  getUserInfo: makeCommunicationReducer(actions.getUserInfo),
  updateUserInfo: makeCommunicationReducer(actions.updateUserInfo),
  sendKYCPicture: makeCommunicationReducer(actions.sendKYCPicture),
  getSlotsHistory: makeCommunicationReducer(actions.getSlotsHistory),
})
