/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import block from 'bem-cn'
import { Field } from 'formik'

import './Checkbox.scss'

interface ICheckboxProps {
  checkedValue?: boolean
  onChange: (e: any, val: string) => void
  name: string
  type: string
  value?: string
  disabled?: boolean
  overrideCheckboxStyles?: string
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  onChange,
  checkedValue = false,
  name,
  type,
  value = '',
  disabled,
  overrideCheckboxStyles,
}) => {
  const b = block('checkbox')
  return (
    <label className={b('wrapper').mix(overrideCheckboxStyles)}>
      <span className={b('icon', { checked: checkedValue })} />
      <Field
        name={name}
        type={type}
        className={b()}
        disabled={disabled}
        checked={checkedValue}
        onChange={onChange}
        // {...props}
      />
      <span className={b('value')}>{value}</span>
    </label>
  )
}
