import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'

import { ISession, ISessionAccessToken } from 'shared/models/Auth'

import { getIsAccessTokenExpired } from './getIsAccessTokenExpired'
import { getIsRefreshTokenExpired } from './getIsRefreshTokenExpired'

export type TimeoutAccessToken = {
  isNeedUpdate: boolean
  ms: number
}

export const getTimeoutAccessToken = (session: ISession): TimeoutAccessToken => {
  const isRefreshTokenExpired = getIsRefreshTokenExpired(session.refresh)

  if (isRefreshTokenExpired) {
    return {
      isNeedUpdate: false,
      ms: 0,
    }
  }

  const isAccessTokenExpired = getIsAccessTokenExpired(session.access)

  if (isAccessTokenExpired) {
    return {
      isNeedUpdate: true,
      ms: 0,
    }
  }

  try {
    const decodedAccessToken = jwtDecode<ISessionAccessToken>(session.access)

    const diffMsToUpdate = dayjs(decodedAccessToken.exp * 1000)
      .subtract(decodedAccessToken.refresh_shift, 'second')
      .diff(dayjs())

    return {
      isNeedUpdate: true,
      ms: diffMsToUpdate,
    }
  } catch {
    return {
      isNeedUpdate: false,
      ms: 0,
    }
  }
}
