import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

export const loadSettings = makeCommunicationActionCreator({
  loading: '@settings/LOAD_SETTINGS_LOADING',
  success: '@settings/LOAD_SETTINGS_SUCCESS',
  error: '@settings/LOAD_SETTINGS_ERROR',
  reset: '@settings/LOAD_SETTINGS_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.settings.getSettings()
    return response.data
  },
)

export const getCurrenciesList = makeCommunicationActionCreator({
  loading: '@settings/GET_CURRENCIES_LIST_LOADING',
  success: '@settingsGET_CURRENCIES_LIST_SUCCESS',
  error: '@settings/GET_CURRENCIES_LIST_ERROR',
  reset: '@settings/GET_CURRENCIES_LIST_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.settings.getCurrencies()
    return response.data
  },
)
