import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IBetbyState } from '../types'

const initialState: IBetbyState['data'] = {
  token: null,
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getBetbySportToken.success, (state, action) => {
      state.token = action.payload
    })
    .addCase(actions.clearSportToken, state => {
      state.token = null
    })
})
