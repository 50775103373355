import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { mediaSize } from 'shared/style/var'

interface IDefineDeviceComponentProps {
  [key: string]: React.ReactElement
}


const DefineDeviceComponent: React.FC<IDefineDeviceComponentProps> =
  ({ desktopComponent, mobileComponent }) => {
    const isDesktop = useMediaQuery({ minWidth: mediaSize.laptop })
    return !isDesktop ? mobileComponent : desktopComponent
  }

export default DefineDeviceComponent