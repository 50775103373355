import { IApplicationState } from 'setup/store'

import { defaultCurrency } from 'shared/constants'

export const selectIsUserAuthenticated = (state: IApplicationState) => state.auth.data.isAuth

export const selectAuthCommunication = (state: IApplicationState) => state.auth.communications

export const selectUserData = (state: IApplicationState) => state.auth.data.user

export const selectUserCurrrency = (state: IApplicationState) => state.auth.data.user?.currency ?? defaultCurrency

export const selectUserBirthday = (state: IApplicationState) => state.auth.data.user?.dateOfBirth

export const selectSessionData = (state: IApplicationState) => state.auth.data.session

export const selectRefreshSessionCommunications = (state: IApplicationState) => state.auth.communications.refreshSession
