const QueryTypes = {
  GET: 'get',
  POST: 'post',
  POST_FEEDBACK: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
}

export default QueryTypes
