import { ITabs } from '../types/interfaces/Slots/Slots'

export const tabs: ITabs = {
  popular: [
    'pragmaticexternal:GatesOfOlympus1',
    'vs20olympgate',

    'habanero:SGHotHotFruit',

    'allways_fruits_original',

    'pragmaticexternal:TheDogHouseMegaways1',
    'vswaysdogs',

    'bookofdead_png',

    'pragmaticexternal:WildWildRiches',
    'vs576treasures',

    'pragmaticexternal:ChilliHeat',
    'vs25chilli',
    'vs20wildparty',
    'vs20lobcrab',
    'vs243nudge4gold',
    'vswayspowzeus',
    'vs20cashmachine',

    'vs20procount',
    'vs20splmystery',
    'vs20hstgldngt',
    'vs10bbextreme',
    'vs20beefed',
    'vs10fdrasbf',
    'vs20mysteryst',
    'lagranaventura_original',
    'luckyzodiac_original',
    'oktoberfest_original',
    'megashark_original',
    'vampires_original',
    'grandtiger_original',
    'dragonsmystery_original',
    'goldenquest_original',
    'buffalothunderstacks_original',
    'dragonsgift_original',
    'hotchoicedeluxe_original',
    'hot81_original',
    'bookoffruits20_original',
    'bookoffruitshalloween_original',
    'allwayshotfruits_original',

    'pragmaticexternal:BiggerBassBonanza',
    'vs12bbb',

    'pragmaticexternal:WildWestGold',
    'vs40wildwest',

    'vswaysdogs_prg',

    'vs576treasures_prg',
    'vs12bbb_prg',
    'vs25chilli_prg',
    'vs40wildwest_prg',

    'pragmaticexternal:SweetBonanza',
    'vs20fruitsw',

    'pragmaticexternal:WolfGold',

    'pragmaticexternal:GreatRhinoDeluxe',

    'pragmaticexternal:CurseoftheWerewolfMegaways',
    'pragmaticexternal:BuffaloKing',
    'pragmaticexternal:5Lions',
    'pragmaticexternal:JohnHunterandtheBookofTut',
    'vs243lions',
    'vs1024lionsd',
    'vs243lionsgold',
    'vswayslions',

    'vs20fruitsw_prg',
    'vs25wolfgold_prg',
    'vs25chilli_prg',
    'vs20rhinoluxe_prg',
    'vswayswerewolf_prg',
    'vs4096bufking_prg',
    'vs243lions_prg',
    'vs10bookoftut_prg',
    'gonzos_quest_touch',
    'qso_bbw',
    'quickspin:BigBadWolfMegaways',
    'qso_talesofdoctordolittle',
    'qso_mountainkingl',
    'snakearena_rg',
    'templetumble_rg',
    'ironbank_rg',
    'wildchapo_rg',
    'moneytrain2_rg',
    'qso_dragonchase',
    'qso_polarpaws',
    'qso_sakurafortune',
    'onlyplay:luckypunch',
    'yggdrasil:5Clans',
    'yggdrasil:Cauldron',
    'abanero:SGLuckyDurian',
    'habanero:SGLanternLuck',
    'habanero:SGNineTails',
    'habanero:SGProst',
    'evoplay:TreeOfLight',
    'evoplay:TreasureManial',
    'evoplay:CurseofthePharaoh',
    'evoplay:AnubisMoon',
    'evoplay:AnimalQuest',
    'evoplay:ForestDreams',
    'evoplay:MidnightShow',
    'evoplay:AndarNight',
    'evoplay:NukeWorld',
    'mascot:BastetAndCats',
    'mascot:cleopatras_gems_rockways',
    'wazdan:BurningStars3',
    'wazdan:HotSlot777Crown',
    'infin:BuffaloPowerHoldandWin',
    'infin:WolfPower',
    'infin:EaglePowerHoldandWIn',
    'infin:DivineDragonHoldAndWin',
    'infin:BookofGold2DoubleHit',
    'infin:SolarKing',
    'infin:TheFruitMegaways',
    'infin:BuddhaMegaways',
    'infin:GoldenDancingLion',
    'infin:GoldExpress',
    'infin:BlackWolf',
    'infin:CandyBoom',
    'bsg:TakeSantasShop',
    'bsg:TakeTheBank',
    'everymatrix:DarkJoker',
    'gameart:NefertitiHyperways',
    'gameart:SpookyGraves',
    'gameart:BattleforAtlantis',
    'gameart:PiggyBjornMuspelheimsTreasure',
    'groove:MrLuck',
    'groove:Kukers',
    'groove:DarkMystic',
    'groove:BookOfAnime',
    'groove:TheMummyWinHunters',
    'groove:RomanceV',
    'groove:GoblinsLand',
    'infin:BookofSand',
    'infin:Spin2Win',
    'infin:Horses8Sprint',
    'kalamba:CaribbeanAnne2_',
    'kalamba:LadyLava_k',
    'kalamba:JokerTimes_k',
    "kalamba:Pharoah'sReign_k",
    'mrslotty:EnchantedForest',
    'mrslotty:BushidoBlade',
    'platipus:rhinomania',
    'platipus:luckycat',
    'platipus:hawaiiannight',
  ],
  'Drops & Wins': [
  
  ],
  megaways: [
    'vswayswerewolf_prg',
    'vswaysdogs_prg',
    'templetumble_rg',
    'vs20xmascarol_prg',
    'vswaysbufking_prg',
    'vswayshammthor_prg',
    'vswaysmadame_prg',
    'vswaysrhino_prg',

    'pragmaticexternal:5LionsMegaways1',
    'vswayslions',

    'pragmaticexternal:BigBassBonanzaMegaways',
    'vswaysbbb',

    'pragmaticexternal:BuffaloKingMegaways1',
    'vswaysbufking',

    'pragmaticexternal:ChilliHeatMegaways',
    'vswayschilheat',

    'pragmaticexternal:ChristmasCarolMegaways1',
    'vs20xmascarol',

    'pragmaticexternal:CrystalCavernsMegaways',
    'vswayscryscav',

    'pragmaticexternal:ElementalGemsMegaways',
    'vswayselements',

    'pragmaticexternal:ExtraJuicyMegaways',
    'vswaysxjuicy',

    'pragmaticexternal:GreatRhinoMegaways',
    'vswaysrhino',

    'pragmaticexternal:MadameDestinyMegaways1',
    'vswaysmadame',

    'pragmaticexternal:PowerofThorMegaways',
    'vswayshammthor',

    'pragmaticexternal:StarzMegaways',
    'vs117649starz',

    'quickspin:BigBadWolfMegaways',
    'kagaming:FortuneBeauty',
    'kagaming:MedalWinner',
    'kagaming:PersiaBonanza',
    'kagaming:TreasureBowlMegaways',
    'infin:BuffaloPowerMegaways',
    'infin:LegendofCleopatraMegaways',
    'infin:LegendofCleopatraMegaways',
    'infin:SolarQueenMegaways',
    'infin:TheFruitMegaways',
    'infin:WolfPowerMegaways',
    'epicmedia:BarXSafeCrackerMegaways',
    'epicmedia:BigCatKingMegaways',
    'epicmedia:BuffaloRisingMegaways',
    'epicmedia:BuffaloRisingMegawaysAllAction',
    'epicmedia:DiamondMineMegaways',
    'epicmedia:EyeofHorusMegaways',
    'epicmedia:FishinFrenzyMegaways',
    'epicmedia:GenieJackpotsMegaways',
    'epicmedia:GorillaGoldMegaways',
    'epicmedia:LegacyofRaMegaways',
    'epicmedia:LightningStrikeMegaways',
    'epicmedia:LuckoftheIrishMegaways',
    'epicmedia:MysterySpinDeluxeMegaways',
    'epicmedia:PrimalMegaways',
    'epicmedia:ReturnofKongMegaways',
    'epicmedia:SafariGoldMegaways',
    'epicmedia:SweetSuccessMegaways',
    'epicmedia:TempleofTreasureMegaways',
    'epicmedia:VallettaMegaways',
    'epicmedia:VikingsUnleashedMegaways',
    'epicmedia:WishUponALeprechaunMegaways',
    'epicmedia:WolfLegendMegaways',
    'gameart:RoshImmortality',
  ],
  showGames: [
    'evolution:monopoly',

    'pragmaticexternal:SweetBonanzaCandyland',
    '1101',

    'evolution:crazytime',
    'evolution:GonzosTreasureHunt',

    'pragmaticexternal:MegaWheel',
    '801',

    'evolution:dealnodeal',
    'atmosphera:musicwheel',
    'evolution:dream_catcher',
    'evolution:megaball',

    'pragmaticexternal:MegaRoulette',
    '204',

    'evolution:cashorcrash',
    'evolution:lightningdice',

    'pragmaticexternal:MegaSicBo',
    '701',

    'evolution:lightning_roulette',

    'pragmaticexternal:ONEBlackjack',
    '901',

    'evolution:top_card',
    'evolution:sidebetcity',

    'pragmaticexternal:DragonTigerLive',
    '108',
  ],
  games: [
    'fishing2',
    'paradise_cq',
    'fishinggod',
    'fishingwar',
    'alienhunter',
    'zombieparty',
    'infin:WildBurningWins',
    'kagaming:ToyWorld',
    'onlyplay:F777Fighter',
    'onlyplay:luckypunch',
    'onlyplay:LuckyTanks',
    'evoplay:LuckyCrumbling',
    'tomhorn:SweetCrush',
    'habanero:SGCoyoteCrash',
    'kagaming:AirCombat1942',
    'kagaming:AnimalFishing',
    'kagaming:KingOctopus',
    'kagaming:GoldenDragon',
    'kagaming:ForceOfDragon',
    'kagaming:KAFishHunter',
    'kagaming:FishingExpedition',
    'kagaming:AlterWorld',
    'kagaming:TheDeepMonster',
    'kagaming:SpaceCat',
    'kagaming:PoseidonSecret',
    'kagaming:MermaidHunter',
    'kagaming:DragonBall',
    'kagaming:WonWonCatching',
    'kagaming:ShockTower',
    'kagaming:FlowersFruitMountain',
  ],
  roulette: [
    'evolution:lightning_roulette',
    'evolution:americanroulette',
    'evolution:auto_roulette',
    'evolution:instantroulette',

    'pragmaticexternal:MegaRoulette',
    '204',

    'pragmaticexternal:SpeedRoulette',
    '203',

    'pragmaticexternal:AutoRoulette',
    '225',

    'rouletteroyal_original',
    'evoplay:AmericanRoulette3D',
    'evoplay:EuropeanRoulette',
    'evoplay:FrenchRouletteClassic',
    'roulette_touch',
    'mascot:holdem_mg',
    'tomhorn:EuropeanRouletteAnnouncedBets',
    'french_roulette_html',
    'american_roulette_html',
    'wazdan:CasinoRoulette',
    'wazdan:GoldRoulette',
    'infin:RoulettewithTrack',
    'belatra:AmericanRoulette',
    'belatra:EuropeanRoulette',
    'belatra:TheMoneymania',
    'bsg:EuropeanRoulette',
    'bsg:VipAmericanRoulette',
    'bsg:VipEuropeanRoulette',
    'bsg:ZoomRoulette',
    'everymatrix:EuropeanRoulette',
    'gameart:Roulette',
    'groove:LuckySpinEuroRoulette',
    'groove:NeonRoulette',
    'luckystreak:AutoRoulette1',
    'luckystreak:FashionRoulette',
    'luckystreak:Oracle360',
    'luckystreak:PortomasoOracleRoulette1',
    'luckystreak:PortomasoOracleRoulette2',
    'luckystreak:Roulette1',
    'luckystreak:Roulette2',
    'luckystreak:XBaccaratM1',
    'luckystreak:XBaccaratM2',
    'luckystreak:XBaccaratM3',
    'luckystreak:XBaccaratM4',
    'infin:LeanderRoulette',
    'mrslotty:LuxRoulette',
    'mrslotty:Roulette',
    'mrslotty:VIPRoulette',
    'nucleus:30261',
    'nucleus:30262',
    'nucleus:30266',
    'nucleus:30308',
    'platipus:EuropeanRoulette',
    'softswiss:AmericanRoulette',
    'softswiss:CasinoHoldem',
    'softswiss:EuropeanRoulette',
    'softswiss:FrenchRoulette',
    'yggdrasil:GoldenChipRoulette',
  ],
  cardGames: [
    'evolution:FirstPersonLightningBlackjack',
    'evolution:LightningBlackjack',
    'evolution:FirstPersonGoldenWealthBaccarat',
    'evolution:FirstPersonLightningBaccarat',

    'pragmaticexternal:AndarBahar',
    '1024',

    'pragmaticexternal:ONEBlackjack',
    '901',

    'evoplay:Baccarat777',
    'evoplay:BlackJackLuckySevens',
    'evoplay:FourAces',
    'blackjack_classic_touch',
    'blackjack_touch',
    'blackjack_single_deck_touch',
    'evoplay:OasisPokerClassic',
    'evoplay:PokerTeenPatti',
    'evoplay:RedQueen',
    'evoplay:TexasHoldemBonus',
    'evoplay:TexasHoldemPoker3D',
    'mascot:baccarat_mg',
    'mascot:blackjack_mg',
    'baccarat',
    'blackjack_html',
    'wazdan:AmericanPokerGold',
    'wazdan:AmericanPokerV',
    'wazdan:BlackJack',
    'wazdan:CaribbeanBeachPoker',
    'kagaming:Baccarat',
    'wazdan:MagicPoker',
    'wazdan:ThreeCards',
    'wazdan:TurboPoker',
    'infin:BlackjackLow',
    'kagaming:SuperVideoPoker',
    'habanero:AcesandEights100Hand',
    'habanero:AcesandEights10Hand',
    'habanero:AcesandEights1Hand',
    'habanero:AcesandEights5Hand',
    'habanero:AcesandEights50Hand',
    'habanero:AllAmericanPoker100Hand',
    'habanero:AllAmericanPoker10Hand',
    'habanero:AllAmericanPoker1Hand',
    'habanero:AllAmericanPoker5Hand',
    'habanero:AllAmericanPoker50Hand',
    'habanero:AmericanBaccarat',
    'habanero:Baccarat3HZC',
    'habanero:BlackJack3H',
    'habanero:BlackJack3HDoubleExposure',
    'habanero:BonusDuecesWild1Hand',
    'habanero:BonusDuecesWild10Hand',
    'habanero:BonusDuecesWild100Hand',
    'habanero:BonusDuecesWild5Hand',
    'habanero:BonusDuecesWild50Hand',
    'habanero:BonusPoker1Hand',
    'habanero:BonusPoker10Hand',
    'habanero:BonusPoker100Hand',
    'habanero:BonusPoker5Hand',
    'habanero:BonusPoker50Hand',
    'habanero:CaribbeanHoldem',
    'habanero:CaribbeanStud',
    'habanero:DoubleBonusPoker1Hand',
    'habanero:DoubleBonusPoker10Hand',
    'habanero:DoubleBonusPoker100Hand',
    'habanero:DoubleBonusPoker5Hand',
    'habanero:DoubleBonusPoker50Hand',
    'habanero:DoubleDoubleBonusPoker1Hand',
    'habanero:DoubleDoubleBonusPoker10Hand',
    'habanero:DoubleDoubleBonusPoker100Hand',
    'habanero:DoubleDoubleBonusPoker5Hand',
    'habanero:DoubleDoubleBonusPoker50Hand',
    'habanero:DuecesWild1Hand',
    'habanero:DuecesWild10Hand',
    'habanero:DuecesWild100Hand',
    'habanero:DuecesWild5Hand',
    'habanero:DuecesWild50Hand',
    'habanero:JacksorBetter1Hand',
    'habanero:JacksorBetter10Hand',
    'habanero:JacksorBetter100Hand',
    'habanero:JacksorBetter5Hand',
    'habanero:JacksorBetter50Hand',
    'habanero:JokerPoker1Hand',
    'habanero:JokerPoker10Hand',
    'habanero:JokerPoker100Hand',
    'habanero:JokerPoker5Hand',
    'habanero:JokerPoker50Hand',
    'habanero:SicBo',
    'habanero:TGBlackjackAmerican',
    'habanero:TGDragonTiger',
    'habanero:TGThreeCardPoker',
    'habanero:TGThreeCardPokerDeluxe',
    'habanero:TGWar',
    'habanero:TensorBetter1Hand',
    'habanero:TensorBetter10Hand',
    'habanero:TensorBetter100Hand',
    'habanero:TensorBetter5Hand',
    'habanero:TensorBetter500Hand',
    'bsg:21BurnBlackjack',
    'bsg:Baccarat',
    'bsg:CaribbeanPoker',
    'bsg:DeucesWild',
    'bsg:DrawHiLo',
    'bsg:EuropeanBlackjack',
    'bsg:OasisPoker',
    'bsg:PaiGow',
    'bsg:Pirate21',
    'bsg:Pontoon',
    'bsg:RedDog',
    'bsg:SingleDeckBlackjack',
    'bsg:ThreeCardRummy',
    'bsg:TopCardTrumps',
    'bsg:TripleEdgePoker',
    'everymatrix:Blackjack',
    'everymatrix:JacksOrBetter',
    'gameart:Blackjack',
    'gameart:CarribeanStudPoker',
    'gameart:VideoPoker',
    'luckystreak:Baccarat',
    'luckystreak:Baccarat4',
    'luckystreak:Blackjack16',
    'luckystreak:Blackjack2',
    'luckystreak:Blackjack3',
    'luckystreak:Blackjack6',
    'luckystreak:Blackjack7',
    'luckystreak:BlackjackVIP',
    'mrslotty:JollyPoker',
    'nucleus:30235',
    'nucleus:30236',
    'nucleus:30239',
    'nucleus:30240',
    'nucleus:30241',
    'nucleus:30242',
    'nucleus:30243',
    'nucleus:30244',
    'nucleus:30245',
    'nucleus:30246',
    'nucleus:30247',
    'nucleus:30251',
    'nucleus:30252',
    'nucleus:30255',
    'nucleus:30257',
    'nucleus:30256',
    'nucleus:30257',
    'nucleus:30258',
    'nucleus:30259',
    'nucleus:30260',
    'nucleus:30273',
    'platipus:baccarat',
    'platipus:baccaratmini',
    'platipus:baccaratvip',
    'platipus:blackjack',
    'platipus:blackjackvip',
    'softswiss:Baccarat',
    'softswiss:BlackjackSurrender',
    'softswiss:BlackjackPro',
    'softswiss:CaribbeanPoker',
    'softswiss:DoubleExposure',
    'softswiss:HiLoSwitch',
    'softswiss:MultihandBlackjack',
    'softswiss:OasisPoker',
    'softswiss:Pontoon',
    'softswiss:TexasHoldem',
    'softswiss:TreyPoker',
  ],
  lotto: [
    'evoplay:BookofKeno',
    'evoplay:XmasKenoCat',
    'kenoplustwoball_gt_html',
    'tomhorn:KongoBongo',
    'wazdan:DragonsLucky8',
    'wazdan:ExtraBingo',
    'kagaming:SuperKeno',
    'belatra:88Bingo88',
    'belatra:BingoSoccer',
    'epicmedia:Keno',
    'infin:Keno',
    'mrslotty:BurningPearlNumbers',
    'mrslotty:Cryptomania',
    'mrslotty:CryptomaniaNumbers',
    'mrslotty:NeptuneNumbers',
    'mrslotty:NeptuneTreasure',
    'keno_austria',
    'keno_universe_html',
  ],
  fruits: [
    'allways_fruits_original',
    'fortunas_fruits_origina',
    'merry_fruits_original',
    'hotfruits100_original',
    'hotfruits40_original',
    'hotfruits20_original',
    'sizzling_hot_classic_html',
    'bananas_go_bahamas_html',
    'three_html',
    'fruit_spin',
    'berry_burst',
    'berry_burst_max',
    'strolling_staxx',
    'grand_spinn',
    'sweety_honey_fruity',
    'twin_happiness',
    'fruitshop_touch',
    'go_bananas_html',
    'fruit_case_html',
    'quickspin:DurianDynamite',
    'qso_duriandynamite',
    'fruitsandroyals_gt_html',
    'fruitsensation_gt_html',
    'sizzlinggems_gt_html',
    'fruitilicious_gt_html',
    'sizzlinghotdeluxe_gt_html',
    'roaringforties_gt_html',
    'sizzling6_gt_html',
    'therealking_gt_html',
    'inferno_gt_html',
    'redhotfruits_gt_html',
    'arrivaarriva_gt_html',
    'mascot:FruitsofLuxor',
    'mascot:TwinFruitsofOlympus',
    'mascot:aloha_tiki_bar',
    'mascot:fruit_macau',
    'mascot:fruit_monaco',
    'mascot:fruit_vegas',
    'fruit_cocktail_original_html',
    'fruit_cocktail_2_original_html',
    'mascot:twin_fruits_of_santa',
    'mascot:venetian_magic',
    'infin:FruitCocktail',
    'infin:FruitCocktailTwo',
    'mf4_wzdn',
    'kagaming:FruitParty',
    'mf27_wzdn',
    'mf81_wzdn',
    'mf_wzdn',
    'midnightfruits81_ag',
    'candyfruits_mk_html',
    'cashfruitsplus_mk_html',
    'multifruits_mk_html',
    'risingliner_mk_html',
    'ice_bar_27_kjt_html',
    'neon_fruits_kjt_html',
    'multi_vegas_81_kjt_html',
    'fruits_of_the_nile_c_ps_html',
    'imperial_fruits_100_ps_html',
    'imperial_fruits_40_ps_html',
    'imperial_fruits_5_ps_html',
    'mega_burning_wins_27_ps_html',
    'sevens_n_fruits_20_ps_html',
    'sevens_n_fruits_ps_html',
    'thunder_reels_c_ps_html',
    'wild_burning_wins_5_ps_html',
    '3_fruits_win_10_ps_html',
    'fruits_and_clovers_20_ps_html',
    'fruits_and_jokers_100_ps_html',
    'fruits_and_jokers_20_ps_html',
    'fruits_and_jokers_40_ps_html',
    'fruits_n_stars_c_ps_html',
    'fruits_n_stars_he_ps_html',
    'fruit_xtreme_ps_html',
    'fruit_supreme_25_ps_html',
    'juice_and_fruits_c_ps_html',
    'super_hot_40',
    'burning_hot_html',
    'fruits_kingdom_html',
    'super_hot_20_html',
    'super_hot_40_html',
    'supreme_hot_html',
    'ultimate_hot_html',
    'ultimate_hot',
    'supreme_hot',
    'super_hot_20',
    'fruits_kingdom',
    'flaming_hot',
    'burning_hot',
    'tomhorn:243CrystalFruits',
    'tomhorn:243CrystalFruitsReversed',
    'tomhorn:81FrutasGrandes',
    'tomhorn:FlamingFruit',
    'tomhorn:FruitsGoPop',
    'tomhorn:LaTomatina',
    'tomhorn:SizableWin',
    'wazdan:Arcade',
    'wazdan:FruitFiesta',
    'azdan:FruitMania',
    'wazdan:FruitManiaDeluxe',
    'wazdan:FruitsGoBananas',
    'wazdan:JumpingFruits',
    'wazdan:Lucky9',
    'wazdan:MagicFruits',
    'wazdan:MagicFruits27',
    'wazdan:MagicFruits4',
    'kagaming:BigApple',
    'wazdan:MagicFruits4Deluxe',
    'wazdan:MagicFruits81',
    'wazdan:MagicFruitsDeluxe',
    'kagaming:BombingFruit',
    'kagaming:BubbleDouble',
    'wazdan:SlotJam',
    'infin:3FruitsWin10lines',
    'infin:3FruitsWinDoubleHit',
    'infin:5SuperSevensFruits',
    'infin:5SuperSevensFruits6reels',
    'infin:BurningWinsclassic5lines',
    'infin:BurningWinsx2',
    'infin:FruitCrown100Lines',
    'infin:Fruits&Clovers20Lines',
    'infin:Fruits&Jokers100Lines',
    'infin:FruitsJokers20lines',
    'infin:FruitsNStarsHolidayEdition',
    'infin:FruitsandJokers40lines',
    'infin:ImperialFruits',
    'infin:ImperialFruits100lines',
    'infin:ImperialFruits40lines',
    'infin:JuiceandFruits',
    'infin:LuckyStaxx40lines',
    'infin:MegaBurningWins27ways',
    'infin:Sevens&Fruits20Lines',
    'infin:SevensFruits',
    'infin:SevensFruits6reels',
    'infin:StarsFruitsDoubleHit1',
    'infin:SunnyFruits',
    'infin:SuperSunnyFruits',
    'infin:TheFruitMegaways',
    'kagaming:PumpkinWin',
    'kagaming:Speakeasy',
    'kagaming:VeggiesPlot',
    'habanero:SGHotHotFruit',
    'habanero:SGMountMazuma',
    'infin:MagicApple',
    'evoplay:CycleofLuck',
    'evoplay:CycleofLuckBonusBuy',
    'evoplay:ExplodingFruits',
    'evoplay:FruitBurst',
    'evoplay:FruitDisco',
    'evoplay:FruitNova',
    'evoplay:FruitNovaSupe40',
    'evoplay:FruitSuperNova',
    'evoplay:FruitSuperNova100',
    'evoplay:FruitSuperNova30',
    'evoplay:FruitSuperNova60',
    'evoplay:FruitSuperNova80',
    'evoplay:FruitSuperNovaJackpot',
    'evoplay:FruitsLand',
    'evoplay:IceMania',
    'fruitshop_christmas',
    'evoplay:Robinson',
    'evoplay:SurfZone',
    'evoplay:TripleChili',
    'evoplay:WesternReels',
    'alg:20HotSpins',
    'alg:40FruityReels',
    'alg:40HotSpins',
    'alg:80FruitDash',
    'alg:FruitDash',
    'alg:FruityReels',
    'alg:LuckyFruitLines',
    'belatra:500JuicyFruits',
    'infin:PoisonedApple',
    'infin:PoisonedApple2',
    'belatra:FruitMix',
    'belatra:GoldenLemon',
    'belatra:GoldenLemonDeluxe',
    'fruity_wild_bng_html',
    'belatra:SevenFruits',
    'belatra:Sevens',
    'belatra:SuperSevens',
    'belatra:SuperSevensHappyWheel',
    'booming:BigAppleWins',
    'booming:CherryBomb',
    'booming:CherryBombDeluxe',
    'bsg:FruitZen',
    'epicmedia:SuperFruit',
    'epicmedia:WoopWoop',
    'everymatrix:FruitSlot',
    'everymatrix:Fruitsand777s',
    'everymatrix:MightyFruits',
    'booming:ExoticFruitDeluxe',
    'everymatrix:RoyalCrown2',
    'booming:ExoticFruit',
    'everymatrix:SpicyFruits',
    'everymatrix:Super7Deluxe',
    'booming:FreezingClassics',
    'gameart:BubbleFruits',
    'gameart:DynamiteFruits',
    'gameart:HotFruitDelights',
    'gameart:SuperHeatedSevens',
    'gameart:XtremeHot',
    'groove:FruityMania',
    'groove:JuicyWilds',
    'booming:WildCherries',
    'infin:FruitStrike20Multiwin',
    'infin:FruitStrikeDriveWild',
    'infin:FruitStrikeExtra6',
    'infin:FruitStrikeHotStaxxx',
    'kalamba:CosmicCharms_k',
    'mancala:CherryBombs',
    'mancala:FruitFactory',
    'mrslotty:FluoHot5',
    'mrslotty:ForestFruits',
    'mrslotty:FruitIsland',
    'mrslotty:FruitsandStars',
    'mrslotty:FruitsandStars40',
    'mrslotty:FruityHot',
    'mrslotty:classic7fruits',
    'mrslotty:HeatingIceDeluxe',
    'mrslotty:JazzyFruits',
    'mrslotty:electric7fruits',
    'mrslotty:fruitcocktail7',
    'mrslotty:TwentyFruits',
    'mrslotty:fruitsanddiamonds',
    'mrslotty:golden7fruits',
    'mrslotty:crystalhot100',
    'mrslotty:heatingice',
    'mrslotty:thecrownfruit',
    'mrslotty:unicorncasinofruits',
    'mrslotty:hotfruits',
    'mrslotty:nomorefruits',
    'mrslotty:royal7fruits',
    'mrslotty:tropical7fruits',
    'mrslotty:wild7fruits',
    'netgame:Bananas10',
    'mrslotty:HotFruits1',
    'mrslotty:TropicalCrush',
    'netgame:DiscoFruits',
    'netgame:FrostyFruits',
    'netgame:FruitBurst',
    'netgame:FruitCashHoldnLink',
    'netgame:GoldenFruits',
    'netgame:InfernoFruits',
    'netgame:JackpotSevens',
    'netgame:QuickCashFruits',
    'netgame:RoyalFruits20',
    'netgame:RoyalFruits40',
    'netgame:VolcanoFruits',
    'nucleus:30102',
    'nucleus:30130',
    'nucleus:30376',
    'nucleus:30377',
    'onlyplay:FruityBook',
    'nucleus:30396',
    'nucleus:30403',
    'platipus:fruitysevens',
    'platipus:hotfruits',
    'platipus:neonclassic',
    'platipus:juicyspins',
    'softswiss:FruitMillion',
    'technology:20MegaFresh',
    'technology:5xCherryParty',
    'technology:BananaParty',
    'technology:CherryCrown',
    'technology:DevilsFruits',
    'technology:FruitsandBells',
    'technology:FusionFruitBeat',
    'technology:NeonBananas',
    'technology:PurpleFruits',
    'technology:PurpleHotII',
    'technology:ShiningTreasures',
    'yggdrasil:Fruitoids',
    'yggdrasil:PumpkinSmash',
    'thunderkick:FruitWarp_tk',
    'yggdrasil:CherryPop',
    'yggdrasil:PapayaPop',
    'zillion:FruitsFive',
  ],
  hot: [
    'hot_seven_original',
    'hot_twenty_original',
    'hot_diamonds_original',
    'bells_on_fire_hot_original',
    'bells_on_fire_original',
    'bells_on_fire_rombo_original',
    'hot_scatter_original',
    'hot_star_original',
    'hot_neon_original',
    'hot_27_original',
    'hotfruits100_original',
    'hotfruits40_original',
    'hotfruits20_original',
    'hot27dice_original',
    'hot7dice_original',
    'hotscatterdice_original',
    'hot40_original',
    'hottestfruits20_original',
    'evoplay:HotTripleSevens',
    'evoplay:HotTripleSevensSpecial',
    'xtrahot_gt_html',
    'ultrahotdeluxe_gt_html',
    'hotchance_gt_html',
    'magic81_gt_html',
    'sizzlinghot6extragold_gt_html',
    'spinningstars_gt_html',
    'redlineracer_gt_html',
    'redhotfruits_gt_html',
    'redhot40_gt_html',
    'redhot20_gt_html',
    'redhotburning_gt_html',
    'barsandsevens_gt_html',
    'tomhorn:FireandHot',
    'tomhorn:HotBlizzard',
    'xtra_hot_classic_html',
    'always_hot_classic_html',
    'hat_trick_html',
    'kagaming:777Vegas',
    'quickspin:HotSync',
    'qso_hotsync',
    'wazdan:BurningStars3',
    'kagaming:Al',
    'wazdan:Hot777',
    'wazdan:Hot777Deluxe',
    'wazdan:HotParty',
    'wazdan:HotPartyDeluxe',
    'kagaming:ArtistStudio',
    'wazdan:MagicHot',
    'wazdan:MagicHot4',
    'wazdan:MagicHot4Deluxe',
    'wazdan:SIzzlingBells',
    'wazdan:SuperHot',
    'wazdan:VegasHot',
    'wazdan:VegasHot81',
    'kagaming:Flaming7',
    'hp_wzdn',
    'mh_wzdn',
    'vh81_wzdn',
    'vh_wzdn',
    'sh_wzdn',
    'ring_of_fire_xl_kjt_html',
    'infin:HotBurningWins',
    'infin:HotCoinsHoldandWin',
    'infin:Sevens&Fruits20Lines',
    'super_burning_wins_ps_html',
    'burning_wins_ps_html',
    'fruit_supreme_25_ps_html',
    'kagaming:Mexicaliente',
    'kagaming:OriginOfFire',
    'garden_party',
    'sumatran_storm',
    'vegas_baby',
    'habanero:SGHotHotHalloween',
    'alg:60UltraClassicHot',
    'alg:GoldenVegas',
    'alg:HotFortuneWheel',
    'alg:HotFortuneWheel80',
    'booming:AstroPandas',
    'booming:BarnyardTwister',
    'booming:BoomingSeven',
    'booming:Boomshakalaka',
    'booming:BurningClassics',
    'booming:ChicagoNights',
    'epicmedia:HotZone',
    'epicmedia:RedHotRepeater',
    'everymatrix:FruitHotBonanza',
    'booming:CubaCaliente',
    'booming:JestersJoy',
    'booming:JingleJingle',
    'gameart:BurningFlame',
    'booming:MerrySpinning',
    'booming:MexicoWins',
    'booming:MiamiNights',
    'booming:ParisNights',
    'booming:RedHotVolcano',
    'booming:RoyalWins',
    'booming:RudolfsRide',
    'booming:SantasKiss',
    'booming:ShowMaster',
    'booming:ShowmetheMummy',
    'booming:StellarSpins',
    'booming:SuperBoom',
    'booming:TheKingPanda',
    'booming:VIPFilthyRiches',
    'booming:VegasVIPGold',
    'booming:VegasWins',
    'booming:WildBonusReSpins',
    'booming:WinnersCup',
    'igtech:Hot88',
    'mrslotty:CrystalHot40',
    'mrslotty:CrystalHot80',
    'mrslotty:FruityHot',
    'mrslotty:cryptomatrix',
    'mrslotty:HotStars',
    'mrslotty:JuicyHot',
    'mrslotty:MegaHot',
    'mrslotty:emojislot',
    'mrslotty:MysteryJokerHot',
    'mrslotty:Retro7Hot',
    'mrslotty:TripleHot',
    'mrslotty:TropicalHot',
    'mrslotty:TurboHOT100',
    'mrslotty:TurboHot40',
    'mrslotty:TurboHot80',
    'mrslotty:TwinklingHot40',
    'mrslotty:TwinklingHot5',
    'mrslotty:HotFruits1',
    'netgame:RedHotChili7s',
    'onlyplay:HotandSpicy',
    'onlyplay:HotandSpicyJackpot',
    'nucleus:30400',
    'nucleus:30401',
    'nucleus:30402',
    'nucleus:TropicalSplash',
    'softswiss:AlohaKingElvis',
    'softswiss:BonanzaBillion',
    'softswiss:DeepSea',
    'softswiss:ElvisFroginVegas',
    'softswiss:FantasyPark',
    'softswiss:FireLightning',
    'softswiss:HawaiiCocktails',
    'softswiss:HelloEaster',
    'technology:BrilliantsHot',
    'softswiss:Minesweeper',
    'softswiss:Plinko',
    'softswiss:PrincessOfSky',
    'technology:HOT7sXII',
    'thunderkick:Barbershop_Uncut_tk',
    'thunderkick:CosmicVoyager_tk',
    'thunderkick:FlameBusters_tk',
    'thunderkick:Flux_tk',
    'yggdrasil:TheHotOffer',
    'dazzling_hot',
    'dazzling_hot_html',
    'flaming_hot_html',
  ],
  wild: [
    'wild_stars_original',
    'wild_dragon_original',
    'wild_respin_original',
    'wild_seven_original',
    'wild_shark_original',
    'evoplay:ChristmasParty',
    'santaswildride_mg_html',
    'wildscarabs_mg_html',
    'evoplay:WildBullets',
    'mascot:wild_spirit',
    'wildhorses_gt_html',
    'wildadventure_gt_html',
    'tomhorn:BeastieBux',
    'tomhorn:WildWeather',
    'wild_worlds',
    'wild_water_html',
    'quickspin:DwarfsGoneWild',
    'quickspin:WildHarlequinbasic',
    'quickspin:WildLuchador',
    'qso_wildchase',
    'qso_dwarfsgonewild',
    'wazdan:WildGirls',
    'wazdan:WildJack',
    'wazdan:WildJack81',
    'wj81_wzdn',
    'wj_wzdn',
    'wildfruits27_ag',
    'extrawild_mk_html',
    'ultra7wild_mk_html',
    'epicmedia:ExtraWild',
    'epicmedia:MultiWild',
    'multi_vegas_81_kjt_html',
    'joker_27_kjt_html',
    'infin:MegaBurningWins27ways',
    'infin:WildWarriors',
    'wild_burning_wins_5_ps_html',
    'wild_warriors_ps_html',
    'kagaming:GlacialEpoch',
    'kagaming:GoldenBull',
    'kagaming:HappyFarm',
    'kagaming:HappyThanksgiving',
    'kagaming:Jungle',
    'kagaming:KAFishHunter',
    'kagaming:Kitty',
    'kagaming:MeerkatsFamily',
    'kagaming:MonkeyAndCrab',
    'kagaming:Pets',
    'kagaming:PhoenixRising',
    'kagaming:TaiwanBlackBear',
    'kagaming:TheDeepMonster',
    'kagaming:WildAlaska',
    'kagaming:WildVick',
    'kagaming:WildWildBell',
    'habanero:SGBirdOfThunder',
    'habanero:SGFireRooster',
    'infin:Wild888',
    'wild_888_bng_html',
    'belatra:TheWildlife',
    'belatra:TheWildlife2',
    'booming:BuffaloHoldandWin',
    'booming:CheekyMonkeys',
    'epicmedia:WildAntics',
    'epicmedia:Windfall',
    'everymatrix:WildGold',
    'everymatrix:WildReels',
    'booming:MightyGorilla',
    'booming:MoneyMoose',
    'booming:RhinoHoldandWin',
    'booming:SharkMeet',
    'booming:WildEnergy',
    'booming:WildJester',
    'booming:WildOcean',
    'booming:WildPride',
    'booming:WildWildVegas',
    'booming:Wombaroo',
    'igtech:AmazingKong',
    'igtech:AncientGiants',
    'igtech:MonkeyKing',
    'igtech:SafariDeluxe',
    'kalamba:MonkeyGodBuyFeature_k',
    'kalamba:MonkeyGod_k',
    'kalamba:SafariChase_k',
    'kalamba:Wildcraft_k',
    'mrslotty:WildKingdom',
    'mrslotty:WildWest',
    'mrslotty:wildclover506',
    'mrslotty:wildcloverdice40',
    'mrslotty:WildFaires',
    'mrslotty:WildGiantPanda',
    'netgame:WildBuffalo',
    'nucleus:30091',
    'nucleus:30350',
    'nucleus:30358',
    'softswiss:BobsCoffeeShop',
    'softswiss:CrazyStarter',
    'platipus:wildjustice',
    'platipus:wildspin',
    'technology:AlaskaWild',
    'softswiss:JogoDoBicho',
    'softswiss:SlotomonGo',
    'yggdrasil:WildPops',
    'amazing_amazonia',
    'amazing_amazonia_html',
    'majestic_forest_html',
    'majestic_forest',
  ],
  books: [
    'a_book_of_aztec_original',
    'book_of_fortune_original',
    'golden_book_original',
    'bookofqueen_original',
    'bookoflords_original',
    'evoplay:BookofKeno',
    'evoplay:BookofRest',
    'mascot:book_of_amaterasu',
    'bookofradeluxe_gt_html',
    'bookofraclassic_gt_html',
    'bookofstars_gt_html',
    'bookofmaya_gt_html',
    'tomhorn:BookOfSpells',
    'book_of_ra_classic_html',
    'spellcast_html',
    'quickspin:BookofDuat',
    'quickspin:BookofDuat90',
    'quickspin:WildTomeoftheWoods',
    'kagaming:BookOfMummy',
    'bom_wzdn',
    'infin:BookofGold2DoubleHit',
    'infin:BookofGoldClassic',
    'infin:BookofGoldDoubleChance',
    'infin:BookofGoldMultichance',
    'infin:BookofGoldSymbolChoice',
    'book_of_gold_choice_ps_html',
    'book_of_gold_ps_html',
    'book_of_gold_classic_ps_html',
    'infin:BookofSun',
    'infin:BookofSunChoice',
    'infin:BookofSunMultichance',
    'infin:BookofWizard',
    'belatra:BookofDoom',
    'book_of_sun_bng_html',
    'book_of_sun_multichance_bng_html',
    'belatra:MayanBookMultiChoice',
    'booming:BookOfTombs',
    'bsg:BookofDarkness',
    'epicmedia:TombofDeadPower4slots',
    'everymatrix:BlueDiamondBook',
    'everymatrix:BookOfSoulsRemastered',
    'everymatrix:BookOfSpiesMissionX',
    'everymatrix:BookOfThePrincess',
    'everymatrix:BookofMuertitos',
    'everymatrix:BookofSouls',
    'everymatrix:BookofSoulsIIElDorado',
    'gameart:BookOziris',
    'gameart:BookofAlchemy',
    'groove:LinesOfMagic',
    'groove:BookOfTattoo',
    'groove:BookOfTattooII',
    'mrslotty:BookofBruno',
    'mrslotty:BookofSpells',
    'mrslotty:BookofSpellsDeluxe',
    'mrslotty:LostBook',
    'mrslotty:bookofluxordouble',
    'netgame:BookOfNileLostChapter',
    'netgame:BookofNileHOLDNINK',
    'netgame:BookofNileMagicChoice',
    'netgame:BookofNileRevenge',
    'onlyplay:BookofBambo',
    'onlyplay:BookofEye',
    'onlyplay:FruityBook',
    'platipus:bookofegypt',
    'softswiss:BookOfCats',
    'softswiss:BookOfPyramids',
    'zillion:bookOfSpells',
    'book_of_magic_html',
    'book_of_magic',
  ],
  jokers: [
    'ladyjoker_original',
    'luckyjoker40_original',
    'luckyjoker20_original',
    'luckyjoker5_original',
    'goldenjoker_original',
    'allwaysjoker_original',
    'megajoker_gt_html',
    'kingofcards_gt_html',
    'tomhorn:JokerReelz',
    'tomhorn:TripleJoker',
    'king_of_cards_classic_html',
    'joker_pro',
    'quickspin:JokerStrike',
    'qso_jokerstrike',
    'wazdan:JokerExplosion',
    'wazdan:JokerPoker',
    'wazdan:ReelJoke',
    'bonusjokerii_ag',
    'mysteryjokerii_ag',
    'smilingjokerii_ag',
    'epicmedia:JokersCap',
    'joker_27_plus_kjt_html',
    'joker_boom_plus_kjt_html',
    'joker_81_kjt_html',
    'joker_strong_kjt_html',
    'super_joker_40_kjt_html',
    'infin:100JokerStaxx100lines',
    'infin:40JokerStaxx40lines',
    'infin:Fruits&Jokers100Lines',
    'infin:FruitsJokers20lines',
    'infin:FruitsandJokers40lines',
    'infin:JokerExpand40lines',
    'infin:JokerExpand5lines',
    'joker_expand_ps_html',
    '100_joker_staxx_ps_html',
    '40_joker_staxx_ps_html',
    'fruits_and_jokers_100_ps_html',
    'fruits_and_jokers_20_ps_html',
    'fruits_and_jokers_40_ps_html',
    'joker_expand_40_ps_html',
    'kagaming:JokerFruit',
    'kagaming:JokerSlot',
    'habanero:SGJump',
    'belatra:Carousel',
    'bsg:JumboJoker',
    'everymatrix:DarkJoker',
    'booming:JokersWild',
    'groove:Joker',
    'groove:SpinJoker',
    'groove:StonedJoker',
    'groove:StonedJoker5',
    'kalamba:DoubleJoker_k',
    'kalamba:JokerTimesXmas_k',
    'kalamba:JokerTimes_k',
    'kalamba:Joker3600_k',
    'kalamba:JokerLanterns_k',
    'kalamba:JokerMax_k',
    'kalamba:JokerSupremeXmasEdition_k',
    'kalamba:JokerSupreme_k',
    'mrslotty:MysteryJokerHot',
    'mrslotty:goldenjokerdice',
    'mrslotty:jokercards',
    'mrslotty:jokerdice',
    'nucleus:30394',
    'onlyplay:JackPotter',
    'onlyplay:JackPotterXMAS',
    'onlyplay:JokerCoins',
    'onlyplay:JokerCoinsXMAS',
    'technology:BigJoker',
    'technology:CloverJoker',
    'yggdrasil:Jokerizer',
    'yggdrasil:LightningJoker',
    'yggdrasil:TheDarkJokerRizes',
    'yggdrasil:WickedCircus',
  ],
  egypt: [
    'eye_of_ra_original',
    'bookofqueen_original',
    'enchantedcleopatra_original',
    'evoplay:CurseofthePharaoh',
    'evoplay:CurseofthePharaoh1',
    'evoplay:EgyptGods',
    'godsofgiza_mg_html',
    'isis_mg_html',
    'evoplay:Sindbad',
    'evoplay:TempleOfDead',
    'evoplay:TempleofDeadBonusBuy',
    'evoplay:TotemIsland',
    'mascot:AnksunamunTQoE',
    'mascot:BastetAndCats',
    'mascot:cleopatras_gems_rockways',
    'bookofradeluxe_gt_html',
    'bookofraclassic_gt_html',
    'pharaohstomb_gt_html',
    'bookofradeluxe6_gt_html',
    'pharaohsring_gt_html',
    'ramses2_gt_html',
    'pharaohsnight_gt_html',
    'tomhorn:BlackMummy',
    'tomhorn:TheSecretofBa',
    'tomhorn:ThronesofPersia',
    'pharaons_gold2_classic_html',
    'book_of_ra_classic_html',
    'pyramid_new',
    'kagaming:Aladdin',
    'wazdan:GoldenSphinx',
    'wazdan:PowerofGodsEgypt',
    'wazdan:RelicHuntersBookofFaith',
    'kagaming:Egypt',
    'kagaming:EgyptianEmpress',
    'kagaming:EgyptianMythology',
    'gs_wzdn',
    'amuletofthepharaoh_mk_html',
    'fireofegypt_mk_html',
    'epicmedia:EyeOfHorus',
    'epicmedia:LuckyPharaoh',
    'secrets_of_egypt_kjt_html',
    'infin:9HappyPharaohs',
    'infin:BookofGold2DoubleHit',
    'infin:BookofGoldClassic',
    'infin:BookofGoldDoubleChance',
    'infin:BookofGoldMultichance',
    'infin:BookofGoldSymbolChoice',
    'infin:LegendofCleopatra',
    'infin:RiseofEgypt',
    'infin:RiseofEgypt',
    'infin:RiseofEgyptDeluxe',
    'infin:SolarKing',
    'infin:SolarQueen',
    'infin:SpiritofEgyptHoldandWin',
    'fruits_of_the_nile_c_ps_html',
    'legend_of_cleopatra_ps_html',
    'rise_of_egypt_ps_html',
    'book_of_gold_classic_ps_html',
    'book_of_gold_ps_html',
    'kagaming:SilkRoad',
    'infin:3CoinsEgypt',
    'infin:BookofSun',
    'queen_of_the_nile_2',
    'queen_of_the_nile_2_html',
    'cleopatra_gpe',
    'habanero:SGEgyptianDreamsDeluxe',
    'habanero:SGOrbsOfAtlantis',
    'habanero:SGQueenOfQueens1024',
    'infin:BookofSunChoice',
    'infin:BookofSunMultichance',
    'infin:EyeOfGold',
    'infin:GodsTempleDeluxe',
    'alg:AgeofCleopatra',
    'alg:FortuneGenie',
    'infin:ScarabBoost',
    'infin:ScarabRiches',
    'infin:ScarabTemple',
    'infin:SunOfEgypt2',
    'infin:SunofEgypt',
    'scarab_riches_bng_html',
    'book_of_sun_multichance_bng_html',
    'secret_of_nefertiti_2_bng_html',
    'romantribune_knm_html',
    'booming:ArabianSpins',
    'booming:AztecPalace',
    'booming:BoomerangBonanza',
    'booming:BoomingBananas',
    'bsg:LegendOfTheNile',
    'bsg:Lost',
    'epicmedia:CryptoftheDead',
    'epicmedia:EyeofHorusMegaways',
    'epicmedia:ScribesofThebes',
    'epicmedia:TombofDeadPower4slots',
    'everymatrix:BlueDiamondBook',
    'everymatrix:BookOfThePrincess',
    'everymatrix:JamesGoldandtheMummyRiches',
    'everymatrix:LaraJonesisCleopatra',
    'everymatrix:LaraJonesisCleopatra2',
    'everymatrix:RulerofEgypt',
    'gameart:BookOziris',
    'gameart:Cleopatra',
    'booming:LuckyScarabs',
    'gameart:GoldOfRa',
    'gameart:NefertitiHyperways',
    'gameart:NefertitisNile',
    'gameart:RamsesTreasure',
    'groove:BookofAnunnaki',
    'groove:PharaohsTemple',
    'booming:SphinxFortune',
    'groove:CleopatrasDiary',
    'booming:TikiWins',
    'groove:ImhotepManuscript',
    'booming:ValleyofPharaohs',
    'groove:Mummy',
    'groove:TheMummyWinHunters',
    'groove:TheMummyWinHuntersEPICWAYS',
    'groove:PlaguesofEgypt',
    'igtech:EgyptianTales',
    'infin:BookofSand',
    'infin:AlexandriaCityofFortune',
    'infin:TheTabletofAmunRa',
    'mancala:OpenTomb',
    "kalamba:Pharoah'sReign_k",
    'mrslotty:LostBook',
    'mrslotty:Pyramid',
    'mrslotty:godsofegypt',
    'mrslotty:bookofluxordouble',
    'mrslotty:treasuresofegypt',
    'mrslotty:HorusEye',
    'netgame:CleosHeart',
    'nucleus:30373',
    'onlyplay:BookofEye',
    'onlyplay:MythsofBastet',
    'nucleus:SpiritoftheNile',
    'platipus:bookofegypt',
    'platipus:egyptiangold',
    'platipus:pharaohsempire',
    'softswiss:AztecMagic',
    'softswiss:AztecMagicDeluxe',
    'platipus:lordofthesun',
    'technology:BlackPharaoh',
    'softswiss:ScrollOfAdventure',
    'technology:PyramidofGold',
    'technology:RamessestheGreat',
    'technology:ThePowerofAnkh',
    'yggdrasil:TutsTwister',
    'thunderkick:Pyramyth_tk',
    'yggdrasil:WordofThoth',
    'zillion:BookOfHor',
    'zillion:christmasHor',
    'thunderkick:TurningTotems_tk',
  ],
  asia: [
    'evoplay:TheGreatWallTreasure',
    'lucky_coin_original',
    'big_panda_original',
    'evoplay:AnimalQuest',
    'n108heroes_mg_html',
    'luckytwins_mg_html',
    'asianbeauty_mg_html',
    'huangditheyellowemperor_mg_html',
    'fortunegirl_mg_html',
    'evoplay:MysteriesoftheEast',
    'evoplay:RedCliff',
    'evoplay:TheEmperorsTomb',
    'evoplay:TheLegendofShaolin',
    'mascot:bamboo_bear',
    'mascot:book_of_amaterasu',
    'mascot:prince_of_persia_tgop',
    'mascot:the_rite',
    'ninjapathclient_gt_html',
    'tomhorn:FengFu',
    'tomhorn:GeishasFan',
    'tomhorn:PandasRun',
    'tomhorn:ShaolinsTiger',
    'shangrila_cluster_pays',
    'long_pao',
    'whos_the_bride',
    'quickspin:DivineDreams',
    'quickspin:EasternEmeralds',
    'kagaming:BaWangBieJi',
    'kagaming:ButterflyLovers',
    'kagaming:CaiYuanGuangJin',
    'kagaming:ChiYou',
    'kagaming:ChineseAncientTomb',
    'kagaming:ChineseOpera',
    'kagaming:ChineseValentinesDay',
    'kagaming:CuJu',
    'kagaming:EarthGod',
    'kagaming:EightTreasures',
    'kagaming:EmperorQin',
    'kagaming:FaCaiDestiny',
    'kagaming:FlowerGoddessFestival',
    'kagaming:FlowersFruitMountain',
    'kagaming:FortuneFu',
    'kagaming:FortuneGanesha',
    'kagaming:FortuneGod',
    'kagaming:FortuneLions',
    'kagaming:FortunePiggyBank',
    'kagaming:FourBeauties',
    'kagaming:FourScholars',
    'kagaming:GenghisKhan',
    'kagaming:GodofLove',
    'kagaming:GreatVoyages',
    'kagaming:HengandHa',
    'kagaming:HouYi',
    'kagaming:HuYeh',
    'kagaming:HuaMulan',
    'kagaming:ImperialGuards',
    'kagaming:JourneyToWest',
    'kagaming:LanternFestival',
    'kagaming:LeagueOfGods',
    'kagaming:LegendOfPaladin',
    'kagaming:LionDance',
    'kagaming:LuckyCat',
    'kagaming:LuckyLucky',
    'kagaming:Masquerade',
    'kagaming:Mazu',
    'kagaming:Nian',
    'kagaming:PandaFamily',
    'kagaming:QuickPlayMahjong',
    'kagaming:RedBoy',
    'kagaming:Rarities',
    'kagaming:TaiChi',
    'kagaming:RichSquire',
    'kagaming:Samura',
    'kagaming:Tao',
    'kagaming:ThreeGods',
    'kagaming:ThreeHeroes',
    'kagaming:ThreeKingdoms',
    'kagaming:TombHeroes',
    'kagaming:WanFuJinAn',
    'kagaming:WelcomeFortune',
    'kagaming:WenDing',
    'kagaming:Wencheng',
    'kagaming:WestChamber',
    'kagaming:WhiteSnakeLegend',
    'kagaming:WongTaiSin',
    'kagaming:WuGang',
    'kagaming:WuSong',
    'kagaming:WuZetian',
    'kagaming:XBomber',
    'kagaming:Yamato',
    'kagaming:YuGong',
    'kagaming:YueFei',
    'kagaming:YunCaiTongZi',
    'choy_sun_doa',
    'choy_sun_doa_html',
    'jewel_of_the_arts',
    'one_hundred_pandas',
    'habanero:SG5LuckyLions',
    'habanero:SGFaCaiShen',
    'habanero:SGFaCaiShenDeluxe',
    'habanero:SGFortuneDogs',
    'habanero:SGHeySushi',
    'habanero:SGMysticFortune',
    'habanero:SGMysticFortuneDeluxe',
    'habanero:SGNineTails',
    'habanero:SGPandaPanda',
    'habanero:SGTheKoiGate',
    'habanero:SGWaysOfFortune',
    'habanero:SGWealthInn',
    'infin:BuddhaFortuneHoldandWin',
    'infin:BuddhaMegaways',
    'infin:GoldenDancingLion',
    'infin:GreatPandaHoldandWin',
    'infin:SuperRichGodHoldandWin',
    'infin:Wukong',
    'fu_cai_shen_bng_html',
    'buddha_fortune_bng_html',
    'supreme_fortune_bng_html',
    'fortunestacks_knm_html',
    'chinamystery_knm_html',
    'belatra:ShogunsFortune',
    'belatra:TheGolden88',
    'booming:AsiaWins',
    'bsg:BambooRush',
    'bsg:CaishensArrival',
    'bsg:MrMacau',
    'bsg:QuesttotheWes',
    'bsg:ThaiBlossoms',
    'epicmedia:EmpressDowagerCixi',
    'epicmedia:HailTheEmperor',
    'epicmedia:LuckyPanda',
    'epicmedia:MegaFortuneGod',
    'epicmedia:GongHeiGongHei',
    'everymatrix:EternalShogi',
    'everymatrix:GiantPanda',
    'everymatrix:LuckyHeaven',
    'booming:FengShuiKitties',
    'everymatrix:TheNeonSamuraiKawa',
    'booming:LedgendofQuYuan',
    'gameart:EmperorsWealth',
    'gameart:FortunePanda',
    'gameart:LuckyCoins',
    'gameart:SushiYatta',
    'gameart:ThreeKings',
    'groove:CaiShen689',
    'igtech:CaishensFortune',
    'igtech:ThreeKings',
    'igtech:ThreeWitches',
    'kalamba:BangkokDreams_k',
    'kalamba:HongBao_k',
    'mrslotty:AncientArtifacts',
    'mrslotty:Bagua',
    'mrslotty:Bagua2',
    'mrslotty:BushidoBlade',
    'mrslotty:CaishenRiches',
    'mrslotty:China',
    'mrslotty:ChineseBoss',
    'mrslotty:DragonofTheEasternSea',
    'mrslotty:EmpressRegnant',
    'mrslotty:FengHuang',
    'mrslotty:FourDragons',
    'mrslotty:LightningGod',
    'mrslotty:OngBak2',
    'mrslotty:PeachBanquet',
    'mrslotty:Phoenix888',
    'mrslotty:TaiShangLaoSun',
    'mrslotty:TheWildProtectors',
    'mrslotty:ThirdPrincesJourney',
    'netgame:DancingLanters',
    'netgame:LotusFortune',
    'netgame:ZenZenCash',
    'nucleus:30227',
    'nucleus:30228',
    'platipus:bamboogrove',
    'platipus:caishensgifts',
    'platipus:jadevalley',
    'platipus:luckycat',
    'platipus:powerofgods',
    'platipus:dajidali',
    'platipus:jadevalley',
    'technology:MightyMoon',
    'thunderkick:TheFalconHuntress_tk',
    'fortune_spells',
  ],
  wolfs: [
    'coolwolf_mg_html',
    'silverfang_mg_html',
    'mascot:northen_heat',
    'tomhorn:WolfSierra',
    'wolf_cub',
    'spinsane',
    'the_wolfs_bane',
    'quickspin:BigBadWolfMegaways',
    'qso_bbw',
    'kagaming:ArcticStorm',
    'kagaming:Aurora',
    'infin:WolfPower',
    'kagaming:SiberianWolves',
    'kagaming:WonWonCatching',
    'kagaming:WonWonRich',
    'infin:BlackWolf',
    'werewolf_wild',
    'wild_wolf',
    'wolf_run_gpe',
    'infin:WolfNight',
    'infin:WolfSaga',
    'goldenwolves_knm_html',
    'bsg:WolfMoonRising',
    'epicmedia:WolfLegendMegaways',
    'booming:HowlingWolves',
    'booming:HowlingWolvesMegaways',
    'gameart:WolfHunt',
    'gameart:WolfQuest',
    'igtech:WolfTreasure',
    'platipus:magicalwolf',
    'thunderkick:BeattheBeastCerberusInferno',
    'technology:WildHills',
    'thunderkick:BeattheBeastGriffinsGold_tk',
    'yggdrasil:WolfHunters',
    'thunderkick:BorkTheBerzerker_tk',
  ],
  worldOfSea: [
    'fishingwar',
    'fishinggod',
    'blue_dolphin_original',
    'wild_shark_original',
    'mermaidsmillions_mg_html',
    'dolphinquest_mg_html',
    'alaskanfishing_mg_html',
    'hollyjollypenguins_mg_html',
    'evoplay:DolphinsTreasure',
    'alaskanfishing_mg_html',
    'evoplay:TreeOfLight',
    'dolphinspearlclassic_gt_html',
    'dolphinspearldeluxe_gt_html',
    'lordoftheocean_gt_html',
    'orca_gt_html',
    'plentyontwenty_gt_htm',
    'caribbeanholidays_gt_html',
    'oceantale_gt_html',
    'hypnohippo_gt_html',
    'dolphins_pearl_classic_html',
    'oceans_treasure',
    'quickspin:BlueFortune',
    'wazdan:CaptainShark',
    'kagaming:AnimalFishing',
    'wazdan:HungryShark',
    'wazdan:LuckyFish',
    'kagaming:FishingExpedition',
    'cs_wzdn',
    'hs_wzdn',
    'infin:GodofWildSea',
    'kagaming:GoldenFish',
    'kagaming:LuckyPenguins',
    'habanero:SGCashReef',
    'habanero:SGJellyfishFlow',
    'habanero:SGTreasureDiver',
    'bsg:Angler',
    'epicmedia:FishinFrenzyMegaways',
    'epicmedia:FishinFrenzyPower4slots',
    'epicmedia:FishingFrenz',
    'booming:DolphinsLuckTwo',
    'booming:Hooked',
    'gameart:DolphinsDream',
    'gameart:WildDolphin',
    'groove:AmazingYu',
    'groove:DeepBlueSee',
    'groove:JewelSea',
    'igtech:BlueReef',
    'infin:FishingWeekend',
    'infin:KrakenConquest',
    'infin:SavageShark',
    'mancala:HugeCatch',
    'mrslotty:dolphinsgold',
    'netgame:DolphinQueen',
    'netgame:FishingKingdom',
    'nucleus:30317',
    'nucleus:30318',
    'platipus:greatocean',
    'platipus:luckydolphin',
    'softswiss:LuckyBlue',
    'technology:CarribeanDiver',
    'technology:FortuneFish',
    'technology:JollyBelugaWhales',
    'technology:MightyKraken',
    'yggdrasil:GoldenFishtTank2Gigablox',
    'yggdrasil:GoldenFishtank',
    'zillion:OceansTreasures',
  ],
  girls: [
    'yggdrasil:Valkyries',
    'mrslotty:hothoney22',
    'evoplay:SweetSugar',
    'mrslotty:hothoney22vip',
    'groove:BookOfAnime',
    'lucky_coin_original',
    'lovely_lady_original',
    'fortunas_fruits_original',
    'ladyjoker_original',
    'mermaids_gold_original',
    'magic_owl_original',
    'red_chili_original',
    'bookoflords_original',
    'partynight_original',
    'enchantedcleopatra_original',
    'agentjaneblonde_mg_html',
    'asianbeauty_mg_html',
    'immortalromance_mg_html',
    'lifeofriches_mg_html',
    'ariana_mg_html',
    'isis_mg_html',
    'redlady_gt_html',
    'flamencoroses_gt_html',
    'seasirens_gt_html',
    'cindereela_gt_html',
    'fairyqueen_gt_html',
    'sweetsins_gt_html',
    'helena_gt_html',
    'riverqueen_gt_html',
    'springqueen_gt_html',
    'summerqueen_gt_html',
    'mayanmoons_gt_html',
    'bookofstars_gt_html',
    'magicprincess_gt_html',
    'mermaidspearl_gt_html',
    'secretelixir_gt_html',
    'showgirls_gt_html',
    'luckyladyscharmdeluxe6_gt_html',
    'queencleopatra_gt_html',
    'junglequeen_gt_html',
    'storiesofinfinity_gt_html',
    'luckyrose_gt_html',
    'tomhorn:BookOfSpells',
    'tomhorn:FrozenQueen',
    'lucky_ladys_charm_classic_html',
    'queen_of_hearts_classic_html',
    'magic_money_html',
    'koi_princess',
    'wilderland',
    'evoplay:AnubisMoon',
    'evoplay:BonanzaWheel',
    'evoplay:CharmingQueens',
    'evoplay:ChristmasReach',
    'evoplay:EllensFortune',
    'evoplay:ElvenPrincesses',
    'evoplay:Football',
    'evoplay:FruitBurst',
    'evoplay:GoldofSirensBonusBuy',
    'evoplay:HungryNight',
    'evoplay:LuckyGirls',
    'evoplay:MidnightShow',
    'evoplay:NaughtyGirlsCabaret',
    'evoplay:NightoftheLivingTales',
    'evoplay:PachinGirl',
    'evoplay:PlingoBall',
    'evoplay:Prohibition',
    'evoplay:RollToLuck',
    'evoplay:SeasonSisters',
    'mascot:AnksunamunTQoE',
    'mascot:book_of_amaterasu',
    'mascot:cancan_saloon',
    'mascot:cleopatras_gems_rockways',
    'mascot:fairytale_coven',
    'dynastyofra_gt_html',
    'goldenark_gt_html',
    'mascot:mermaids_bay',
    'mascot:pinup_dolls',
    'mascot:queen_of_spades',
    'infin:PumkinFairy',
    'quickspin:RapunzelsTower',
    'quickspin:SakuraFortune',
    'quickspin:WildChaseTokyoGo',
    'qso_sakurafortune',
    'wazdan:Backto70s',
    'wazdan:BeachParty',
    'wazdan:BeautyFruity',
    'wazdan:BurningReels',
    'wazdan:FruitManiaDeluxe',
    'kagaming:AliceInWonderland',
    'kagaming:AlterWorld',
    'wazdan:HighwaytoHellDeluxe',
    'wazdan:Hot777Deluxe',
    'wazdan:HotPartyDeluxe',
    'wazdan:JackpotBuilders',
    'wazdan:JacksRide',
    'wazdan:LuckyFortune',
    'wazdan:LuckyQueen',
    'wazdan:MiamiBeach',
    'kagaming:Cancan',
    'kagaming:CharmingSorceress',
    'kagaming:Cinderella',
    'kagaming:FairyDust',
    'kagaming:FairyForestTale',
    'wazdan:WildGirls',
    'kagaming:Gem',
    'lf_wzdn',
    'nc81_wzdn',
    'bp_wzdn',
    'lq_wzdn',
    'pandora_ag',
    'railroad_mk_html',
    'infin:LegendofCleopatra',
    'infin:SolarQueen',
    'infin:SpiritofEgyptHoldandWin',
    'infin:SolarTemple',
    'legend_of_cleopatra_ps_html',
    'kagaming:GoldenShanghai',
    'kagaming:ImperialGirls',
    'kagaming:Jingwei',
    'kagaming:LegendOfFoxSpirit',
    'kagaming:LiveStreamingStar',
    'kagaming:LoungeClub',
    'kagaming:Mermaid',
    'kagaming:MermaidHunter',
    'kagaming:MilkGirl',
    'kagaming:MillenniumLove',
    'kagaming:Millionaires',
    'kagaming:MoonGoddess',
    'kagaming:Mythic',
    'kagaming:Neanderthals',
    'kagaming:Nezha',
    'kagaming:Nvwa',
    'kagaming:PartyGirl',
    'kagaming:PartyGirlWays',
    'kagaming:PersiaBonanza',
    'kagaming:RedRidingHood',
    'kagaming:SecretOfOcean',
    'kagaming:SevenHeroines',
    'kagaming:ShadowPlay',
    'kagaming:ShoppingFiend',
    'kagaming:SnowQueen',
    'kagaming:SnowWhite',
    'kagaming:TheGoldenAx',
    'kagaming:SunnyBikini',
    'kagaming:SushiNinja',
    'kagaming:SweetMaid',
    'kagaming:TheNutCracker',
    'western_belles',
    'miss_red',
    'habanero:SGBikiniIsland',
    'habanero:SGBombsAway',
    'habanero:SGDiscoFunk',
    'habanero:SGFlyingHigh',
    'habanero:SGIndianCashCatcher',
    'habanero:SGNuwa',
    'habanero:SGOceansCall',
    'habanero:SGPamperMe',
    'habanero:SGProst',
    'infin:MagicBall',
    'infin:MoonSistersHoldandWin',
    'belatra:Action!',
    'belatra:BeautyBeast',
    'belatra:BondedWarehouse',
    'infin:PoisonedApple2',
    'wonderrose_knm_html',
    'heartofromance_knm_html',
    'gypsyfire_knm_html',
    'belatra:PrincessOfSwamp',
    'booming:BacheloretteParty',
    'bsg:AtTheCopa',
    'bsg:BloodEternal',
    'bsg:FaerieSpells',
    'bsg:GypsyRose',
    'bsg:TipsyTourist',
    'epicmedia:LegacyofRaMegaways',
    'everymatrix:GoldenEngines',
    'booming:DevilsHeat',
    'booming:GenieWishes',
    'booming:GoldenGirls',
    'booming:GoldenProfits',
    'booming:HarvestFest',
    'booming:LaRomantica',
    'booming:LotusLove',
    'gameart:DragonLady',
    'gameart:JoanofArc',
    'booming:Octoberfest',
    'gameart:LadyLuck',
    'gameart:NornsFate',
    'gameart:TigerHeart',
    'gameart:WolfHunt',
    'booming:Romeo',
    'groove:MonacoFever',
    'groove:DonSlottione',
    'groove:FortuneCircus',
    'groove:Resident3D',
    'groove:RomanceV',
    'groove:SpinJoker',
    'groove:WildRodeo',
    'booming:Wunderfest',
    'booming:WunderfestDeluxe',
    'igtech:FairyWings',
    'igtech:Godiva',
    'igtech:GoldenQueen',
    'igtech:JungleQueen',
    'igtech:MoonGoddess',
    'igtech:QueenAndTheDragons',
    'igtech:QueenOfTheSea',
    'igtech:RomanEmpire',
    'igtech:VegasRiches',
    'kalamba:BeersonReels_k',
    'kalamba:BigBountyBill_k',
    'kalamba:CaribbeanAnne2_k',
    'kalamba:CaribbeanAnne_k',
    'infin:Bandida',
    'kalamba:ElVigilante_k',
    'infin:FortuneTellersCharm',
    'infin:SkyVault',
    'infin:WildJane',
    'kalamba:LadyLava_k',
    'kalamba:MermaidsGalore_k',
    'mancala:BloodRomance',
    'mancala:BrazilCarnival',
    'mancala:Casinonight',
    'mancala:ChineseForest',
    'mancala:PirateCave',
    'mancala:PortalMaster',
    'mancala:SpiritOfTheLake',
    'mancala:Starwins',
    'mancala:TheTwinWinsMystery',
    'mancala:WickedHeart',
    'mancala:ZeroDay',
    'kalamba:OperationDiamondHunt_k',
    'kalamba:PyroPixie_k',
    'kalamba:RubyHunter_k',
    'kalamba:SadieSwiftGunsandGlyphs_k',
    'mrslotty:AlohaCharm',
    'kalamba:SpeakeasyBoost',
    'kalamba:ZombieQueen_k',
    'mrslotty:cleopatra18',
    'mrslotty:lollasworld',
    'mrslotty:VIPRoulette',
    'mrslotty:Roulette',
    'mrslotty:Postman',
    'mrslotty:LuxRoulette',
    'mrslotty:mermaidgold',
    'mrslotty:trendyskulls',
    'mrslotty:ChineseBoss',
    'mrslotty:EmpressRegnant',
    'mrslotty:EnchantedForest',
    'mrslotty:EnterTheKTV',
    'mrslotty:Immortals',
    'mrslotty:LadyHawk',
    'mrslotty:PeachBanquet',
    'mrslotty:Phoenix888',
    'mrslotty:WitchsBrew',
    'mrslotty:Yggdrasil',
    'netgame:HitinVegas',
    'netgame:LuxorRelics',
    'netgame:ShiningPrincess',
    'nucleus:30353',
    'nucleus:30354',
    'platipus:RoyalLotus',
    'platipus:cinderella',
    'platipus:fairyforest',
    'softswiss:CherryFiesta',
    'softswiss:DesertTreasure',
    'softswiss:HitTheRoute',
    'softswiss:JourneyFlirt',
    'softswiss:LuckyDamaMuerta',
    'softswiss:LuckyLadyClover',
    'softswiss:LuckyLadyMoon',
    'softswiss:MechanicalOrange',
    'softswiss:MissCherryFruits',
    'softswiss:PrincessRoyal',
    'softswiss:Road2Riches',
    'technology:CaraMiaCarmen',
    'technology:ChiliBaby',
    'technology:CloverWheel',
    'technology:EnchantedFruits',
    'technology:EnglishRose',
    'technology:ForestNymph',
    'technology:FruitofDesire',
    'technology:HalloweenDoubleHot',
    'technology:LadyEmerald',
    'technology:MiladyxII',
    'technology:MysticMoon',
    'technology:NorseQueen',
    'technology:OceanLegends',
    'technology:TheTempleOfAstarta',
    'technology:ThreeNymphs',
    'technology:UrbanLady',
    'technology:UrbanLadyLoveStory',
    'technology:NavyGirl',
    'yggdrasil:BeautyAndTheBeast',
    'yggdrasil:FrostQueenJackpots',
    'yggdrasil:JackpotExpress',
    'yggdrasil:LegendOfTheWhiteSnakeLady',
    'yggdrasil:VikingGoBerzekReloaded',
    'thunderkick:CarnivalQueen_tk',
    'thunderkick:CrystalQuestArcaneTower_tk',
    'thunderkick:CrystalQuestDeepJungle_tk',
    'thunderkick:CrystalQuestFrostlands_tk',
    'thunderkick:FullMoonRomance_tk',
    'yggdrasil:ElementalPrincess',
    'yggdrasil:MedusaFortuneandGlory',
    'yggdrasil:SirenSong',
    'yggdrasil:HunterMoonGigablox',
    'yggdrasil:LadyMerlin',
    'yggdrasil:MedusaHot1',
    'yggdrasil:Serendipity',
    'zillion:BookOfHor',
    'zillion:christmasHor',
    'zillion:christmasPyramid',
    'zillion:mistressOfPyramid',
    'blue_heart_html',
    'fortune_spells_html',
    'grace_of_cleopatra_html',
    'versailles_gold_html',
    'versailles_gold',
    'blue_heart',
  ],
  oldSchool: [
    'gonzos_quest_touch',
    'fruit_cocktail_original_html',
    'infin:FruitCocktail',
    'crazy_monkey_original_html',
    'infin:CrazyMonkey',
    'keks_original_html',
    'infin:Keks',
    'sweet_life_original_html',
    'infin:SweetLife',
    'resident_original_html',
    'infin:Resident',
    'garage_original_html',
    'infin:Garage',
    'gnome_original_html',
    'infin:Gnome',
    'qso_bbw',
    'qso_spinions',
    'qso_talesofdoctordolittle',
    'bookofradeluxe_gt_html',
    'bookofraclassic_gt_html',
    'columbusdeluxe_gt_html',
    'katana_gt_html',
    'bookofradeluxe6_gt_html',
    'megajoker_gt_html',
    'bananas_go_bahamas_html',
    'columbus_classic_html',
    'sizzling_hot_classic_html',
    'book_of_ra_classic_html',
    'queen_of_hearts_classic_html',
    'three_html',
    'the_money_game_classic_html',
    'marco_polo_classic_html',
    'magic_money_html',
    'pharaons_gold2_classic_html',
    'fruitshop_touch',
    'dead_or_alive',
    'fruitshop_christmas',
    'n108heroes_mg_html',
    'thunderstruck_mg_html',
    'surewin_mg_html',
    'tallyho_mg_html',
    'glr2_pt',
    'bvs_pt',
    'whk_pt',
    'gangsterworld_ag',
    'multi_vegas_81_kjt_html',
    'werewolf_wild_html',
    'buffalo',
    'cleopatra_igt_html',
    'aloha_cluster_pays',
    'ghost_pirates_html',
    'rock_climber_original_html',
    'infin:RockClimber',
    'pirate_original_html',
    'infin:Pirate',
    'island_original_html',
    'infin:Island',
    'lucky_haunter_original_html',
    'infin:LuckyHaunter',
    'fruit_cocktail_2_original_ht',
    'infin:FruitCocktailTwo',
    'crazy_monkey_2_original_html',
    'infin:CrazyMonkeyTwo',
    'sweet_life_2_original_html',
    'infin:SweetLifeTwo',
    'pirate_2_original_html',
    'infin:PirateTwo',
    'island_2_original_html',
    'infin:IslandTwo',
    'magicmirrordeluxe_mk_html',
    'dragonstreasure_mk_html',
    'fireofegypt_mk_html',
    'railroad_mk_html',
  ],
  luckyClower: [
    'evoplay:IrishReels',
    'quickspin:LeprechaunHills',
    'qso_leprechaunhills',
    'wazdan:CloverLady',
    'wazdan:LarrytheLeprechaun',
    'infin:CloverRiches',
    'infin:Fruits&Clovers20Lines',
    'infin:LuckyStaxx40lines',
    'clover_riches_ps_html',
    'kagaming:LandOfGold',
    'kagaming:Leprechauns',
    'alg:PatricksRiches',
    'patricks_pub_bng_html',
    'bsg:CharmsAndClovers',
    'epicmedia:FortuneSpins',
    'epicmedia:LuckoftheIrishMegaways',
    'epicmedia:WishUponALeprechaunMegaways',
    'everymatrix:ShamrockTreasures',
    'gameart:JumpinPot',
    'igtech:LeprachaunGoldDeluxe',
    'igtech:LeprachaunGold',
    'kalamba:FinnegansFormula_k',
    'infin:PatricksJackpot',
    'kalamba:JokerLeprechauns_k',
    'mancala:Green&Greedy',
    'mrslotty:40WildClover',
    'mrslotty:5CloverFire',
    'mrslotty:cloverfire40',
    'mrslotty:veryhot40',
    'mrslotty:veryhot5',
    'netgame:Cloverstones',
    'nucleus:30329',
    'nucleus:30330',
    'onlyplay:GoldenClover',
    'onlyplay:LuckyClover',
    'nucleus:TheGoldenInn',
    'softswiss:AllLuckyClover',
    'softswiss:AllLuckyClover100',
    'softswiss:AllLuckyClover200',
    'softswiss:AllLuckyClover400',
    'softswiss:AllLuckyClover5',
    'platipus:leprechauns',
    'softswiss:FourLuckyClover',
    'technology:20CloversHot',
    'softswiss:MechanicalClover',
    'technology:CloverGems',
    'technology:CloverJoker',
    'technology:CloverWhee',
    'technology:FullOfLuck',
    'technology:LuckyClover',
    'technology:WildClover',
    'yggdrasil:RainbowRyan',
    'zillion:LeprechaunsGold',
  ],
  gangsta: [
    'pistoleras_mg_html',
    'loaded_mg_html',
    'evoplay:GangsterNight',
    'evoplay:MafiaSyndicate',
    'evoplay:Syndicate',
    'garage_original_html',
    'infin:Garage',
    'americangangster_gt_html',
    'reel_steal_html',
    'dead_or_alive_html',
    'dead_or_alive_2',
    'wild_wild_west',
    'narcos',
    'jack_hammer_touch',
    'quickspin:StickyBandits',
    'quickspin:StickyBandits3MostWanted',
    'quickspin:StickyBanditsWildReturn',
    'qso_stickybandits',
    'wazdan:JackonHold',
    'kagaming:CatchTheThief',
    'wazdan:WildGuns',
    'joh_wzdn',
    'gangsterworld_ag',
    'bakerstreet_mk_html',
    'wanted_kjt_html',
    'infin:ChicagGangsters',
    'kagaming:ThreeBandits',
    'habanero:SGGangsters',
    'habanero:SGMrBling',
    'alg:BandidosShowdown',
    'alg:WildBandidos',
    'belatra:AnEscapeFromAlcatraz',
    'belatra:Chicagobangbang',
    'belatra:LuckyBankRobbers',
    'bsg:AfterNightFalls',
    'bsg:GoldCanyon',
    'bsg:Heist',
    'bsg:HouseOfFun',
    'bsg:NightInParis',
    'bsg:ReturntoParis',
    'bsg:Slotfather',
    'bsg:Slotfather2',
    'bsg:TakeSantasShop',
    'bsg:TakeTheBank',
    'bsg:TrueSheriff',
    'epicmedia:KingOfTheWest',
    'everymatrix:LuckyMrWild',
    'booming:GangsterGamblers',
    'gameart:TexasRangersReward',
    'gameart:WildWildQuest',
    'groove:DonSlottione',
    'groove:Yakuza',
    'igtech:GangsterCats',
    'igtech:GangsterCatsII',
    'kalamba:Agent51_k',
    'mancala:GunsnHeat',
    'mancala:PowerOfGuns',
    'mrslotty:BookofBruno',
    'mrslotty:ChineseBoss',
    'nucleus:30072',
    'nucleus:30074',
    'nucleus:30188',
    'nucleus:30189',
    'nucleus:30192',
    'nucleus:30193',
    'nucleus:30313',
    'nucleus:30314',
    'technology:ByeByeSpyGuy',
    'technology:CaratsWhisper',
    'yggdrasil:TheOneArmedBandit',
  ],
  sport: [
    'evoplay:Basketball',
    'evoplay:Football',
    'evoplay:FootballManager',
    'surewin_mg_html',
    'rock_climber_original_html',
    'infin:RockClimber',
    'flamedancer_gt_html',
    'tomhorn:CricketMania',
    'tomhorn:TheCup',
    'football',
    'wazdan:CrazyCars',
    'wazdan:FootballMania',
    'wazdan:FootballManiaDeluxe',
    'wazdan:KickOff',
    'kagaming:BaseballFever',
    'kagaming:Fastbreak',
    'goldcup_mk_html',
    'epicmedia:GoldCup',
    'kagaming:GoldenBall',
    'star_trek',
    'habanero:SGKnockoutFootball',
    'habanero:SGKnockoutFootballRush',
    'habanero:SGSuperStrike',
    'belatra:MWA',
    'bsg:HatTrickHero',
    'booming:Goal',
    'gameart:MaradonaHyperWays',
    'booming:SurfinReels',
    'booming:Tailgating',
    'epicmedia:DashingDerby',
    'epicmedia:EnglishFastLeagueFootballMatch',
    'epicmedia:EnglishFastLeagueFootballSingle',
    'epicmedia:IceHockey',
    'epicmedia:ItalianFastLeagueFootballSingle',
    'epicmedia:PlatinumHounds',
    'epicmedia:ProPongTableTennis',
    'epicmedia:SpanishFastLeagueFootballSingle',
    'igtech:Hockey',
    'igtech:HockeyWildMatch',
    'infin:Dog6',
    'infin:EnglandLeague',
    'infin:Horses8Sprint',
    'infin:MMAFighting',
    'infin:Speedway',
    'netgame:MMALegends',
    'nucleus:30392',
    'nucleus:30404',
    'nucleus:30414',
    'ucleus:WinterChamps',
    'softswiss:WbcRingOfRiches',
    'yggdrasil:Bicicleta',
    'yggdrasil:FootballGlory',
  ],
  sweets: [
    'evoplay:CandyDreams',
    'evoplay:JellyBoom',
    'keks_original_html',
    'mascot:purple_pills',
    'infin:Keks',
    'tomhorn:SweetCrush',
    'kagaming:Bakery',
    'kagaming:CandyMania',
    'kagaming:CandyStorm',
    'kagaming:ChristmasCandy',
    'kagaming:HoneyMoney',
    'kagaming:JellyMania',
    'kagaming:QuickPlayCandy',
    'kagaming:SantaBumbleBeeHoldandWin',
    'kagaming:Sweetopia',
    'kagaming:TheGingerbreadLand',
    'habanero:SGCakeValley',
    'habanero:SGCandyTower',
    'booming:BabyBloomers',
    'bsg:SugarPop',
    'bsg:SugarPop2DoubleDipped',
    'bsg:SuperSweetsbsg:SuperSweets',
    'epicmedia:SweetSuccessMegaways',
    'everymatrix:SweetGems',
    'gameart:CaptainCandy',
    'groove:SugarLand',
    'booming:SugarSkulls',
    'igtech:CandyRush',
    'igtech:CandyRushSummerTime',
    'igtech:CandyRushValentinesDay',
    'igtech:CandyRushWinter',
    'infin:CandyMix',
    'nucleus:30000',
    'nucleus:30001',
    'nucleus:30361',
    'nucleus:30362',
    'platipus:crazyjelly',
    'softswiss:CandyMonsta',
    'softswiss:LuckySweets',
    'thunderkick:SunsetDelight_tk',
  ],
  fantasy: [
    'beetlemaniadeluxe_gt_html',
    'queenofheartsdeluxe_gt_html',
    'rumpelwildspins_gt_html',
    'thealchemist_gt_html',
    'coinofapollo_gt_html',
    'bugsnbees_gt_html',
    'perchta_gt_html',
    'thirteen_gt_html',
    'gameofthrones_lines_mg_html',
    'gameofthrones_ways_mg_html',
    'thunderstruck_mg_html',
    'thunderstruckii_mg_html',
    'tomhorn:MonsterMadness',
    'arcane',
    'archangels',
    'vikings_html',
    'ghost_pirates_html',
    'golden_grimoire',
    'finn_and_the_swirly_spin',
    'golden_tavern',
    'evoplay:ForestDreams',
    'evoplay:ForgottenFable',
    'evoplay:GoldofSirens',
    'evoplay:GoldofSirensBonusBuy',
    'evoplay:HungryNight',
    'evoplay:JollyTreasures',
    'evoplay:JourneytotheWest',
    'evoplay:LegendOfKaan',
    'evoplay:LegendofRa',
    'evoplay:MagicWheel',
    'evoplay:MazeDesireforPower',
    'evoplay:MonsterLab',
    'evoplay:MysteryPlanet',
    'evoplay:Necromancer',
    'evoplay:NeonShapes',
    'evoplay:NightoftheLivingTales',
    'evoplay:RaccoonTales',
    'evoplay:RobinHood',
    'evoplay:Robots',
    'evoplay:RocketStars',
    'evoplay:RunesofDestiny',
    'evoplay:ShadowofLuxor',
    'evoplay:Sprinkle',
    'evoplay:TheGreatConflict',
    'evoplay:TheSlavs',
    'evoplay:TreasureMania',
    'evoplay:UnlimitedWishes',
    'evoplay:ValleyOfDreams',
    'evoplay:WheelofTime',
    'mascot:bennys_the_biggest_game',
    'gnome_original_html',
    'mascot:hellsing',
    'mascot:merlins_tower',
    'mascot:re_kill',
    'mascot:red_horde',
    'mascot:riot',
    'mascot:the_evil_bet',
    'mascot:the_myth',
    'mascot:zeus_the_thunderer',
    'infin:Gnome',
    'kagaming:DayOfDead',
    'kagaming:DrGeek',
    'kagaming:Dracula',
    'kagaming:Enchanted',
    'kagaming:ErlangShen',
    'kagaming:Fantasy777',
    'kagaming:FantasyPark',
    'kagaming:FluffyBuddy',
    'kagaming:Frankenstein',
    'kagaming:Giants',
    'kagaming:GoGoMonsters',
    'kagaming:Horoscope',
    'kagaming:KingOctopus',
    'kagaming:MagicApprentice',
    'kagaming:Meowfia',
    'kagaming:MonsterParade',
    'kagaming:Mushrooms',
    'kagaming:MysteryAlchemy',
    'kagaming:Pinocchio',
    'kagaming:Polynesian',
    'kagaming:Poseidon',
    'kagaming:PoseidonSecret',
    'kagaming:Robots',
    'kagaming:RouranKhaganate',
    'kagaming:RoyalDemeanor',
    'kagaming:SNS',
    'kagaming:Space',
    'kagaming:TheKingofDinosaurs',
    'kagaming:TheLegendofHeroes',
    'kagaming:UFO',
    'kagaming:Vampire',
    'kagaming:Viking',
    'kagaming:WerewolfIsComing',
    'kagaming:WitchAcademy',
    'kagaming:WizardofOz',
    'kagaming:Wizardry',
    'kagaming:ZombieLand',
    'kagaming:Zorro',
    'day_of_the_dead',
    'grand_monarch',
    'masques_of_san_marco',
    'habanero:SG12Zodiacs',
    'habanero:SG5Mariachis',
    'habanero:SGArcaneElements',
    'habanero:SGCalaverasExplosivas',
    'habanero:SGColossalGems',
    'habanero:SGGalacticCash',
    'habanero:SGJungleRumble',
    'habanero:SGKanesInferno',
    'habanero:SGMagicOak',
    'habanero:SGMonsterMashCash',
    'habanero:SGMummyMoney',
    'habanero:SGPumpkinPatch',
    'habanero:SGRollingRoger',
    'habanero:SGSpaceFortune',
    'habanero:SGTabernaDeLosMuertos',
    'habanero:SGTabernaDeLosMuertosUltra',
    'habanero:SGTheDeadEscape',
    'habanero:SGVikingsPlunder',
    'habanero:SGWeirdScience',
    'habanero:SGWickedWitch',
    'habanero:SGWizardsWantWar',
    'habanero:SGZeus',
    'alg:AdventureSaga',
    'belatra:7DaysAnotherland',
    'belatra:Anotherland',
    'belatra:BlueBeard',
    'belatra:DraculaRiches',
    'belatra:GhostWalks',
    'belatra:Halloween',
    'belatra:HalloweenCrystals',
    'belatra:HalloweenJackpot',
    'belatra:LoveMagic',
    'fortune_multiplier_bng_html',
    'vikings_winter_bng_htm',
    'belatra:NeptunesKingdom',
    'secretofthemermaid_knm_html',
    'belatra:OceanBed',
    'destinyofathena_knm_html',
    'belatra:ZombieTown',
    'bsg:2MillionBC',
    'bsg:4Seasons',
    'bsg:AlkemorsTower',
    'bsg:AmericanBlackjack',
    'bsg:GiovannisGems',
    'bsg:Gladiator',
    'bsg:LavaGold',
    'bsg:MilesBellhouseAndtheGearsOftime',
    'bsg:OgreEmpire',
    'bsg:OnceUponATime',
    'bsg:VikingAge',
    'bsg:VikingVoyage',
    'epicmedia:MasterofAtlantis',
    'epicmedia:RomeRiseofanEmpire',
    'epicmedia:VikingsUnleashedMegaways',
    'epicmedia:WildSpirit',
    'everymatrix:BlackForest',
    'booming:ColossalVikings',
    'everymatrix:KnightsOfFortune',
    'everymatrix:ValhollHalloftheSlain',
    'booming:GreekLegends',
    'booming:HorrorHouse',
    'gameart:AliBabasRiches',
    'gameart:AtlantisWorld',
    'gameart:AzrabahWishes',
    'gameart:BattleforAtlantis',
    'booming:LeprechaunsLuckyBarrel',
    'booming:MonsterMunchies',
    'gameart:JoanofArc',
    'booming:OhCatrina',
    'groove:WitcherCave',
    'booming:Spinosaurus',
    'groove:GoblinsLand',
    'groove:HorrorCastle',
    'booming:WitchesWildBrew',
    'booming:WizardingWins',
    'igtech:Hercules',
    'igtech:NorthernGods',
    'igtech:VikingHero',
    'kalamba:AgeOfHuracan_k',
    'kalamba:AtlantisThunderStPatricksDay_k',
    'kalamba:AtlantisThunder_k',
    'kalamba:BloodMoonExpress_k',
    'kalamba:DinoOdyssey_k',
    'infin:APiratesQuest',
    'infin:AliBaba',
    'infin:AztarFortunes',
    'kalamba:DucksTillDawn_k',
    'infin:MagicGemsDeluxe',
    'kalamba:GatesofBabylon_k',
    'kalamba:GoblinsGemstones_k',
    'infin:VikingRaidZone',
    'mancala:CastleRock',
    'kalamba:PearlsofAphrodite_k',
    'mrslotty:50linesofwar',
    'mrslotty:crazyhalloween',
    'mrslotty:draculasgems',
    'mrslotty:enchanted7s',
    'mrslotty:gemstower',
    'mrslotty:insects18',
    'mrslotty:marsdinner',
    'mrslotty:monsterbirds',
    'mrslotty:monsterinos',
    'mrslotty:quarantine',
    'mrslotty:zeusthethunderer',
    'mrslotty:zeusthethunderer2',
    'mrslotty:BushidoBlade',
    'mrslotty:EnchantedForest',
    'mrslotty:LadyHawk',
    'mrslotty:Yggdrasil',
    'mrslotty:WitchsBrew',
    'netgame:MagicTree',
    'netgame:Thunderstrike',
    'nucleus:30103',
    'nucleus:30126',
    'nucleus:30231',
    'nucleus:30232',
    'nucleus:30298',
    'nucleus:30299',
    'nucleus:30337',
    'nucleus:30338',
    'nucleus:30341',
    'nucleus:30342',
    'nucleus:30345',
    'nucleus:30346',
    'nucleus:30353',
    'nucleus:30354',
    'nucleus:30399',
    'nucleus:30405',
    'nucleus:KrakenDeepWins',
    'nucleus:TheForbiddenTomb',
    'nucleus:TheHauntedCarnival',
    'softswiss:BraveViking',
    'softswiss:Domnitors',
    'softswiss:JacksOrBetter',
    'softswiss:SpinAndSpell',
    'yggdrasil:AgeofAsgard',
    'yggdrasil:SaharaNights',
    'yggdrasil:TrollsBridge',
    'thunderkick:EsqueletoExplosivo2_tk',
    'thunderkick:EsqueletoExplosivo_tk',
    'thunderkick:FrogGrog_tk',
    'thunderkick:GodsofRock_tk',
    'yggdrasil:BrawlattheRedCapInn',
    'thunderkick:KrakensLair_tk',
    'thunderkick:Magicious_tk',
    'thunderkick:MidasGoldenTouch_tk',
    'yggdrasil:VikingRunes',
    'thunderkick:PinkElephants2_tk',
    'yggdrasil:GargoyleInfinityReels',
    'thunderkick:PinkElephants_tk',
    'thunderkick:RocketFellas_tk',
    'thunderkick:TheRift_tk',
    'zillion:GuardiansOfValhalla',
    'olympus_glory_html',
    'royal_secrets_html',
    'shining_crown_html',
  ],
}
