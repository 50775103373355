import { IJackpotItem, IJackpotItemDTO } from 'shared/models/Jackpots'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { JackpotsConverter } from './JackpotsConverter'

class JackpotsApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/jackpot`
    this.converter = new JackpotsConverter()
  }

  getJackpots = () =>
    this.sendQuery({
      type: QueryTypes.GET,
      url: `${this.baseUrl}/v1/jackpots/`,
      converterSuccess: this.converter.convertJackpotsList,
    })
}

export default JackpotsApi
