import { projectStyledName } from '../../constants'

export const mainPageDescription: { [key: string]: any } = {
  heading: `Become a friend of ${projectStyledName} and start playing!`,
  buttonText: 'Join',
  buttonTextIsHidden: 'Show more',
  buttonTextIsVisible: 'Show less',
  welcomeContent: {
    headerContent: {
      heading: `Welcome to the casino ${projectStyledName}`,
      item1: `We invite you to play all your favorite online casino games at ${projectStyledName}.
We have the latest, classic and most popular casino games,
which you can play on a mobile device, desktop computer or tablet.
Some games can be played for as little as 5 cents, and bets can reach thousands, especially in board games.`,
      item2: `We are proud of our collection of games and the high level of security
that we offer to the players of our online casino. Since we are a licensed casino, you can
be calm not only when viewing and choosing games, but also when making deposits.`,
      item3: `We have all kinds of games that a Curacao Casino player would like to spin, bet or play.
From bingo to slots, roulette, blackjack, baccarat, poker, betting games and much more.
Moreover, the games are accompanied by detailed annotations and guides at the bottom
to help you better navigate the games.`,
      item4: `If you are looking for a first-class online casino site, welcome to the casino ${projectStyledName}.
Explore our games, take advantage of online casino bonuses and have fun without any extra hassle!`,
    },
  },
}
