import React, { useCallback, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link, useHistory } from 'react-router-dom'
import block from 'bem-cn'
import { AnimatePresence } from 'framer-motion'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

import { ROUTE_URLS } from 'router/routes'

import { ReactComponent as MainLogoIcon } from 'shared/img/logo.svg'

import { mediaSize } from 'shared/style/var'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { addNotify } from 'components/Notify'
import SetLanguage from 'components/SetLanguage/SetLanguage'
import { IListMenuItem,initialClosedGroups, listMenu, navMenu } from 'components/SideMenu/data'

import { SideMenuItem } from './SideMenuItem/SideMenuItem'

import './SideMenu.scss'

interface ISideMenuProps {
  isOpen: boolean
  onClose: () => void
}

const b = block('side-menu')

export const SideMenu: React.FC<ISideMenuProps> = ({ isOpen, onClose }) => {
  const history = useHistory()
  const { pushNewModal } = useContext(ModalContext)
  const isDesktop = useMediaQuery({ minWidth: mediaSize.laptop })

  const locale = useSelector(selectLocaleDict, shallowEqual).home
  const localeSlots = useSelector(selectLocaleDict, shallowEqual).slots
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  const handleLinkClick = useCallback(
    (e: React.MouseEvent, item: IListMenuItem) => {
      if (item.authRequired && !isAuth) {
        e.preventDefault()
        addNotify(localeSlots.pleaseAuth)
        return
      }

      if (item.isModal) {
        e.preventDefault()
        pushNewModal(item.route)
        return
      }

      if (item.mobileRoute && !isDesktop) {
        e.preventDefault()
        history.push(item.mobileRoute)
      }

      onClose()
    },
    [history, isAuth, isDesktop, localeSlots, onClose, pushNewModal],
  )

  const renderItems = useCallback(
    listToRender => {
      if (!listToRender) return null

      const list = groupBy(listToRender.filter((item: IListMenuItem) => !item.isModal), 'group')

      return map(list, (group, groupTitle) => (
        <SideMenuItem
          key={`sideMenu_${groupTitle}`}
          title={groupTitle}
          subItems={group}
          locale={locale}
          initialClosed={initialClosedGroups[groupTitle]}
          callback={handleLinkClick}
        />
      ))
    },
    [handleLinkClick, locale],
  )

  return (
    <AnimatePresence exitBeforeEnter>
      <aside className={b({ isOpen })}>
        <div className={b('content')}>
          <div className={b('header')}>
            <div className={b('logo')} onClick={onClose}>
              <Link to={ROUTE_URLS.MAIN()} className={b('logo-link')}>
                <MainLogoIcon />
              </Link>
            </div>
          </div>
          <div className={b('content-inner')}>
            {renderItems(navMenu)}
            {renderItems(listMenu)}

            <div className={b('content-bottom')}>
              <div className={b('lang')}>
                <SetLanguage />
              </div>
            </div>
          </div>
        </div>
        <div className={b('overlay-close', { isOpen })} onClick={onClose} />
      </aside>
    </AnimatePresence>
  )
}
