import QueryTypes from 'services/api/QueryTypes'

import { BaseHttpService } from '../BaseHttpService'
import { DigitainConverter } from './DigitainConverter'

class DigitainApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/integrations/f5d4766a`
    this.converter = new DigitainConverter()
  }

  getToken = () =>
    this.sendQuery({
      type: QueryTypes.POST,
      url: `${this.baseUrl}/login`,
      converterSuccess: this.converter.convertToken,
    })
}

export default DigitainApi
