export const projectName = 'hoyganas'
export const projectStyledName = 'Hoyganas'
export const projectDomainName = 'hoyganas.com'
export const projectKey = 'hoyganascom'
export const logoSvgPath = `/copiedImages/logo.svg`

export const newBCraftServicesUrl = 'https://new.bcraftservices.com'

export const betbyScriptId = 'betby-script'

export const defaultCurrency = 'ARS'

export const sentryDSN = 'https://59338ffab9ed8ea0066577e28ed52660@o1135411.ingest.us.sentry.io/4507936235782145'

// Pragmatic DGA
export const PRAGMATIC_DGA_WS_URL = 'dga.pragmaticplaylive.net'

export const PRAGMATIC_DGA_CASINO_ID = 'ppcdh00000006107'

export const sliderUrl = 'https://gcatg.com/services/content'
export const sliderImageBaseUrl = 'https://images.gcatg.com/media'
