import { tabs } from 'modules/SlotsRoot/data/tabs'
import { tabsLive } from 'modules/SlotsRoot/data/tabsLive'

function getSections(gameList) {
  const sections = gameList.reduce(
    (types, item) => (types.indexOf(item.sectionId) !== -1 ? types : [...types, item.sectionId]),
    [],
  )
  sections.unshift('all')
  return sections
}

function getTab(slotId) {
  const trueTab: string[] = Object.keys(tabs).reduce(
    (acc, tab) => (tabs[tab].findIndex(item => item === slotId) === -1 ? acc : [...acc, tab]),
    [],
  )
  return trueTab
}

function getTabLive(slotId) {
  const trueTab: string[] = Object.keys(tabsLive).reduce(
    (acc, tab) => (tabsLive[tab].findIndex(item => item === slotId) === -1 ? acc : [...acc, tab]),
    [],
  )
  return trueTab
}

export { getSections, getTab, getTabLive }
