import React, { useCallback, useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import block from 'bem-cn'

import './Notify.scss'

const notifyDeletingTime = 4000

const Notify = () => ReactDOM.createPortal(<ToastContainer />, document.getElementById('notifications-dom-root'))

export default Notify
