import { IServerUser, UserTypes } from 'shared/models/User'

export const getUserType = (user: IServerUser): UserTypes[] => {
  let userTypes: UserTypes[] = []
  if (user.is_self_registered) {
    userTypes.push(UserTypes.isSelfRegistered)
  } else {
    userTypes.push(UserTypes.isWithCashier)
  }
  return userTypes
}
