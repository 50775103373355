import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { Store } from 'redux'

import { IApplicationState } from 'setup/store'

import { IListOfErrors } from 'shared/models/AppError'

import { logout, refreshSession } from 'features/auth/actions'

import { getIsRefreshTokenExpired } from 'components/SessionPolling/utils'

import { LOGOUT_RESPONSE_CODE } from './constants'
import { authExcluding } from './helpers/authExcluding'
import { checkIsMultiError } from './helpers/checkIsMultiError'
import { convertInvalidField } from './helpers/convertInvalidField'

export const makeUnauthorizedInterceptor = (store: Store<IApplicationState, any>) => (error: AxiosError) => {
  const state = store.getState()
  const localeErrors = state.locale.data.errorStatusMessage

  const { isAuth } = state.auth.data

  // sessions
  if (isAuth) {
    if (error.response?.data?.code === LOGOUT_RESPONSE_CODE) {
      store.dispatch(logout.success())

      toast.error(localeErrors.access_and_refresh_token_invalid)
    } else if (error.response?.status === 401) {
      const { session } = store.getState().auth.data

      if (session) {
        const isRefreshTokenExpired = getIsRefreshTokenExpired(session.refresh)

        if (isRefreshTokenExpired) {
          store.dispatch(logout.success())
        } else {
          setTimeout(() => store.dispatch(refreshSession()), 0)
        }
      } else {
        store.dispatch(logout.success())
      }
    }
  }

  if (error.request?.responseURL?.includes?.('accounts/login')) {
    const isAuthError = authExcluding(error, store)

    if (isAuthError) {
      throw new Error(JSON.stringify(error.response?.data || ''))
    }
  }

  if (checkIsMultiError(error)) {
    const convertedField: IListOfErrors = convertInvalidField(error)

    if (error?.response?.status === 401) {
      throw new Error(JSON.stringify(convertedField) || '')
    }

    convertedField.listOfErrors.forEach(err => {
      const errorText = localeErrors?.[err.code] || err.message || localeErrors.defaultMessage
      toast.error(errorText, { toastId: errorText })
    })

    throw new Error(JSON.stringify(convertedField) || '')
  }

  if (error.response) {
    if (error.response.status === 401) {
      throw new Error(error.message)
    }

    const errorText =
      localeErrors?.[error.response?.data?.code] ||
      error.response?.data?.message ||
      error.response?.data?.detail ||
      localeErrors.defaultMessage

    toast.error(errorText, { toastId: errorText })
  }

  throw new Error(JSON.stringify(error.response?.data || ''))
}
