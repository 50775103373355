/* eslint-disable camelcase */

export enum parameterNameEnum {
  URL = 'url',
  CODE = 'code',
}
export enum eventTypeEnum {
  REF_LINK = 'referral_link',
}

interface IEventParameter {
  parameterName: parameterNameEnum
  parameterValue: string
}

export interface IEvents {
  eventType: eventTypeEnum
  projectKey: string
  parameters: IEventParameter[]
  userId?: string
  sessionId?: string
  eventId?: string
  partnerId?: string
  timestamp?: string
}

interface IServerEventParameter {
  parameter_name: parameterNameEnum
  parameter_value: string
}

export interface IServerEvents {
  event_type: eventTypeEnum
  project_key: string
  parameters: IServerEventParameter[]
  user_id?: string
  session_id?: string
  event_id?: string
  partner_id?: string
  timestamp?: string
}
