import * as Yup from 'yup'

export const signInFields = {
  username: '',
  password: '',
  isAgeConfirm: true,
}

export const SignInSchema = Yup.object().shape({
  username: Yup.string().required('required'),
  password: Yup.string().required('required'),
  isAgeConfirm: Yup.boolean().required('required').isTrue('ageConfirmErrorText'),
})
