import { IEmailPayload, ISession, ISignInPayload, ISignInResponse, IVerifyEmailPayload } from 'shared/models/Auth'
import { IServerUser, IUser } from 'shared/models/User'
import { ISignIn, IUserSignUp } from 'shared/utils/types/Auth.type'

import { IUpdateUserPayload } from 'features/profile/types'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { AuthConverter } from './AuthConverter'
import { IResetPasswordConfirm } from './types/types'

class AuthApi extends BaseHttpService {
  private converter

  private referral

  private usersUrl

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/accounts`
    this.referral = `${baseUrl}/api/referral`
    this.usersUrl = `${baseUrl}/api/users`
    this.converter = new AuthConverter()
  }

  async logout(data: Pick<ISession, 'refresh'>) {
    return this.sendQuery<never, never, Pick<ISession, 'refresh'>>({
      url: `${this.baseUrl}/logout/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async signIn(data: ISignInPayload) {
    return this.sendQuery<ISignInResponse, ISignIn, ISignInPayload>({
      url: `${this.baseUrl}/login/`,
      type: QueryTypes.POST,
      data,
      converterSuccess: this.converter.convertSignInData,
    })
  }

  async refreshSession(data: Pick<ISession, 'refresh'>) {
    return this.sendQuery<Pick<ISession, 'access'>, Pick<ISession, 'access'>, Pick<ISession, 'refresh'>>({
      url: `${this.baseUrl}/refresh/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async passwordReset({ email }: IEmailPayload) {
    return this.sendQuery<string, string>({
      url: `${this.baseUrl}/password/reset/`,
      type: QueryTypes.POST,
      data: { email },
    })
  }

  async signUp(data: IUserSignUp) {
    return this.sendQuery({
      url: `${this.baseUrl}/registration/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async passwordResetConfirm({ token, ...data }: IResetPasswordConfirm) {
    return this.sendQuery<IResetPasswordConfirm, IResetPasswordConfirm>({
      url: `${this.baseUrl}/password/reset/confirm/${token}`,
      type: QueryTypes.POST,
      data,
    })
  }

  async getUserData() {
    return this.sendQuery<IServerUser, IUser>({
      url: `${this.baseUrl}/user`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertUserData,
    })
  }

  async updateUserData(data: IUpdateUserPayload) {
    return this.sendQuery<IServerUser, IUser>({
      url: `${this.baseUrl}/user`,
      type: QueryTypes.PATCH,
      data,
      converterSuccess: this.converter.convertUserData,
    })
  }

  async verifyEmail({ token }: IVerifyEmailPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/email-verify/${token}`,
      type: QueryTypes.POST,
    })
  }

  async resendEmail({ email }: IEmailPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/registration/resend-email/`,
      type: QueryTypes.POST,
      data: { email },
    })
  }

  async changeEmail({ email }: IEmailPayload) {
    return this.sendQuery({
      url: `${this.usersUrl}/change/user/email/`,
      type: QueryTypes.POST,
      data: { email },
    })
  }
}

export default AuthApi
