import { IAuthStatePersisted } from '../../types'

type ReturnMigrationType = (state: IAuthStatePersisted) => IAuthStatePersisted

export const authMigrations: Record<string, ReturnMigrationType> = {
  1: state => ({
    ...state,
    data: {
      ...state.data,
      user: null,
      isAuth: false,
      session: null,
    },
  }),
}
