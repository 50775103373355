/* eslint-disable camelcase */
import { IJackpotItem, IJackpotItemDTO } from 'shared/models/Jackpots'

const jackpotSortArray = ['Silver', 'Gold', 'Bronze']
export class JackpotsConverter {
  convertJackpotsList(data: IJackpotItemDTO[]): IJackpotItem[] {
    return data
      .map(t => ({
        id: t.id,
        amount: +(+t.value).toFixed(2),
        winner: t.winner,
        name: t.name,
      }))
      .sort((a, b) => jackpotSortArray.indexOf(a.name) - jackpotSortArray.indexOf(b.name))
  }
}
