import { AxiosError } from 'axios'

export const checkIsMultiError = (error: AxiosError) => {
  let isMulti = false
  if (error.request && error.request.response) {
    const parsedError = JSON.parse(error.request.response)
    Object.values(parsedError).forEach(field => {
      if (Array.isArray(field)) {
        isMulti = true
      }
    })
  }
  return isMulti
}
