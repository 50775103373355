export const fullEvent: { [key: string]: string } = {
  expand: 'Expand all',
  collapse: 'Collapse all',
  main: 'Main',
  mainGame: 'Main Game',
  cScore: 'C.Score',
  combo: 'Combo',
  toWinOu: 'To Win O/U',
  notLoseOu: 'Not Lose O/U',
  handicap: 'Handicap',
  halftime: 'Halftime',
  ggSpecial: 'GG special',
  secondHalf: '2nd Half',
  score2Halves: 'Score2Halves',
  oddEven: 'Odd/Even',
  totalGoalsHt2H: 'Total Goals HT/2H',
  winEitherHalf: 'Win Either Half',
  winToNill: 'Win to Nill',
  scoreWin: 'Score&Win',
  scoreDraw: 'Score&Draw',
  win2Halves: 'Win2Halves',
  secondHalvesOu: '2HalvesOu',
  firstGoal: 'First Goal',
  lastGoal: 'Last Goal',
  moreGoal: 'More Goal',
  ou12H: 'O/U 1/2H',
  totalGoals: 'Total Goals',
  goalsOu: 'Goals O/U',
  leadNotLose: 'Lead Not Lose',
  multiGoal: 'Multi Goal',
  timeMatch: 'Time Match',
  sTime: 'S.Time',
  winFromBehind: 'Win From Behind',
  '2ndHalf': '2nd Half',
  '2HalvesOu': '2 halves O/U',
  other: 'Other',
  matchOver: 'Match over',
  matchStarted: 'The match has started',
}
