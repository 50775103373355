import React, { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import block from 'bem-cn'

import { ReactComponent as LogoutSVG } from 'shared/img/profile/logout-svg.svg'

import { profilePageItems } from 'shared/utils/profile'

import { selectLocaleDict } from 'features/locale/selectors'

import SetLanguage from 'components/SetLanguage/SetLanguage'
import UserInfo from 'components/UserInfo/UserInfo'
import WheelButton from 'components/WheelButton/WheelButton'

import './ProfileMobile.scss'

interface IProfileMobileProps {
  onCloseProfileMenu: () => void
  logOut: () => void
}

const b = block('profile-mobile')

const ProfileMobile: React.FC<IProfileMobileProps> = ({ onCloseProfileMenu, logOut }) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).profile

  const onLogOutClick = useCallback(() => {
    logOut()
    onCloseProfileMenu()
  }, [logOut, onCloseProfileMenu])

  const { playerActivities, accountSettings } = profilePageItems

  return (
    <div className={b()}>
      <div className={b('header')}>
        <UserInfo />
        <div className={b('header-sub')}>
          <div className={b('wheel')}>
            <WheelButton onCloseProfileMenu={onCloseProfileMenu} />
          </div>
        </div>
      </div>
      <div className={b('content')}>
        <div className={b('category')}>
          <div className={b('category-header')}>
            {locale[playerActivities.label]}
            <playerActivities.icon className={b('category-icon')} />
          </div>
          <div className={b('category-items')}>
            {playerActivities.items.map(item => (
              <Link
                key={`plAc${item.route}`}
                to={item.route}
                className={b('category-item')}
                onClick={onCloseProfileMenu}>
                {locale[item.label]}
              </Link>
            ))}
          </div>
        </div>
        <div className={b('category')}>
          <div className={b('category-header')}>
            {locale[accountSettings.label]}
            <accountSettings.icon className={b('category-icon')} />
          </div>
          <div className={b('category-items')}>
            {accountSettings.items.map(item => (
              <Link
                key={`accSe${item.route}`}
                to={item.route}
                className={b('category-item')}
                onClick={onCloseProfileMenu}>
                {locale[item.label]}
              </Link>
            ))}
          </div>
        </div>
        <div className={b('category-header-logout')} onClick={onLogOutClick}>
          {locale.logout}
          <LogoutSVG className={b('category-logout-icon')} />
        </div>
      </div>
    </div>
  )
}

export default ProfileMobile
