import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IJackpotsState } from '../types'

export default combineReducers<IJackpotsState['communications']>({
  getJackpotList: makeCommunicationReducer(actions.getJackpotList),
})
