import { AvailableCryptoCurrenciesList } from 'shared/models/Crypto'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { IDefaultPageFilters } from '../ProfileApi/types/types'
import { PaymentConverter } from './PaymentConverter'
import {
  IActionStatusPayload,
  ICallbackPayload,
  ICreateDepositPayload,
  ICreatePayoutPayload,
  IDefaultFilters,
} from './types/types'

class ProfileApi extends BaseHttpService {
  private converter

  private promocodeUrl

  private cryptoServiceUrl

  private facadeServiceUrl

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/backoffice/payments`
    this.promocodeUrl = `${baseUrl}/api/referral`
    this.converter = new PaymentConverter()
    this.cryptoServiceUrl = `${baseUrl}/services/crypto-pay`
    this.facadeServiceUrl = `${baseUrl}/services/facade`
  }

  async activatePromocode(promocode: string) {
    return this.sendQuery({
      url: `${this.promocodeUrl}/activate/`,
      type: QueryTypes.POST,
      data: { code: promocode },
    })
  }

  async getInvoiceManualList(filters: IDefaultFilters) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/system_list/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertManualList,
    })
  }

  async getInvoiceList(filters: IDefaultFilters) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertPaymentList,
    })
  }

  async setInvoice(data: ICreateDepositPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/`,
      type: QueryTypes.POST,
      data,
      converterSuccess: this.converter.convertPaymentItem,
    })
  }

  async getInvoiceById(id: number) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/${id}`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertPaymentItem,
    })
  }

  async getPayoutList(filters: IDefaultFilters) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertPaymentList,
    })
  }

  async setPayout(data: ICreatePayoutPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/`,
      type: QueryTypes.POST,
      data,
      converterSuccess: this.converter.convertPaymentItem,
    })
  }

  async getPayoutById(id: number) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/${id}`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertPaymentItem,
    })
  }

  async getInvoiceApproveList(filters: IDefaultFilters) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/approve_list/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertApproveList,
    })
  }

  async getInvoiceCallback(data: ICallbackPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/callback/${data.slug}${data.var}/`,
      type: QueryTypes.GET,
    })
  }

  async setInvoiceCallback(data: ICallbackPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/callback/${data.slug}${data.var}/`,
      type: QueryTypes.POST,
    })
  }

  async getInvoiceSystemList(filters: IDefaultFilters) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/system_list/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertSystemList,
    })
  }

  async setInvoiceApproveFail({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/${id}/approve_fail/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async setInvoiceApproveSuccess({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/${id}/approve_success/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async setInvoiceManualFail({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/${id}/manual_fail/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async setInvoiceManualSuccess({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/${id}/manual_success/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async setInvoiceProccessAfterApprove({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/deposit/${id}/process_after_approve/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async getPayoutApproveList(filters: IDefaultFilters) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/approve_list/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertApproveList,
    })
  }

  async getPayoutCallback(data: ICallbackPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/callback/${data.slug}${data.var}/`,
      type: QueryTypes.GET,
    })
  }

  async setPayoutCallback(data: ICallbackPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/callback/${data.slug}${data.var}/`,
      type: QueryTypes.POST,
    })
  }

  async getPayoutManualList(filters: IDefaultFilters) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/manual_list/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertManualList,
    })
  }

  async getPayoutSystemList(filters: IDefaultFilters) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/system_list/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertSystemList,
    })
  }

  async setPayoutApproveFail({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/${id}/approve_fail/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async setPayoutApproveSuccess({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/${id}/approve_success/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async setPayoutManualFail({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/${id}/manual_fail/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async setPayoutManualSuccess({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/${id}/manual_success/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async setPayoutProccessAfterApprove({ data, id }: IActionStatusPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/${id}/process_after_approve/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async getOnlinePaymentHistory(data: IDefaultPageFilters) {
    return this.sendQuery({
      url: `${this.facadeServiceUrl}/history/payment/`,
      type: QueryTypes.GET,
      data,
      converterSuccess: this.converter.convertHistoryPayment,
    })
  }

  async cancelOnlinePayout(id: string) {
    return this.sendQuery({
      url: `${this.baseUrl}/payout/${id}/cancel_payment/`,
      type: QueryTypes.POST,
      data: { id },
    })
  }

  async getCryptoCurrencies() {
    return this.sendQuery({
      url: `${this.cryptoServiceUrl}/available-currencies`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertCryptoCurrencies,
    })
  }

  async createCryptoPayment({ id }: { id: AvailableCryptoCurrenciesList }) {
    return this.sendQuery({
      url: `${this.cryptoServiceUrl}/create-payment`,
      type: QueryTypes.POST,
      data: { pay_currency: id },
      converterSuccess: this.converter.convertCreateCryptoPayment,
    })
  }

  async getCryptoMinAmount({ id }: { id: AvailableCryptoCurrenciesList }) {
    return this.sendQuery({
      url: `${this.cryptoServiceUrl}/min-amount?currency=${id}`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertCryptoMinAmount,
    })
  }
}

export default ProfileApi
