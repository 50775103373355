import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTE_PATHS } from 'router/routes'

import { WheelGame } from 'pages/WheelGame/WheelGame'

export class WheelGamePageModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.WHEEL_GAME} path={ROUTE_PATHS.WHEEL_GAME}>
        <Switch>
          <Route key={ROUTE_PATHS.WHEEL_GAME} path={ROUTE_PATHS.WHEEL_GAME} component={WheelGame} />
        </Switch>
      </Route>
    )
  }
}
