import React, { useCallback, useContext } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'
import { Formik } from 'formik'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { localeAuthKeysType } from 'shared/locale/en-US'

import { actions as authActions } from 'features/auth'
import { selectAuthCommunication } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Button from 'components/UIKit/Button/Button'
import { Checkbox } from 'components/UIKit/Checkbox/Checkbox'
import { Input } from 'components/UIKit/Input/Input'

import { signInFields, SignInSchema } from './data/signIn'
import {
  StyledAuthForm,
  StyledAuthHeader,
  StyledAuthHeaderCloseIcon,
  StyledAuthHeaderLocale,
  StyledAuthHr,
  StyledAuthInputLabel,
  StyledAuthInputWrapper,
  StyledAuthModal,
  StyledButtonContainer,
} from './styles/AuthStyles'

import './SignIn.scss'

const b = block('sign-in')

const SignIn: React.FC = () => {
  const dispatch = useDispatch()

  const { closeModal } = useContext(ModalContext)

  const { isLoading } = useSelector(selectAuthCommunication, shallowEqual).login

  const onCloseModal = useCallback(() => {
    closeModal(MODAL_ROUTE_URLS.SIGN_IN())()
  }, [closeModal])

  const onSubmit = useCallback(
    (values: typeof signInFields) => {
      dispatch(authActions.signIn(values, { callback: onCloseModal }))
    },
    [onCloseModal, dispatch],
  )

  const locale = useSelector(selectLocaleDict, shallowEqual).auth
  return (
    <StyledAuthModal className={b()}>
      <Formik initialValues={signInFields} validationSchema={SignInSchema} onSubmit={onSubmit}>
        {({ values, isValid, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <StyledAuthForm onSubmit={handleSubmit}>
            <StyledAuthHeader className={b('header')}>
              <StyledAuthHeaderLocale className={b('title')}>{locale.login}</StyledAuthHeaderLocale>
              <StyledAuthHeaderCloseIcon onClick={onCloseModal}>×</StyledAuthHeaderCloseIcon>
            </StyledAuthHeader>
            <StyledAuthHr />
            <StyledAuthInputWrapper className={b('input-wrapper')}>
              <StyledAuthInputLabel>
                {locale.username}
                <Input
                  type="text"
                  name="username"
                  label={locale.username}
                  placeholder={`${locale.username}`}
                  value={values.username}
                  onChange={handleChange}
                  isRequired
                  onBlur={handleBlur}
                  height="100%"
                />
              </StyledAuthInputLabel>
            </StyledAuthInputWrapper>
            <StyledAuthInputWrapper className={b('input-wrapper')}>
              <StyledAuthInputLabel>
                {locale.password}
                <Input
                  name="password"
                  type="password"
                  placeholder={`${locale.password}`}
                  value={values.password}
                  isRequired
                  onChange={handleChange}
                  onBlur={handleBlur}
                  height="100%"
                />
              </StyledAuthInputLabel>
            </StyledAuthInputWrapper>
            <StyledAuthInputWrapper className={b('input-wrapper', { checkbox: true })}>
              <Checkbox
                type="checkbox"
                name="isAgeConfirm"
                overrideCheckboxStyles={b('checkbox')}
                value={locale.ageConfirmText}
                checkedValue={values.isAgeConfirm}
                onChange={() => setFieldValue('isAgeConfirm', !values.isAgeConfirm)}
              />
              {errors.isAgeConfirm && (
                <div className={b('error')}>
                  {locale[errors.isAgeConfirm as localeAuthKeysType] ?? errors.isAgeConfirm}
                </div>
              )}
            </StyledAuthInputWrapper>
            <StyledAuthHr />
            <StyledButtonContainer className={b('button-wrapper')}>
              <Button
                type="submit"
                variant="main"
                text={locale.login}
                disabled={isLoading || !isValid}
                loading={isLoading}
              />
            </StyledButtonContainer>
          </StyledAuthForm>
        )}
      </Formik>
    </StyledAuthModal>
  )
}

export default SignIn
