import uniq from 'lodash.uniq'

import { tabs } from 'modules/SlotsRoot/data/tabs'
import { ISlotDTO } from 'modules/SlotsRoot/types/interfaces/Slots/GameCatalog'

import { uniqGameId } from './uniqGameId'

export const providersWhiteList = [
  'amatic',
  'evolution',
  'pgsoft',
  'spribe',
  'pragmaticplay',
  'pragmaticplaylive',
]

export const providerReplacingNames: { [key: string]: string } = {
  evosw: 'evolution',
  pragmatic: 'pragmaticplay',
}

export const providerLiveReplacingNames: { [key: string]: string } = {
  pragmatic: 'pragmaticplaylive',
}

export const getSlotProviderName = (slot: ISlotDTO): string => {
  if (slot.integration === '08f21347' && slot.producer === 'pragmatic') {
    return slot.game_type && slot.game_type.includes('live-casino')
      ? providerLiveReplacingNames[slot.producer]
      : providerReplacingNames[slot.producer]
  }

  return providerReplacingNames[slot.producer] || slot.producer
}

export function getGameCatalogTab(slotId: string, dropAndWins?: boolean): string[] {
  const additionalTabs: string[] = dropAndWins ? ['Drops & Wins'] : []

  const trueTab = Object.keys(tabs).reduce(
    (acc: string[], tab: string) =>
      tabs[tab].findIndex((item: string) => item === slotId) === -1 ? acc : [...acc, tab],
    [],
  )
  return uniq([...additionalTabs, ...trueTab])
}

export const blackListGames = [
  uniqGameId('028142ca', 'spribe', 'aviator'),
]
