import React, { useCallback } from 'react'
import { ArrayParam,useQueryParam, withDefault } from 'use-query-params'

import { ModalQueryContext } from './ModalContext'

const withModalQuery = (WrappedComponent: React.FC<any>) => (props: any) => {
  const [openedModals, setOpenedModals] = useQueryParam('openedModals', withDefault(ArrayParam, []))

  const pushNewModal = useCallback(
    (newModal: string) => () => {
      setOpenedModals([...openedModals.filter(t => t !== newModal), newModal])
    },
    [openedModals, setOpenedModals],
  )

  const closeModal = useCallback(
    (newModal: string) => () => {
      setOpenedModals(openedModals.filter(t => t !== newModal))
    },
    [openedModals, setOpenedModals],
  )

  const replaceLastModal = useCallback(
    (newModal: string) => () => {
      setOpenedModals([...openedModals.splice(0, openedModals.length - 1), newModal])
    },
    [openedModals, setOpenedModals],
  )

  return (
    <WrappedComponent
      {...props}
      modalFilters={
        {
          openedModals,
          setOpenedModals,
          pushNewModal,
          closeModal,
          replaceLastModal,
        } as ModalQueryContext
      }
    />
  )
}

export default withModalQuery
