/* eslint-disable max-len */
import allWaysFruits from './imagesForReplace/allWaysFruits.svg'
import biggerBassBonanze from './imagesForReplace/biggerBassBonanze.svg'
import bookOfDead from './imagesForReplace/bookOfDead.svg'
import chillliHeat from './imagesForReplace/chillliHeat.svg'
import crazyTime from './imagesForReplace/crazyTime.svg'
import gatesOfOlympus from './imagesForReplace/gatesOfOlympus.svg'
import hotHotFruit from './imagesForReplace/hotHotFruit.svg'
import sweetBonanzaCandyland from './imagesForReplace/sweetBonanzaCandyland.svg'
import theDogHouseMegaways from './imagesForReplace/theDogHouseMegaways.svg'
// import wildWestGold from './imagesForReplace/wildWestGold.svg'
import wildWildRiches from './imagesForReplace/wildWildRiches.svg'

export const imageReplacementList: Record<string, string> = {
  vs20olympgate_prg: gatesOfOlympus,
  'pragmaticexternal:GatesOfOlympus1': gatesOfOlympus,
  vs20olympgate: gatesOfOlympus,

  'habanero:SGHotHotFruit': hotHotFruit,
  allways_fruits_original: allWaysFruits,
  bookofdead_png: bookOfDead,

  vs576treasures_prg: wildWildRiches,
  'pragmaticexternal:WildWildRiches': wildWildRiches,
  vs576treasures: wildWildRiches,

  vs12bbb_prg: biggerBassBonanze,
  'pragmaticexternal:BiggerBassBonanza': biggerBassBonanze,
  vs12bbb: biggerBassBonanze,

  vs25chilli_prg: chillliHeat,
  'pragmaticexternal:ChilliHeat': chillliHeat,
  vs25chilli: chillliHeat,

  'evolution:crazytime': crazyTime,

  'pragmaticexternal:SweetBonanzaCandyland': sweetBonanzaCandyland,
  '1101': sweetBonanzaCandyland,
}

export const getOtcSlotImage = (slotId: string, sectionId: string) => {
  const slotIdStr = slotId.toLowerCase()
  const sectionIdLower = sectionId.toLowerCase()

  if (imageReplacementList[slotId]) {
    return imageReplacementList[slotId]
  }

  return `https://static.gcatg.com/outcome/235x235/${sectionIdLower}/${slotIdStr}.jpg`
}

export const getSoftswissSlotImage = (slotProvider: string, slotId: string, platform?: string) => {
  if (imageReplacementList[slotId]) {
    return imageReplacementList[slotId]
  }
  return `https://cdn.softswiss.net/i/${platform === 'desktop' ? 's3' : 's4'}/${slotProvider}/${slotId.substring(
    slotId.lastIndexOf(':') + 1,
  )}.png`
}

export const getGameCatalogImage = (slotId: string, imageUrl: string, imageDesktop?: string, platform?: string) => {
  if (imageReplacementList[slotId]) {
    return imageReplacementList[slotId]
  }

  // if (platform === 'desktop' && imageDesktop) return imageDesktop

  return imageUrl
}
