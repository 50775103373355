export const bonuses = {
  bonuses: 'Bonuses',
  moreAboutStock: 'More about the stock',

  ALL: 'All',
  Cashback: 'Cashback',
  SportDiscount: 'Sport discount',
  FirstDeposit: 'First deposit',
  DetractedFirstDepositBonusByWithdrawal: 'Detracted first deposit bonus by withdrawal',
  IncreasedBySO: 'Increased by SO',
  TransferBonusesToBalance: 'Transfer bonuses to balance',
  LuckySpin: 'Lucky spin',
  balanceConvertedSuccess: 'Balance added successfully',
  exchange: 'Exchange',
  bonusBalanceOnYourAccount: 'Your current bonus balance',
  exchangeRate: 'Exchange rate',
  balanceMustBeLess: 'Balance must be less than',
  toCompleteExchange: 'to complete the exchange',

  yourJackpot: 'Your jackpot',
  yourFirstDeposit: 'Your first deposit',
  yourCashback: 'Your cashback',
  youGot: 'You got',
  thankYou: 'Thank you',
}
