/* eslint-disable camelcase */

export type SportTypes =
  | 'football'
  | 'basketball'
  | 'baseball'
  | 'iceHockey'
  | 'tennis'
  | 'handball'
  | 'floorball'
  | 'golf'
  | 'boxing'
  | 'rugby'
  | 'aussieRules'
  | 'bandy'
  | 'americanFootball'
  | 'cycling'
  | 'specials'
  | 'snooker'
  | 'tableTennis'
  | 'cricket'
  | 'volleyball'
  | 'eSport'

export const SportIndexes: Record<SportTypes, string> = {
  football: '1',
  basketball: '2',
  baseball: '3',
  iceHockey: '4',
  tennis: '5',
  handball: '6',
  floorball: '7',
  golf: '9',
  boxing: '10',
  rugby: '12',
  aussieRules: '13',
  bandy: '15',
  americanFootball: '16',
  cycling: '17',
  specials: '18',
  snooker: '19',
  tableTennis: '20',
  cricket: '21',
  volleyball: '23',
  eSport: 'e_sport',
}

export type PageTypes = 'live' | 'favorites' | 'search' | 'bets' | SportTypes

export interface IToken {
  username_on_service: string
  access_token: string
  brand_id: string
}

export interface IParameters {
  brand_id: string
  target: HTMLElement
  token: string | null
  onTokenExpired?: { (): Promise<string> }
  themeName?: string
  lang?: string
  betSlipOffsetTop?: number
  betSlipOffsetBottom?: number
  betSlipOffsetRight?: number
  betslipZIndex?: number
  url?: string
  stickyTop?: number
  onRouteChange?: { (route: string): void }
  onLogin?: { (): void }
  onRegister?: { (): void }
  onSessionRefresh?: { (): void }
  onAppInitialized?: { (): void }
  onRecharge?: { (): void }
  onBannerClick?: { (): void }
  onOutcomeClick?: { (): void }
  onBetSlipStateChange?: { (): void }
  betSlipButton?: HTMLElement
  handlePageChange?: { (): void }
}

export interface IChangeableParameters {
  betSlipOffsetTop?: number
  betSlipOffsetBottom?: number
  betSlipOffsetRight?: number
  betSlipButton?: HTMLElement
  url?: string
  stickyTop?: number
}

export interface IApplicationObject {
  initialize: { (params: IParameters): IApplicationObject }
  updateOptions: { (params: IChangeableParameters): void }
  kill: { (): void }
}

export interface IBetbySportsbook {
  new (): IApplicationObject
}
