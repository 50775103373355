import { sliderImageBaseUrl } from 'shared/constants'
import { Carousel, ICarouselDTO, ISlide, ISlideDTO, SliderPlatforms } from 'shared/models/Slider'
import { getCarouselParameters } from 'shared/utils/slider'

export class SliderConverter {
  convertSlides(data: ISlideDTO[]): ISlide[] {
    return (
      data.map(slide => ({
        id: slide.id,
        type: slide.type,
        image: `${sliderImageBaseUrl}/${slide.image}`,
        link: slide.link,
        language: slide.language,
        isActive: slide.is_active,
        carouselId: slide.carousel_id,
        authRequired: slide.auth_required,
        order: slide.order,
      })) || []
    )
  }

  convertCarousels(data: ICarouselDTO[], slides: ISlide[]): Carousel {
    const getSlides = (carousel: ICarouselDTO) => slides.filter(slide => slide.carouselId === carousel.id)

    const sortedCarousels = data.reduce((acc: Carousel, carousel) => {
      const carouselParams = getCarouselParameters(carousel.slug)

      const routes = carousel.route.split(',').map(route => route.replaceAll(' ', ''))

      const sortedCarousel = routes.reduce(
        (carouselsWithRoute, route) => ({
          ...carouselsWithRoute,
          [route]: {
            ...(acc?.[route] ?? {}),
            [carouselParams.platform || SliderPlatforms.NOT_FOUND]: {
              ...(acc?.[route]?.[carouselParams.platform || SliderPlatforms.NOT_FOUND] ?? {}),
              [carouselParams.language || 'missedLanguage']: getSlides(carousel).sort((a, b) =>
                a.order - b.order > 0 ? 1 : -1,
              ),
            },
          },
        }),
        acc,
      )

      return sortedCarousel
    }, {})

    return sortedCarousels
  }
}
