import { createAction } from '@reduxjs/toolkit'

import { loadSliderPeriodMiliseconds } from 'shared/constants/slider'
import { Carousel } from 'shared/models/Slider'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'
import { getIsNeedUpdateStoreState } from 'shared/utils/getIsNeedUpdateStoreState'

export const checkSlider = createAction<Date>('@slider/CHECK_SLIDER')

// export const loadSlides = makeCommunicationActionCreator({

export const loadCarousels = makeCommunicationActionCreator({
  loading: '@Slider/LOAD_CAROUSELS_LOADING',
  success: '@Slider/LOAD_CAROUSELS_SUCCESS',
  error: '@Slider/LOAD_CAROUSELS_ERROR',
  reset: '@Slider/LOAD_CAROUSELS_RESET',
})<void, Carousel>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
  }) => {
    const { slider } = getState()

    if (getIsNeedUpdateStoreState(slider.data.lastLoadTime, loadSliderPeriodMiliseconds)) {
      const slideResponse = await api.slider.getSlides()

      let carouselResponse

      if (slideResponse.success) {
        carouselResponse = await api.slider.getCarousels(slideResponse.data)

        if (carouselResponse.success) {
          dispatch(checkSlider(new Date()))
        }
      }

      return carouselResponse?.data || slider.data.carousels
    }

    return slider.data.carousels
  },
)
