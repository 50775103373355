import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IBonusSystemState } from '../types'

export default combineReducers<IBonusSystemState['communications']>({
  exchangeBonusBalanceToBalance: makeCommunicationReducer(actions.exchangeBonusBalanceToBalance),
})
