import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { Store } from 'redux'

import { IApplicationState } from 'setup/store'

export const authExcluding = (error: AxiosError, store: Store<IApplicationState, any>) => {
  if (error.response && error.response.data?.code === 'no_active_account') {
    toast.warn(store.getState().locale.data.errorStatusMessage.invalidUserNameOrPassword)
    return true
  }
  return false
}
