import { projectStyledName } from 'shared/constants'

/* eslint-disable max-len */
export const promotionsPage = {
  rules: 'Rules',

  items: {
    firstPromotion: ['NO WAGERING START-UP BONUS', 'The best offer for new players'],
    secondPromotion: ['EVERYDAY CASHBACK', `No hidden rules Only get it every day`],
    thirdPromotion: ['LUCKY SPIN', 'Wheel every day and get real balance bonus or special prize'],
    fourthPromotion: ['Night Bingo Party', 'Big jackpots and themed quizzes with prizes.'],
    fifthPromotion: ['Top game from Lauriz this week', 'Get 20 x 0.20€ WinSpins every day'],
    sixthPromotion: [
      'Catch the bonus in the Lightning Roulette game!',
      'Play the most exciting roulette game from Evolution and get a 5% bonus up to 25€ from losing or winning.',
    ],
    seventhPromotion: [
      'Happy Bingo Bonus',
      'Play the first online bingo in Latvia and get 20 risk-free bingo cards for Bingo games in the 75 ball free space room!',
    ],
    eighthPromotion: [
      'Habanero Jackpot Races',
      'On the start, attention... March! The competition in the new Habanero jackpots has begun!',
    ],
    ninthPromotion: ['Bingo Chat Games', 'Join our only online bingo community in Latvia!'],
  },

  persons: {
    welcomeToGonzos: 'Welcome to the Gonzos',
    green: 'Green',
    blue: 'Blue',
    pink: 'Pink',
    lemon: 'Lemon',
    greenDesc: `This guy is always in the spotlight! New adventures every day. GREEN lives by the all-or-nothing recipe. He loves risk and good champagne. You can't conquer the world alone! And that's why our GREEN does not spare delicious bonuses and secret offers for his friends.`,
    blueDesc: `This guy BLUE is the winner of numerous competitions. He has an IQ - three times higher than average. Knowledge is power, but power also comes with great responsibility. Before taking risks, Max calculates all possible outcomes. Keeping calm, he helps to resolve many difficult situations.`,
    pinkDesc: `Behind the gentle appearance of this beauty hides a truly gambling soul. PINK’S friends know that she loves to play Blackjack, and a good card layout always cheers her up. Anna helps both game professionals and those who are just planning to try their luck.`,
    lemonDesc: `Meet LEMON, our friend who attends all the best parties. He never sleeps in order to have time to do more than the rest. All he needs for good luck is coffee and unlimited internet. LEMON will be the first to talk about the latest games and updates.`,
  },

  pageOne: {
    headerTitle: 'The biggest start-up bonus in Latvia!',
    headerText: `Exactly! This is the best offer for new players! Join ${projectStyledName}'s circle of friends and get 200 Winspins, up to €200 cashback and a special surprise on Thursdays within a month of signing up!`,
    items: {
      heading1: 'Register',
      text1: 'It takes only a few minutes',
      btn1: 'Registration',
      heading2: 'Choose bonus "SLOTS"',
      text2: 'In My Bonus',
      heading3: 'Get 200 WINSPINS',
      text3: 'Cash back up to 200€ and gifts within a month',
    },
    sblock1heading: 'About Winspins',
    sblock1text:
      'WinSpins is a great opportunity to try out the best slot games without risking your money. You pay for a spin, and we immediately return its value to your account, regardless of whether you won or not.',
    sblock2heading: 'About 200€ refund',
    sblock2text:
      'You will receive your cash back 7 days after you made your first deposit. The refund amount is calculated according to the following formula: (bets - winnings) * 50% Do you want to play, not count? No problem! We counted everything for you! Just log into your account, go to this page at any time, and you will see how your cashback grows.',
  },

  pageTwo: {
    headerTitle: 'Rejoice in the coming of spring with colorful prizes',
    headerText: `Enjoy the spring by collecting points and getting bright prizes. Become a winner and dive into water sports with prizes from ${projectStyledName}, or maybe a brand new iPhone is waiting for you, which will help you play ${projectStyledName} casino, even on the go, quickly and easily.`,
    items: {
      heading1: 'Get points',
      text1: 'for every bet placed',
      btn1: 'play',
      heading2: 'Reach bonus levels',
      text2: '',
      heading3: 'Get prizes',
      text3: 'for each level reached',
    },
  },

  pageThree: {
    headerTitle: 'Play fantastic Fantasma Megaways slots!',
    headerText:
      'Spin Fantasma Megaways slots and you will immediately get 20 x 0.20€ Heroes Hunt 2 WinSpins. Keep playing and get cash back up to 200€ daily.',
    items: {
      heading1: 'Make a turn',
      heading1br2: 'at 100€',
      text1: 'in Fantasma Megaways games',
      text1br2: 'Get 20 x 0.20€',
      text1br3: 'Heroes Hunt 2 WinSpins',
      btn1: 'Play',
      heading2: 'keep playing',
      text2: 'Get a refund',
      text2br2: 'in 2% up to 200€ tomorrow',
      heading3: 'Your',
      heading3br2: 'turnover',
      text3: '',
    },
    sblock1heading: '',
    sblock1text: 'The refund will be credited to your account tomorrow by 16:00',
  },

  pageFour: {
    headerTitle: 'Night bingo party',
    headerText: 'Big jackpots and themed quizzes with prizes.',
    items: {
      heading1: 'Join and play',
      text1: '',
      btn1: 'Play',
      heading2: 'buy bingo cards',
      text2: 'before every game',
      heading3: 'Answer the questions',
      text3: 'to get more cards',
    },
    sblock1text:
      'All night long there will be pleasant surprises waiting for you, a quiz that will allow you to get additional risk-free cards and, of course, guaranteed jackpots with a total prize pool of over 1000€!',
  },

  pageFive: {
    headerTitle: 'Top game from Lauriz this week',
    headerText: 'Activate the offer with a €10 deposit just once and get 20 x €0.20 WinSpins every day!',
    items: {
      heading1: 'Make a deposit',
      text1: 'at the rate of',
      text1br2: 'at least 10€ here',
      text1br3: 'Start playing with',
      text1br4: '5 x €0.20 WinSpins',
      btn1: 'Get',
      heading2: 'Place your bets',
      text2: 'for total cost',
      text2br2: 'at least 100€',
      text2br3: 'in game today',
      text2br4: 'Tiger Kingdom Infinity Reels',
      btn2: 'Get',
      heading3: 'Get 20 x 0.20€',
      text3: 'win spins',
      text3br2: 'the next day',
    },
  },

  pageSix: {
    headerTitle: 'Catch the bonus in the game Lightning Roulette!',
    headerText: 'Play the most exciting roulette game from Evolution and get a 5% bonus up to 25€ on your win or loss.',
    items: {
      heading1: 'play',
      text1: 'Lightning Roulette',
      btn1: 'Play',
      heading2: 'Not important',
      text2: 'win or lose',
      heading3: 'You will get',
      text3: '5% cash back',
    },
  },

  pageSeven: {
    headerTitle: 'Lucky Bingo Bonus',
    headerText:
      "Play the first online bingo in Latvia and get 20 risk-free bingo cards! That's right, it's really that easy to try your luck!",
    items: {
      heading1: 'Register',
      heading1br2: `in ${projectStyledName}`,
      text1: 'It takes only a few minutes',
      btn1: 'Registration',
      heading2: 'Choose your BINGO bonus',
      text2: 'In My Bonus',
      heading3: 'Get',
      heading3br2: '20 x 0.50€',
      text3: 'risk-free bingo cards for all games in the room 75 ball free space',
    },
    sblock1heading: 'Risk free bingo cards',
    sblock1text:
      'Try bingo games in the 75 ball free space without risking your money. Simply activate the bonus, and once the risk-free bingo cards are activated, you will automatically receive a cashback for each of the first 20 cards purchased - up to 0.50€ per card.',
  },

  pageEight: {
    headerTitle: 'Race for weekly Habanero jackpots',
    headerText:
      'Reade set Go! The new Habanero jackpot competition has begun! Play slots from Habanero every Friday from 21:00 to 23:00 and compete with other players. The top ten will share the prize money from the weekly jackpots.',
    items: {
      heading1: 'PLAY HABANERO GAMES',
      text1: 'Watch the jackpot grow',
      btn1: 'Play',
      heading2: 'JACKPOT HAPPENS',
      text2: 'From 21:00 to 23:00',
      heading3: 'BE IN THE TOP 10 PLAYERS',
      heading3br2: '20 x 0.50€',
      text3: 'to win part of the jackpot',
    },
  },

  pageNine: {
    headerTitle: 'Bingo Chat Games',
    headerText: 'Join our only online bingo community in Latvia!',
    headerTextbr1: 'Here you can chat with like-minded people, play different variants of bingo and, of course, win!',
    headerTextbr2:
      'In addition, we give 20 risk-free cards to all new players. You can learn more about our new player bonus',
    headerLinkText: 'on this page.',
    blockTitle: 'Briefly about our online bingo',
    items: {
      heading1: 'Open',
      heading1br2: 'around the clock',
      text1: 'Play bingo anytime. Each game lasts about 6 minutes',
      btn1: 'Play',
      heading2: 'Common price',
      heading2br2: 'only 0.50€',
      text2: 'per card',
      heading3: 'Buy from 1 to',
      heading3br2: '30 cards',
      text3: 'for an exciting game',
    },
  },
}
