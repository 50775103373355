export const wheelGame = {
  freeSpin: 'Free spin',
  viewHistory: 'View history',
  date: 'Date',
  type: 'Type',
  amount: 'Amount',
  spinFor: 'Spin for',
  dateFrom: 'Date from',
  dateTo: 'Date to',
  from: 'From',
  to: 'To',
  show: 'Show',
  page: 'Page',
  spin: 'Spin',
  selectPeriod: 'Select period',
  claim: 'Claim',
  spinHistory: 'Spin history',
  nextFreeSpin: 'Next free spin',
  youHaveNotWinYet: "You haven't won anything yet",
  youWon: 'You won',
  luckyNextTime: "You'll be lucky next time",
  tryAgain: 'Try again',
  pleaseAuth: 'To use casino, please log in.',
}
