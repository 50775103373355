import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import block from 'bem-cn'

import { ReactComponent as ArrowSvg } from 'shared/img/arrow.svg'

import { localeHomeKeysType, localeHomeType } from 'shared/locale/en-US'

import { IListMenuItem } from '../data'

import './SideMenuItem.scss'

interface ISideMenuItemProps {
  title: localeHomeKeysType
  subItems?: IListMenuItem[]
  locale: localeHomeType
  initialClosed: boolean
  callback: (e: React.MouseEvent, item: IListMenuItem) => void
}

const b = block('side-menu-item')

export const SideMenuItem: React.FC<ISideMenuItemProps> = ({
  title,
  subItems,
  callback,
  locale,
  initialClosed,
}) => {
  const [isOpen, setIsOpen] = useState(!initialClosed)
  const [fakeIsOpen, setFakeIsOpen] = useState<'' | 'close' | 'open'>(!initialClosed === false ? 'close' : '')

  const toggleSubMenu = useCallback(() => {
    setIsOpen(!isOpen)

    setFakeIsOpen(isOpen ? 'close' : 'open')
  }, [isOpen])

  const items = useMemo(
    () => {
      if (!subItems) return null

      return subItems.map((item, index) => (
        <Link
          key={`${index}_${item.title}_${index}`}
          to={item.route}
          className={b('link')}
          onClick={e => callback(e, item)}
        >
          <item.icon className={b('icon')} />
          <span className={b('link-text')}>
            {locale[item.title] || item.title}
          </span>
        </Link>
      ))
    },
    [callback, locale, subItems],
  )

  return (
    <div className={b()}>
      <div className={b('title-wrapper')}>
        <div onClick={toggleSubMenu} className={b('title')}>
          <span>{locale[title] || title}</span>
          <ArrowSvg className={b('title-svg', { close: !isOpen })} />
        </div>
      </div>
      <div className={b('dropdown-menu', { closed: fakeIsOpen === 'close', open: fakeIsOpen === 'open' })}>
        <div className={b('dropdown-menu-items')}>{items}</div>
      </div>
    </div>
  )
}
