/* eslint-disable camelcase */
import { RolesTypesType } from './Roles'

export enum UserTypes {
  isSelfRegistered = 'self-registered',
  isWithCashier = 'with-cashier',
}

export interface IUser {
  id: number
  login: string
  currency: string
  firstName: string
  lastName: string
  role: RolesTypesType
  username: string
  balance: number
  bonusBalance: number
  parent: number | null
  email: string
  dateJoined: string
  isSelfRegistered: boolean
  userTypes: UserTypes[]
  createdAt: string
  dateOfBirth: string | null
}

export interface IServerUserInfo {
  date_of_birth: string | null
}

export interface IServerUser {
  balance: string
  balance_currency: string
  bonus_balance: string
  created_at: string
  date_joined: string
  email: string
  first_name: string
  groups: string[]
  id: number
  is_active: boolean
  is_banned: boolean
  is_staff: boolean
  is_superuser: boolean
  last_login: string
  last_name: string
  parent: number
  role: RolesTypesType
  user_permissions: string[]
  username: string
  version: number
  is_self_registered: boolean
  info: IServerUserInfo
}
