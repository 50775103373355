import { createAction } from '@reduxjs/toolkit'

import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

export const getSportToken = makeCommunicationActionCreator({
  loading: '@digitain/GET_SPORT_TOKEN_LOADING',
  success: '@digitain/GET_SPORT_TOKEN_SUCCESS',
  error: '@digitain/GET_SPORT_TOKEN_ERROR',
  reset: '@digitain/GET_SPORT_TOKEN_RESET',
})<undefined, string>(
  async ({
    deps: {
      extra: { api },
    },
    payload: callback,
  }) => {
    const response = await api.digitain.getToken()
    return response.data
  },
)

export const removeSportToken = createAction('@digitain/REMOVE_SPORT_TOKEN')
