/* eslint-disable no-debugger */
import { createAction } from '@reduxjs/toolkit'

import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'
// eslint-disable-next-line max-len
import { makeCommunicationByIdActionCreator } from 'shared/utils/communication/actions/makeCommunicationByIdActionCreator'

import { IGetGameList } from 'modules/SlotsRoot/api'
import { slotProvidersConfiguration } from 'modules/SlotsRoot/configuration'
import { getPureGameId } from 'modules/SlotsRoot/providersData/GameCatalog/uniqGameId'
import {
  IFavoriteSlotPayload,
  IGetExtraDataSuccess,
  IGetFreeSpinSessionUrlPayload,
  IGetFreeSpinSessionUrlResponse,
  IGetGameListInField,
  IGetGameListReplaced,
  IGetSessionUrlOutput,
  IGetSessionUrlPayload,
  ISetActionProccessingByProvider,
} from 'modules/SlotsRoot/types/actions'
import { ProvidersType } from 'modules/SlotsRoot/types/configuration'
import { IProducer, IServerResponse } from 'modules/SlotsRoot/types/interfaces/Api'
import { IFreeSpinInfoPayload,IFreeSpinInfoView } from 'modules/SlotsRoot/types/interfaces/Slots/Outcome'
import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { addNotify } from 'components/Notify'

// -----

export const GET_GAME_LIST_REPLACED = '@slots/GET_GAME_LIST_REPLACED'
export const GET_GAME_LIST_SUCCESS_IN_FIELD = '@slots/GET_GAME_LIST_SUCCESS_IN_FIELD'
export const ACTION_PROCESSING_BY_PROVIDER = '@slots/ACTION_PROCESSING_BY_PROVIDER'
export const GET_EXTRA_DATA_SUCCESS = '@slots/GET_EXTRA_DATA_SUCCESS'
export const ACTION_FAILURE = '@slots/ACTION_FAILURE'

export const LOAD_SESSION = '@slots/LOAD_SESSION'
export const LOAD_FREE_SPIN_SESSION = '@slots/LOAD_FREE_SPIN_SESSION'
export const SET_BACK_URL = '@slots/SET_BACK_URL'
export const CLEAR_SESSION = '@slots/CLEAR_SESSION'
export const CLEAR_FREE_SPIN_SESSION = '@slots/CLEAR_FREE_SPIN_SESSION'

export const FAVORITE_ADD_SLOT_SUCCESS = '@slots/FAVORITE_ADD_SLOT_SUCCESS'
export const FAVORITE_REMOVE_SLOT_SUCCESS = '@slots/FAVORITE_REMOVE_SLOT_SUCCESS'

// -----

export const getGameListReplaced = createAction<IGetGameListReplaced>(GET_GAME_LIST_REPLACED)
export const getGameListInField = createAction<IGetGameListInField>(GET_GAME_LIST_SUCCESS_IN_FIELD)
export const getExtraDataSuccess = createAction<IGetExtraDataSuccess>(GET_EXTRA_DATA_SUCCESS)
export const setActionFailure = createAction<string>(ACTION_FAILURE)

export const loadSession = createAction<string>(LOAD_SESSION)
export const loadFreeSpinSession = createAction<string>(LOAD_FREE_SPIN_SESSION)
export const setBackUrl = createAction<string>(SET_BACK_URL)
export const clearSessionUrl = createAction(CLEAR_SESSION)
export const clearFreeSpinSessionUrl = createAction(CLEAR_FREE_SPIN_SESSION)

export const addFavoriteSlotAction = createAction<string>(FAVORITE_ADD_SLOT_SUCCESS)
export const removeFavoriteSlotAction = createAction<string>(FAVORITE_REMOVE_SLOT_SUCCESS)

// -----

export const getGameList = makeCommunicationByIdActionCreator({
  loading: '@slots/GET_GAME_LIST_LOADING',
  success: '@slots/GET_GAME_LIST_SUCCESS',
  error: '@slots/GET_GAME_LIST_ERROR',
  reset: '@slots/GET_GAME_LIST_RESET',
})<IGetGameList, any>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const { id, provider, platform = 'desktop' } = payload

    const data = slotProvidersConfiguration[provider]
    if (Array.isArray(provider)) {
      provider.forEach(t => {
        dispatch(getGameList({ id: t, provider: t }))
      })
      return { id }
    }
    if (!data) {
      throw new SyntaxError(`Provider "${provider}" not found in configuration`)
    }

    const state = getState()
    const { isAuth } = state.auth.data
    const providerList = state.slots.data.gameList.filter((t: ISlotViewItem) => t.routeProvider === provider)
    const favoriteSlots = state.slots.data.gameList.filter((t: ISlotViewItem) => t.tab.indexOf('favorite') !== -1)
    const listHasDefaultList = providerList.some((t: ISlotViewItem) => t.isDefault)

    if (data.defaultList && !isAuth) {
      if (!listHasDefaultList) {
        dispatch(getGameListReplaced({ gameList: data.defaultList, provider }))
      }
      return { id }
    }

    let extraData = state.slots.data.extraData?.[provider] || {}

    const isExtraDataExist = provider in state.slots.data.extraData

    if (!isExtraDataExist) {
      const extraDataResponses = await Promise.all(
        data.extraData?.map(async _extraData => {
          if (_extraData.authRequired && !isAuth) return { data: {} }
          const { url, converter } = _extraData
          const result = await api.slots.getExtraData({ url, converter })
          if (result.success) {
            dispatch(getExtraDataSuccess({ provider, data: result.data }))
          }
          return result
        }) || [],
      )

      extraDataResponses.forEach((extraDataParam: any) => {
        extraData = {
          ...extraData,
          ...extraDataParam.data,
        }
      })
    }

    if (
      providerList.length &&
      data.slotsCount &&
      providerList.length > data.slotsCount - 300 &&
      !listHasDefaultList &&
      (!data.extraData || (data.extraData.length && Object.keys(state.slots.data.extraData[provider] || {}).length))
    ) {
      return { id }
    }

    const response = await api.slots.getGameList({ id, provider, extraData, platform })

    if (response.success) {
      if (!data.uniqueField) {
        dispatch(getGameListReplaced({ gameList: response.data, provider }))
      } else {
        dispatch(getGameListInField({ gameList: response.data, provider }))
      }
      return { id }
    }
    dispatch(setActionFailure(response.errorMessage))

    return { id }
  },
)

//

const attemptsToLoadSessionUrl = 3

export const getSessionUrl = makeCommunicationByIdActionCreator({
  loading: '@slots/GET_SESSION_URL_LOADING',
  success: '@slots/GET_SESSION_URL_SUCCESS',
  error: '@slots/GET_SESSION_URL_ERROR',
  reset: '@slots/GET_SESSION_URL_RESET',
})<IGetSessionUrlPayload, IGetSessionUrlOutput>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const {
      id,
      provider,
      gameId,
      withRedirect = false,
      isDesktop = false,
      attempts = 0,
      callback,
      demoStart,
      hasFreeSpins,
    } = payload

    const pureGameId = getPureGameId(gameId)

    let currentAttempts = attempts + 1
    const state = getState()
    const { lang } = state.userSettings.data
    const extraData = state.slots.data.extraData[provider] || {}
    const { gameList } = state.slots.data
    const providerGameList = gameList.filter((t: ISlotViewItem) => t.routeProvider === provider)

    let response: any

    try {
      response = await api.slots.getSessionUrl({
        provider,
        gameId: pureGameId,
        extraData,
        gameList: providerGameList,
        params: { lang, isDesktop },
        demoStart,
        hasFreeSpins,
      })
      dispatch(loadSession(response.data))
      if (withRedirect) {
        setTimeout(() => {
          location.href = response.data
        }, 0)
      }
      return { id, payload: response.data }
    } catch {
      if (currentAttempts >= attemptsToLoadSessionUrl) {
        callback()
      } else {
        dispatch(
          getSessionUrl({
            id: gameId,
            provider,
            gameId: pureGameId,
            withRedirect,
            isDesktop,
            attempts: currentAttempts,
            callback,
            demoStart,
            hasFreeSpins,
          }),
        )
      }
      return { id } as IGetSessionUrlOutput
    }
  },
)

//

export const getFreeSpinSessionUrl = makeCommunicationByIdActionCreator({
  loading: '@slots/GET_FREE_SPIN_SESSION_URL_LOADING',
  success: '@slots/GET_FREE_SPIN_SESSION_URL_SUCCESS',
  error: '@slots/GET_FREE_SPIN_SESSION_URL_ERROR',
  reset: '@slots/GET_FREE_SPIN_SESSION_URL_RESET',
})<IGetFreeSpinSessionUrlPayload, IGetFreeSpinSessionUrlResponse>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const {
      id,
      provider,
      gameId,
      withRedirect = false,
      isDesktop = false,
      attempts = 0,
      callback,
      hasFreeSpins,
    } = payload
    const pureGameId = getPureGameId(gameId)

    let currentAttempts = attempts + 1
    const state = getState()
    const { lang } = state.userSettings.data
    const extraData = state.slots.data.extraData[provider] || {}
    const { gameList } = state.slots.data
    const providerGameList = gameList.filter((t: ISlotViewItem) => t.routeProvider === provider)

    let response

    try {
      response = await api.slots.getFreeSpinSessionUrl({
        provider,
        gameId: pureGameId,
        extraData,
        gameList: providerGameList,
        params: { lang, isDesktop },
        hasFreeSpins,
      })
      dispatch(loadFreeSpinSession(response.data))
      return { id, payload: response.data }
    } catch (e) {
      if (currentAttempts >= attemptsToLoadSessionUrl) {
        callback()
      } else {
        dispatch(
          getFreeSpinSessionUrl({
            id: gameId,
            provider,
            gameId: pureGameId,
            withRedirect,
            isDesktop,
            attempts: currentAttempts,
            callback,
            hasFreeSpins,
          }),
        )

        throw new Error(JSON.stringify(e) || '')
      }
      return { id } as IGetFreeSpinSessionUrlResponse
    }
  },
)

export const getFreeSpinInfo = makeCommunicationActionCreator({
  loading: '@slots/GET_FREE_SPIN_INFO_LOADING',
  success: '@slots/GET_FREE_SPIN_INFO_SUCCESS',
  error: '@slots/GET_FREE_SPIN_INFO_ERROR',
  reset: '@slots/GET_FREE_SPIN_INFO_RESET',
})<{ provider: ProvidersType }, IFreeSpinInfoView>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const { provider } = payload
    const response = await api.slots.getFreeSpinInfo(provider)
    return response.data
  },
)

export const exitFreeSpinSession = makeCommunicationActionCreator({
  loading: '@slots/EXIT_FREE_SPIN_SESSION_LOADING',
  success: '@slots/EXIT_FREE_SPIN_SESSION_SUCCESS',
  error: '@slots/EXIT_FREE_SPIN_SESSION_ERROR',
  reset: '@slots/EXIT_FREE_SPIN_SESSION_RESET',
})<{ provider: ProvidersType }, IFreeSpinInfoView>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const { provider } = payload
    const response = await api.slots.getFreeSpinInfo(provider)
    return response.data
  },
)

export const getFavoriteGameList = makeCommunicationActionCreator({
  loading: '@slots/GET_FAVORITE_GAME_LIST_LOADING',
  success: '@slots/GET_FAVORITE_GAME_LIST_SUCCESS',
  error: '@slots/GET_FAVORITE_GAME_LIST_ERROR',
  reset: '@slots/GET_FAVORITE_GAME_LIST_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.slots.getFavoriteGameList()
    return response.data
  },
)

export const addFavoriteSlot = makeCommunicationActionCreator({
  loading: '@slots/ADD_FAVORITE_SLOT_LOADING',
  success: '@slots/ADD_FAVORITE_SLOT_SUCCESS',
  error: '@slots/ADD_FAVORITE_SLOT_ERROR',
  reset: '@slots/ADD_FAVORITE_SLOT_RESET',
})<IFavoriteSlotPayload, any>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const { provider, gameId } = payload
    const localeSlots = getState().locale.data.slots

    const response = await api.slots.addSlot(provider, gameId)

    if (response.success) {
      dispatch(addFavoriteSlotAction(`${gameId}_${provider}`))
      addNotify(localeSlots.slotSuccessfullyAdded, 'success')
    } else {
      addNotify(response.errorMessage, 'error')
    }
  },
)

export const removeFavoriteSlot = makeCommunicationActionCreator({
  loading: '@slots/REMOVE_FAVORITE_SLOT_LOADING',
  success: '@slots/REMOVE_FAVORITE_SLOT_SUCCESS',
  error: '@slots/REMOVE_FAVORITE_SLOT_ERROR',
  reset: '@slots/REMOVE_FAVORITE_SLOT_RESET',
})<IFavoriteSlotPayload, any>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const { provider, gameId } = payload
    const localeSlots = getState().locale.data.slots

    const response = await api.slots.removeSlot(provider, gameId)

    if (response.success) {
      dispatch(removeFavoriteSlotAction(`${gameId}_${provider}`))
      addNotify(localeSlots.slotSuccessfullyRemoved, 'success')
    } else {
      addNotify(response.errorMessage, 'error')
    }
  },
)

export const getProducers = makeCommunicationActionCreator({
  loading: '@slots/GET_PRODUCERS_LOADING',
  success: '@slots/GET_PRODUCERS_SUCCESS',
  error: '@slots/GET_PRODUCERS_ERROR',
  reset: '@slots/GET_PRODUCERS_RESET',
})<void, IProducer[]>(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.slots.loadProducers()

    return response.data
  },
)
