import { ICurrencyList, IGetCurrencyDTO, ISettings, ISettingsDTO } from 'shared/models/Settings'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { SettingsConverter } from './SettingsConverter'

class SettingsApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/settings`
    this.converter = new SettingsConverter()
  }

  async getSettings() {
    return this.sendQuery<ISettingsDTO, ISettings>({
      url: `${this.baseUrl}/`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertSettings,
    })
  }

  async getCurrencies() {
    return this.sendQuery<IGetCurrencyDTO, ICurrencyList>({
      url: `${this.baseUrl}/currency-list/`,
      type: QueryTypes.GET,
    })
  }
}

export default SettingsApi
