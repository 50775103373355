import { newBCraftServicesUrl } from 'shared/constants'
import { IServerEvents } from 'shared/models/Collector'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { CollectorConverter } from './CollectorConverter'

class CollectorApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${newBCraftServicesUrl}/services/analytics/collector/collector`
    this.converter = new CollectorConverter()
  }

  async postEvents(data: IServerEvents[]) {
    return this.sendQuery({
      url: `${this.baseUrl}/events`,
      type: QueryTypes.POST,
      data,
    })
  }
}

export default CollectorApi
