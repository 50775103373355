import { deleteEmptyFields } from 'shared/utils/filters'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { BonusHistoryConverter } from './BonusHistoryConverter'
import { IBonusHistoryFilters, ICashbackHistoryFilters } from './types/types'

class BonusHistoryApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/bonus-system`

    this.converter = new BonusHistoryConverter()
  }

  async loadBonusHistory(filters: IBonusHistoryFilters) {
    const filtersWithFields = deleteEmptyFields(filters)

    return this.sendQuery({
      url: `${this.baseUrl}/bonusbalance/history`,
      type: QueryTypes.GET,
      data: filtersWithFields,
      converterSuccess: this.converter.convertBonusHistory,
    })
  }

  async loadCashbackHistory(filters: ICashbackHistoryFilters) {
    const filtersWithFields = deleteEmptyFields(filters)

    return this.sendQuery({
      url: `${this.baseUrl}/cashback/history`,
      type: QueryTypes.GET,
      data: filtersWithFields,
      converterSuccess: this.converter.convertBonusHistory,
    })
  }
}

export default BonusHistoryApi
