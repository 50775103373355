import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import data from './data'

const persistConfig = {
  key: 'userSettings',
  storage,
  version: 1,
  blacklist: ['communications'],
}

export const reducer = persistReducer(persistConfig, data)
