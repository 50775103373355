import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

export type ISubItem = {
  text: string
  route: string
}

export interface INavigationItem {
  text: string
  route: string
  refRoutes?: string[]
  authReq?: boolean
  subItems?: ISubItem[]
}

export const navigationsItems: INavigationItem[] = [
  {
    text: 'sport',
    route: MODAL_ROUTE_URLS.CHOOSE_SPORT('sport'),
    refRoutes: [ROUTE_URLS.DIGITAIN_SPORT_PAGE('Home'), ROUTE_URLS.BETBY_SPORT()],
    subItems: [
      {
        text: 'sport1',
        route: ROUTE_URLS.DIGITAIN_SPORT_PAGE('Home'),
      },
      {
        text: 'sport2',
        route: ROUTE_URLS.BETBY_SPORT(),
      },
    ],
  },
  {
    text: 'live',
    route: MODAL_ROUTE_URLS.CHOOSE_SPORT("live"),
    refRoutes: [ROUTE_URLS.DIGITAIN_SPORT_PAGE('Live'), ROUTE_URLS.BETBY_SPORT('live')],
    subItems: [
      {
        text: 'live1',
        route: ROUTE_URLS.DIGITAIN_SPORT_PAGE('Live'),
      },
      {
        text: 'live2',
        route: ROUTE_URLS.BETBY_SPORT('live'),
      },
    ],
  },
  { text: 'casino', route: ROUTE_URLS.SLOTS_CASINO() },
  { text: 'liveCasino', route: ROUTE_URLS.LIVECASINO() },
  { text: 'Aviator', route: ROUTE_URLS.SLOTS_PROVIDER('otc', 'aviator_spribe'), authReq: true },
]
