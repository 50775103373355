import React, { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { WheelGamePage } from '@bcraft-project/frontend-lib'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { selectIsUserAuthenticated, selectUserCurrrency } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { selectLanguage } from 'features/userSettings/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { addNotify } from 'components/Notify'

import '@bcraft-project/frontend-lib/dist/style.css'
import './WheelGame.scss'

export const WheelGame = () => {
  const { pushNewModal } = useModalContext()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).wheelGame
  const userCurrency = useSelector(selectUserCurrrency, shallowEqual)
  const lang = useSelector(selectLanguage, shallowEqual)

  const notAuthCallback = useCallback(() => {
    addNotify(locale.pleaseAuth)
    pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())()
  }, [locale, pushNewModal])

  return (
    <WheelGamePage
      actions={{
        notAuthCallback,
      }}
      data={{
        isAuth,
        locale,
        currentLanguage: lang,
        userCurrency,
      }}
    />
  )
}
