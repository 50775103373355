import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IFrameManagerState } from '../types'

const initialState: IFrameManagerState = {
  data: {
    openedFrames: [],
  },
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.addFrame, (state, action) => {
      state.data.openedFrames = [
        ...state.data.openedFrames.filter(frame => frame.id !== action.payload.id),
        action.payload,
      ]
    })
    .addCase(actions.removeFrame, (state, action) => {
      state.data.openedFrames = state.data.openedFrames.filter(frame => frame.id !== action.payload)
    })
    .addCase(actions.changeIsOpenFrame, (state, action) => {
      state.data.openedFrames = state.data.openedFrames.map(frame => {
        if (frame.id === action.payload.id) {
          return {
            ...frame,
            isFrameOpened: action.payload.val,
          }
        }
        return frame
      })
    })
    .addCase(actions.changeIsFullscreenFrame, (state, action) => {
      state.data.openedFrames = state.data.openedFrames.map(frame => {
        if (frame.id === action.payload.id) {
          return {
            ...frame,
            isFullscreen: action.payload.val,
          }
        }
        return frame
      })
    })
})
