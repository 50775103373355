import { combineReducers } from 'redux'

import { IPaymentsState } from '../types'
import communications from './communications'
import data from './data'

export const reducer = combineReducers<IPaymentsState>({
  communications,
  data,
})
