import { createReducer } from '@reduxjs/toolkit'

import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import * as actions from '../actions'
import { ISlotsState } from '../types'

const initialState: ISlotsState['data'] = {
  gameList: [],
  errorMessage: '',
  gameUrl: '',
  freeSpinGameUrl: '',
  freeSpinInfo: null,
  extraData: {},
  favorite: [],
  gameListInField: {},
  producers: [],
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getGameListReplaced, (state, action) => {
      state.gameList = [
        ...state.gameList.filter(
          (t: ISlotViewItem) => t.routeProvider !== action.payload.provider || t.tab.indexOf('favorite') !== -1,
        ),
        ...action.payload.gameList,
      ]
    })
    .addCase(actions.getGameListInField, (state, action) => {
      const { provider, gameList } = action.payload

      state.gameListInField = {
        ...state.gameListInField,
        [provider]: gameList,
      }
    })
    .addCase(actions.getExtraDataSuccess, (state, action) => {
      state.extraData = {
        [action.payload.provider]: {
          ...state.extraData[action.payload.provider],
          ...action.payload.data,
        },
      }
    })
    .addCase(actions.setActionFailure, (state, action) => {
      state.errorMessage = action.payload
    })
    .addCase(actions.loadSession, (state, action) => {
      state.gameUrl = action.payload
    })
    .addCase(actions.loadFreeSpinSession, (state, action) => {
      state.freeSpinGameUrl = action.payload
    })
    .addCase(actions.getFreeSpinInfo.success, (state, action) => {
      state.freeSpinInfo = action.payload
    })
    .addCase(actions.exitFreeSpinSession.success, (state, action) => {
      state.freeSpinInfo = { ...action.payload, sessionEnd: true }
    })
    .addCase(actions.setBackUrl, (state, action) => {
      state.backUrl = action.payload
    })
    .addCase(actions.clearSessionUrl, (state, action) => {
      state.gameUrl = ''
    })
    .addCase(actions.clearFreeSpinSessionUrl, (state, action) => {
      state.freeSpinGameUrl = ''
      state.freeSpinInfo = null
    })
    .addCase(actions.getFavoriteGameList.success, (state, action) => {
      state.favorite = action.payload
    })
    .addCase(actions.addFavoriteSlotAction, (state, action) => {
      state.favorite = [action.payload, ...state.favorite]
    })
    .addCase(actions.removeFavoriteSlotAction, (state, action) => {
      state.favorite = [...state.favorite?.filter((t: string) => t !== action.payload)] || []
    })
    .addCase(actions.getProducers.success, (state, action) => {
      state.producers = action.payload
    })
})
