import React from 'react'
import { Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import { ROUTE_PATHS } from 'router/routes'

const BetbySportPage = React.lazy(() => import('pages/BetbySportPage/BetbySportPage'))

export class BetbySportModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.BETBY_SPORT} exact path={ROUTE_PATHS.BETBY_SPORT}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <BetbySportPage />
        </QueryParamProvider>
      </Route>
    )
  }
}
