import { ROUTE_PATHS } from 'router/routes'

import { ReactComponent as SettingsSVG } from 'shared/img/profile/settings-svg.svg'
import { ReactComponent as PlayersActivitiesSVG } from 'shared/img/profile/user-svg.svg'

interface IProfilePageItem {
  label: string
  icon: any
  items: { route: string; label: string; notDesktop?: boolean }[]
}

export const profilePageItems: Record<string, IProfilePageItem> = {
  playerActivities: {
    label: 'playersActivities',
    icon: PlayersActivitiesSVG,
    items: [
      {
        route: ROUTE_PATHS.PROFILE_BETHISTORY_DGT,
        label: 'betHistory1',
      },
      {
        route: ROUTE_PATHS.PROFILE_BETHISTORY_BETBY,
        label: 'betHistory2',
      },
      {
        route: ROUTE_PATHS.PROFILE_TRANSACTIONS,
        label: 'transactions',
      },
      {
        route: ROUTE_PATHS.PROFILE_SLOTS_HISTORY,
        label: 'slotsHistory',
      },
      {
        route: ROUTE_PATHS.PROFILE_SPIN_HISTORY,
        label: 'spinHistory',
      }
    ],
  },
  accountSettings: {
    label: 'accountSettings',
    icon: SettingsSVG,
    items: [
      {
        route: ROUTE_PATHS.PROFILE_BOOKMAKERS_SETTINGS,
        label: 'bookmakersSettings',
        notDesktop: true,
      },
      {
        route: ROUTE_PATHS.PROFILE_USERINFO,
        label: 'personalDetails',
      },
      {
        route: ROUTE_PATHS.PROFILE_EXCHANGE_BONUS_BALANCE,
        label: 'exchangeBonusBalance',
      },
      {
        route: ROUTE_PATHS.PROFILE_CHANGE_PASSWORD,
        label: 'changePassword',
      },
    ],
  },
}
