import { AvailableCryptoCurrenciesList } from 'shared/models/Crypto'

export const crtyptoCurrenciesWhiteList: AvailableCryptoCurrenciesList[] = [
  'btc',
  'eth',
  'usdttrc20',
  'usdterc20',
  'busdbsc',
  'bnbbsc',
  'doge',
  'trx',
  'ltc',
]
