import { History } from 'history'

import { ROUTE_URLS } from 'router/routes'

import { projectDomainName } from 'shared/constants'
import {
  desktopSportViews,
  mobileSportViews,
  pagesByViews,
  PageTypes,
  SportViews,
  SportViewType,
} from 'shared/models/Digitain'

const defaultMobileView: SportViewType = 'm'
const defaultDesktopView: SportViewType = 'af'

const defaultPage: PageTypes = 'Home'

export const convertView = (isMobile: boolean, view?: SportViewType, history?: History): SportViewType => {
  if (isMobile) {
    if (view && mobileSportViews.includes(SportViews[view as SportViewType])) return view
    history?.push(ROUTE_URLS.DIGITAIN_SPORT_PAGE(defaultPage, defaultMobileView))
    return defaultMobileView
  }
  if (view && desktopSportViews.includes(SportViews[view])) return view
  history?.push(ROUTE_URLS.DIGITAIN_SPORT_PAGE(defaultPage, defaultDesktopView))
  return defaultDesktopView
}

export const convertPage = (view: SportViewType, page?: PageTypes, history?: History): PageTypes => {
  if (page) {
    if (pagesByViews[view].includes(page)) return page
    history?.push(ROUTE_URLS.DIGITAIN_SPORT_PAGE(defaultPage, view))
    return defaultPage
  }
  history?.push(ROUTE_URLS.DIGITAIN_SPORT_PAGE(defaultPage, view))
  return defaultPage
}

export const getHostName = () =>
  window.location.hostname === 'localhost' ? projectDomainName : window.location.hostname

export const getDomainRoot = () => {
  const hostName = getHostName()
  const hostNameSplitted = hostName.split('.')
  const hostNamePure =
    hostNameSplitted.length > 2
      ? `${hostNameSplitted[hostNameSplitted.length - 2]}.${hostNameSplitted[hostNameSplitted.length - 1]}`
      : hostName

  return hostNamePure
}

export const getSportUrl = () => 'https://sport.dgspb.com/'
