import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import block from 'bem-cn'

import { languagesWithIcons } from 'shared/locale'
import { mediaSize } from 'shared/style/var'

import { actions } from 'features/userSettings'
import { selectLanguage } from 'features/userSettings/selectors'

import './SetLanguage.scss'

const SetLanguage: React.FC = () => {
  const b = block('select-lang')
  const dispatch = useDispatch()

  const isDesktop = useMediaQuery({ minWidth: mediaSize.laptop })

  const lang = useSelector(selectLanguage, shallowEqual)
  
  const changeLang = useCallback(newLang => {
    dispatch(actions.changeLang(newLang))
  }, [dispatch])

  const [isOpen, setOpened] = useState(false)
  const [timeoutId, setTimeoutId] = useState(0)

  const items = useMemo(
    () =>
      Object.values(languagesWithIcons).map(item => {
        const isCurent = lang === item.lang
        return (
          !isCurent && (
            <div key={item.lang} className={b('item')} onClick={() => changeLang(item.lang)}>
              {isDesktop && <img src={item.icon} alt="flag" className={b('item-flag')} />}
              <span className={b('item-text')}>
                {item.label}
              </span>
            </div>
          )
        )
      }),
    [lang, b, isDesktop, changeLang],
  )

  const openedSetTimeout = useCallback(
    time => {
      if (isOpen) {
        const newTimeout = setTimeout(() => {
          setOpened(false)
          setTimeoutId(0)
        }, time)
        setTimeoutId(+newTimeout)
      }
    },
    [isOpen],
  )

  useEffect(() => openedSetTimeout(6000), [isOpen])

  useEffect(
    () => () => {
      if (timeoutId) clearTimeout(timeoutId)
    },
    [timeoutId],
  )

  const onSectionClick = useCallback(() => {
    setOpened(!isOpen)
    if (!isOpen) openedSetTimeout(2500)
  }, [isOpen, openedSetTimeout])

  const onMouseLeave = useCallback(() => {
    openedSetTimeout(2500)
  }, [openedSetTimeout])

  const onMouseEnter = useCallback(() => {
    if (timeoutId) clearTimeout(timeoutId)
  }, [timeoutId])

  return (
    <div className={b()} onClick={onSectionClick} onMouseLeave={onMouseLeave}>
      <div className={b('items', { open: isOpen })} onMouseEnter={onMouseEnter}>
        {items}
      </div>
      <div className={b('top', { open: isOpen })}>
        {isDesktop && <img src={languagesWithIcons[lang]?.icon} alt="flag" className={b('top-flag')} />}
        <div className={b('top-text')}>{languagesWithIcons[lang]?.label || ''}</div>
      </div>
    </div>
  )
}

export default SetLanguage
