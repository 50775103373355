import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IWheelGameState } from '../types'

const initialState: IWheelGameState['data'] = {
  spinHistory: [],
  prizesList: [],
  publicSettings: null,
  spinWheel: null,
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getSpinHistory.success, (state, action) => {
      state.spinHistory = action.payload
    })
    .addCase(actions.getPrizesList.success, (state, action) => {
      state.prizesList = action.payload
    })
    .addCase(actions.getPublicSettings.success, (state, action) => {
      state.publicSettings = action.payload
    })
    .addCase(actions.spinWheel.success, (state, action) => {
      state.spinWheel = action.payload
    })
    .addCase(actions.clearSpinInfo, state => {
      state.spinWheel = initialState.spinWheel
    })
})
