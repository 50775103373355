import { createAction } from '@reduxjs/toolkit'

export const addFrame = createAction('@frameManager/ADD_FRAME', (id: string, sessionUrl: string, gameInfo) => ({
  payload: {
    id,
    isFrameOpened: false,
    isFullscreen: false,
    sessionUrl,
    provider: gameInfo.provider,
    gameId: gameInfo.gameId,
  },
}))

export const removeFrame = createAction<string>('@frameManager/REMOVE_FRAME')

export const changeIsOpenFrame = createAction('@frameManager/CHANGE_IS_OPEN_FRAME', (id: string, val: boolean) => ({
  payload: {
    id,
    val,
  },
}))

export const changeIsFullscreenFrame = createAction(
  '@frameManager/CHANGE_IS_FULL_OPEN_FRAME',
  (id: string, val: boolean) => ({
    payload: {
      id,
      val,
    },
  }),
)
