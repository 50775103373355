import { IProfile, IServerProfile, IServerUserInfo, IUserInfo, IUserInfoPayload } from 'shared/models/Profile'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { ProfileConverter } from './ProfileConverter'
import {
  IDefaultChangePassword,
  IDefaultPageFilters,
  ISlotsHistoryFilters,
  ITransactionFilters,
} from './types/types'

class ProfileApi extends BaseHttpService {
  private converter

  private usersUrl

  private transactionsUrl

  private betHistoryUrl

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/user`
    this.usersUrl = `${baseUrl}/api/users`
    this.transactionsUrl = `${baseUrl}/api/backoffice/transactions`
    this.betHistoryUrl = `${baseUrl}/api/backoffice/betshistory`
    this.converter = new ProfileConverter()
  }

  async loadTransfersList(filters: ITransactionFilters) {
    return this.sendQuery({
      url: `${this.transactionsUrl}/`,
      type: QueryTypes.GET,
      data: filters,
      converterSuccess: this.converter.convertTransfersList,
    })
  }

  async withdrawCashier(withdraw: number, currencies: string, parent: number) {
    return this.sendQuery({
      url: `${this.transactionsUrl}/`,
      type: QueryTypes.POST,
      data: { amount: withdraw, currency: currencies, recipient_id: parent },
    })
  }

  async getProfileInfo() {
    return this.sendQuery<IServerProfile, IProfile>({
      url: `${this.baseUrl}/profile`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertProfileInfo,
    })
  }

  async getUserInfo() {
    return this.sendQuery<IServerUserInfo, IUserInfo>({
      url: `${this.usersUrl}/user/info/`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertUserInfo,
    })
  }

  async updateUserInfo(data: IUserInfoPayload) {
    return this.sendQuery<IServerUserInfo, boolean>({
      url: `${this.usersUrl}/user/info/`,
      type: QueryTypes.PATCH,
      data,
    })
  }

  async changePassword(data: IDefaultChangePassword) {
    return this.sendQuery<IServerProfile, IProfile>({
      url: `${this.usersUrl}/user/change-password/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async sendKYCpicture(data: FormData) {
    return this.sendQuery({
      url: `${this.usersUrl}/documents/upload/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async getSlotsHistory(data: ISlotsHistoryFilters) {
    return this.sendQuery({
      url: `${this.betHistoryUrl}/`,
      type: QueryTypes.GET,
      data,
      converterSuccess: this.converter.convertSlotsHistory,
    })
  }
}

export default ProfileApi
