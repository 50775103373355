/* eslint-disable camelcase */
import dayjs from 'dayjs'

import { AvailableCryptoCurrenciesList } from 'shared/models/Crypto'

import { crtyptoCurrenciesWhiteList } from './data'
import {
  IApproveListItem,
  IApproveListItemDTO,
  IConvertedHistoryPayment,
  IConvertedHistoryPaymentItem,
  ICreateCryptoPaymentData,
  ICreateCryptoPaymentDTO,
  ICryptoCurrencyListDTO,
  ICryptoCurrencyWithMinAndMaxItem,
  ICryptoMinAmount,
  ICryptoMinAmountDTO,
  IDefaultDataConverted,
  IDefaultDataResponse,
  IDefaultDataResponseByService,
  IHistoryPaymentDTO,
  IManualListItem,
  IManualListItemDTO,
  ISystemListItem,
  ISystemListItemDTO,
} from './types/types'

export class PaymentConverter {
  convertPaymentList = (data: IDefaultDataResponse<IApproveListItemDTO>): IDefaultDataConverted<IApproveListItem> => {
    const paymentList =
      data?.results?.map(item => ({
        id: item.id,
        redirectLink: item.redirect_link,
        amountCurrency: item.amount_currency,
        amount: item.amount,
        status: item.status,
        createdAt: dayjs(item.created_at).format('DD.MM.YYYY HH:mm'),
        updatedAt: dayjs(item.updated_at).format('DD.MM.YYYY HH:mm'),
        user: item.user,
        paymentSystem: item.payment_system ? item.payment_system.slug : item.payout_system,
      })) || []

    return {
      page: data?.page || 1,
      count: data?.count || 0,
      list: paymentList,
    }
  }

  convertPaymentItem = (data: IApproveListItemDTO): IApproveListItem => ({
    paymentId: data.id,
    redirectLink: data.redirect_link,
    amountCurrency: data.amount_currency,
    amount: data.amount,
    status: data.status,
    createdAt: dayjs(data.created_at).format('DD.MM.YYYY HH:mm'),
    updatedAt: dayjs(data.updated_at).format('DD.MM.YYYY HH:mm'),
    user: data.user,
    paymentSystem: data.payment_system,
  })

  convertApproveList = (data: IDefaultDataResponse<IApproveListItemDTO>): IDefaultDataConverted<IApproveListItem> => {
    const approveList =
      data?.results?.map(item => ({
        id: item.id,
        redirectLink: item.redirect_link,
        amountCurrency: item.amount_currency,
        amount: item.amount,
        status: item.status,
        createdAt: dayjs(item.created_at).format('DD.MM.YYYY HH:mm'),
        updatedAt: dayjs(item.updated_at).format('DD.MM.YYYY HH:mm'),
        user: item.user,
        paymentSystem: item.payment_system ? item.payment_system.slug : item.payout_system,
      })) || []

    return {
      page: data?.page || 1,
      count: data?.count || 0,
      list: approveList,
    }
  }

  convertManualList = (data: IDefaultDataResponse<IManualListItemDTO>): IDefaultDataConverted<IManualListItem> => {
    const manualList =
      data?.results?.map(item => ({
        id: item.id,
        redirectLink: item.redirect_link,
        amountCurrency: item.amount_currency,
        amount: item.amount,
        status: item.status,
        createdAt: dayjs(item.created_at).format('DD.MM.YYYY HH:mm'),
        updatedAt: dayjs(item.updated_at).format('DD.MM.YYYY HH:mm'),
        user: item.user,
        paymentSystem: item.payment_system ? item.payment_system.slug : item.payout_system,
      })) || []

    return {
      page: data?.page || 1,
      count: data?.count || 0,
      list: manualList,
    }
  }

  convertSystemList = (data: ISystemListItemDTO[]): IDefaultDataConverted<ISystemListItem> => {
    const systemList =
      data?.map(item => ({
        slug: item.slug,
        name: item.name,
        description: item.description,
        min: item.min,
        max: item.max,
        isTemporaryDisabled: item.is_temporary_disabled,
      })) || []

    return {
      page: 1,
      count: systemList?.length || 0,
      list: systemList,
    }
  }

  convertCryptoCurrencies = (data: ICryptoCurrencyListDTO): ICryptoCurrencyWithMinAndMaxItem[] =>
    data.selectedCurrencies
      .map(t => ({
        currency: t.toLowerCase(),
      }))
      .sort(
        (a, b) =>
          crtyptoCurrenciesWhiteList.indexOf(a.currency as AvailableCryptoCurrenciesList) -
          crtyptoCurrenciesWhiteList.indexOf(b.currency as AvailableCryptoCurrenciesList),
      )
      .filter(t => crtyptoCurrenciesWhiteList.indexOf(t.currency as AvailableCryptoCurrenciesList) !== -1)

  convertCreateCryptoPayment = (data: ICreateCryptoPaymentDTO): ICreateCryptoPaymentData => ({
    id: data.pay_currency,
    address: data.pay_address,
    currency: data.pay_currency,
  })

  convertCryptoMinAmount = (data: ICryptoMinAmountDTO): ICryptoMinAmount => ({
    currency: data.currency_from,
    min: data.min_amount,
    fiatAmount: data.fiat_equivalent,
  })

  convertHistoryPayment = (data: IDefaultDataResponseByService<IHistoryPaymentDTO>): IConvertedHistoryPayment => {
    const convertedItems: IConvertedHistoryPaymentItem[] = data.items.map((t: IHistoryPaymentDTO) => ({
      id: `${t.id}_${t.payment_type}`,
      paymentId: `${t.id}`,
      currency: t.currency,
      amount: +t.amount.toFixed(2),
      status: t.status,
      paymentSystem: t.payment_by,
      paymentType: t.payment_type,
      createdAt: dayjs(t.created_at).format('DD/MM/YY HH:mm'),
    }))

    return {
      page: data.page,
      count: data.total_items,
      items: convertedItems,
    }
  }
}
