import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { loadSettings } from 'features/settings/actions'
import { selectLanguage } from 'features/userSettings/selectors'

import { useAppDispatch } from '../../index';

const LimitsPolling: React.FC = () => {
  const dispatch = useAppDispatch()
  const lang = useSelector(selectLanguage, shallowEqual)
  // eslint-disable-next-line no-undef
  const currentTimeoutId = useRef<NodeJS.Timeout>()
  // eslint-disable-next-line no-undef
  const previousTimeoutId = useRef<number>()

  const getTimeoutedFunc = useMemo(
    () => () => {
      currentTimeoutId.current = setTimeout(() => {
        previousTimeoutId.current = Number(currentTimeoutId.current);
        dispatch(loadSettings()).then(() => {
          if (previousTimeoutId.current === currentTimeoutId.current) getTimeoutedFunc();
        })
      }, 20 * 60 * 1000);
    },
    [dispatch],
  );

  const getLimitsFunc = useCallback(() => {
    getTimeoutedFunc()
    dispatch(loadSettings())
  }, [getTimeoutedFunc])

  useEffect(() => {
    if (currentTimeoutId.current) {
      clearTimeout(currentTimeoutId.current)
      previousTimeoutId.current = 0
    }
    getLimitsFunc()
    return () => {
      if (currentTimeoutId.current) {
        clearTimeout(currentTimeoutId.current)
        previousTimeoutId.current = 0
      }
    }
  }, [lang])
  return null
};

export default LimitsPolling
