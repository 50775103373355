import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'

import { ISession, ISessionRefreshToken } from 'shared/models/Auth'

export const getIsAccessTokenExpired = (accessToken: ISession['access']): boolean => {
  try {
    const decodedAccessToken = jwtDecode<ISessionRefreshToken>(accessToken)

    const isAccessTokenExpired = dayjs(decodedAccessToken.exp * 1000).isBefore(dayjs())

    return isAccessTokenExpired
  } catch {
    return true
  }
}
