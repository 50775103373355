import React, { Suspense, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useRouteMatch } from 'react-router-dom'
import block from 'bem-cn'
import { AnimatePresence } from 'framer-motion'
import socketIOClient from 'socket.io-client'

import { ROUTE_PATHS, ROUTE_URLS } from 'router/routes'

import { mediaSize } from 'shared/style/var'

import { actions as authActions } from 'features/auth'
import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { actions as userSettingsActions } from 'features/userSettings'
import { selectLanguage } from 'features/userSettings/selectors'

import { ModalContext, ModalQueryContext } from 'components/HOC/withModalQuery/ModalContext'
import withModalQuery from 'components/HOC/withModalQuery/withModalQuery'
import ModalManager from 'components/ModalManager/ModalManager'
import Notify from 'components/Notify/Notify'

import BalancePolling from 'components/BalancePolling/BalancePolling'
import CarouselSliderPolling from 'components/CarouselSliderPolling'
import { ConfirmBirthDateOpen } from 'components/ConfirmBirthDateOpen'
import DefineDeviceComponent from 'components/DefineDeviceComponent'
import { DeviceDetect } from 'components/DeviceDetect/DeviceDetect'
import { Footer } from 'components/Footer/Footer'
import { Header } from 'components/Header/Header'
import LimitsPolling from 'components/LimitsPolling/LimitsPolling'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import NavMenuMobile from 'components/NavMenuMobile/NavMenuMobile'
import CashbackNotify from 'components/notifies/CashbackNotify/CashbackNotify'
import FirstDepositNotify from 'components/notifies/FirstDepositNotify/FirstDepositNotify'
import PersonalJackpotNotify from 'components/notifies/PersonalJackpotNotify/PersonalJackpotNotify'
import ProfileMenu from 'components/ProfileMenu/ProfileMenu'
import { SideMenu } from 'components/SideMenu/SideMenu'
import Spinner from 'components/Spinner'

import './App.scss'

// const socket = socketIOClient('https://socket-test.new.bcraftservices.com/', {
//   transports: ['websocket'],
//   path: '/socket.io',
// })

const b = block('app')

interface IAppProps {
  modalFilters: ModalQueryContext
}

const App: React.FC<IAppProps> = ({ children, modalFilters }) => {
  const dispatch = useDispatch()

  const isSportPage = !!useRouteMatch({
    path: [ROUTE_PATHS.DIGITAIN_SPORT_PAGE, ROUTE_PATHS.BETBY_SPORT],
  })?.isExact
  const isMainPage = !!useRouteMatch({
    path: ROUTE_URLS.MAIN(),
  })?.isExact

  const isDesktop = useMediaQuery({ minWidth: mediaSize.laptop })

  const [isOpenSideMenu, setIsOpenSideMenu] = useState(false)
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false)

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const lang = useSelector(selectLanguage, shallowEqual)

  const loadUserData = useCallback(() => {
    dispatch(authActions.getUserData())
  }, [])

  const onOpenSideMenu = useCallback(() => {
    setIsOpenSideMenu(true)
  }, [])

  const onCloseSideMenu = useCallback(() => {
    setIsOpenSideMenu(false)
  }, [])

  const onToggleProfileMenu = useCallback((val?: boolean) => {
    setIsOpenProfileMenu(prev => (val === undefined ? !prev : val))
  }, [])

  useEffect(() => {
    if (lang) {
      dispatch(userSettingsActions.changeLang(lang))
    }
  }, [dispatch, lang])

  useEffect(() => {
    if (isAuth) {
      loadUserData()
    }
  }, [])

  useLayoutEffect(() => {
    setTimeout(() => localStorage.removeItem('reloadCount'), 2000)
  }, [])

  // useEffect(() => {
  //   socket.on('connect', () => {
  //     console.log('connected')
  //   })
  //   socket.on('event', data => {
  //     console.log(data)
  //   })

  //   return () => {
  //     socket.off('connect')
  //     socket.off('event')
  //   }
  // }, [])

  return (
    <div className={b('container', { isOpenSideMenu, isSportPage })}>
      <ModalContext.Provider value={modalFilters}>
        <Notify />
        <LimitsPolling />
        <CashbackNotify />
        <PersonalJackpotNotify />
        <FirstDepositNotify />
        <CarouselSliderPolling />
        <ModalManager />
        <DeviceDetect />
        <ConfirmBirthDateOpen />
        <DefineDeviceComponent
          mobileComponent={
            <>
              <SideMenu isOpen={isOpenSideMenu} onClose={onCloseSideMenu} />
              <ProfileMenu isOpenProfileMenu={isOpenProfileMenu} onToggleProfileMenu={onToggleProfileMenu} />
            </>
          }
          desktopComponent={<></>}
        />
        <div className={b('main', { isOpenSideMenu })}>
          <div className={b('main-inner')}>
            <Header
              isOpenSideMenu={isOpenSideMenu}
              onOpenSideMenu={onOpenSideMenu}
              isOpenProfileMenu={isOpenProfileMenu}
              onToggleProfileMenu={onToggleProfileMenu}
            />
            {!isDesktop && <NavigationHeader />}
            <Suspense fallback={<Spinner isLoading />}>
              <div className={b('main-content')}>
                <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
              </div>
            </Suspense>
            {isMainPage ? (
              <Footer />
            ) : (
              <DefineDeviceComponent mobileComponent={<NavMenuMobile />} desktopComponent={<Footer />} />
            )}
          </div>
        </div>
        <BalancePolling key={`${isAuth}`} />
      </ModalContext.Provider>
    </div>
  )
}

export default withModalQuery(App)
