import React from 'react'
import { Redirect,Switch } from 'react-router-dom'

export function createRoutes(modules) {
  const routesFromModules = modules.map(module => module.getRoutes())
  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/main" />
        {routesFromModules}
      </Switch>
    </>
  )
}
