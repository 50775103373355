import { generatePath } from 'react-router-dom'

import { PageTypes as BetByPageTypes, SportIndexes, SportTypes } from 'shared/models/Betby'
import { PageTypes, SportIds, SportViewType } from 'shared/models/Digitain'
import { mediaSize } from 'shared/style/var'
import { convertView } from 'shared/utils/digitain'

import { ISlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

export enum ROUTE_PATHS {
  MAIN = '/main',
  PROFILE = '/profile',
  PROFILE_USERINFO = '/profile/user-info',
  PROFILE_WITHDRAW = '/profile/withdraw',
  PROFILE_TRANSACTIONS = '/profile/transactions',
  PROFILE_TRANSACTIONS_ID = '/profile/transactions/:id',
  PROFILE_PROMOCODE = '/profile/promocode',
  PROFILE_LIMITS = '/profile/limits',
  PROFILE_USERBONUS = '/profile/user-bonus',
  PROFILE_SELFEXCLUSION = '/profile/self-exclusion',
  PROFILE_BONUSHISTORY = '/profile/bonus-history',
  PROFILE_CASHBACKHISTORY = '/profile/cashback-history',
  PROFILE_BETDETAIL = '/profile/bet-detail',
  PROFILE_REPLENISHMENT = '/profile/replenishment',
  PROFILE_CASHIER_WITHDRAW = '/profile/cashier',
  PROFILE_KYC_VERIFICATION = '/profile/kyc-verifiaction',
  PROFILE_EXCHANGE_BONUS_BALANCE = '/profile/exchange-bonus-balance',
  PROFILE_CHANGE_PASSWORD = '/profile/change-password',
  PROFILE_BOOKMAKERS_SETTINGS = '/profile/bookmakers-settings',
  PROFILE_SLOTS_HISTORY = '/profile/slots-history',
  PROFILE_SPIN_HISTORY = '/profile/spin-history',
  BALANCE = '/balance',
  HELP = '/help',
  HELP_FAQ = '/faq',
  HELP_IGROVOI_KONT = '/faq/igrovoi-kont',
  HELP_DEPOSITS_WITHDRAWALS = '/faq/depoziti-i-vyplati',
  VERIFY = '/verify',
  VERIFY_EMAIL = '/verify/:key',
  TERMSANDCONDITIONS = '/terms-and-conditions',
  TERMSANDCONDITIONS_POINT = '/terms-and-conditions/:pointId',
  TERMSANDCONDITIONS_GENERALTERMS = '/terms-and-conditions/generalTerms',
  TERMSANDCONDITIONS_PRIVACYPOLICY = '/terms-and-conditions/privacyPolicy',
  LIVECASINO = '/slots/live-casino',
  PERSONS = '/persons',
  PROMOTIONS = '/promotions',
  PROMOTIONS_DETAIL = '/promotions-detail/:type',
  PROMOTIONS_OFFER_ONE = '/promotions/welcome-offer-1',
  PROMOTIONS_OFFER_TWO = '/promotions/welcome-offer-2',
  PROMOTIONS_OFFER_THREE = '/promotions/welcome-offer-3',
  PROMOTIONS_OFFER_FOUR = '/promotions/welcome-offer-4',
  PROMOTIONS_OFFER_FIVE = '/promotions/welcome-offer-5',
  PROMOTIONS_OFFER_SIX = '/promotions/welcome-offer-6',
  PROMOTIONS_OFFER_SEVEN = '/promotions/welcome-offer-7',
  PROMOTIONS_OFFER_EIGHT = '/promotions/welcome-offer-8',
  PROMOTIONS_OFFER_NINE = '/promotions/welcome-offer-9',
  SLOTS = '/slots',
  SLOTS_CASINO = '/slots/casino',
  SLOTS_ALL = '/slots/all',
  SLOTS_SECTION = '/slots/:sectionId/:id',
  SLOTS_PROVIDERS = '/slots/casino/?providers=:provider',
  SLOTS_CATEGORIES = '/slots/casino/?categories=:category',
  SLOTS_LIVE_PROVIDERS = '/slots/live-casino/?providers=:provider',
  SLOTS_PROVIDER = '/slots/:provider/:gameId',
  SLOTS_PROVIDER_DEMO_START = '/slots/:provider/:gameId/:demoStart',
  SLOTS_PROVIDER_FREE_SPIN = '/slots/:provider/:gameId/:freeSpin/session',
  SLOTS_GAMETYPE = '/slots/:gameType',
  DIGITAIN_SPORT_PAGE = '/sport/dgt/:view/:page?/:id?',
  PROFILE_BETHISTORY_DGT = '/profile/bet-history/dgt',
  PROFILE_BETHISTORY_BETBY = '/profile/bet-history/betby',
  EMAIL = '/email',
  EMAIL_VERIFY_EMAIL = '/email/verify/email/:token',
  EMAIL_VERIFY_PASSWORD_CONFIRM = '/email/verify/password/confirm/:token',
  BETBY_SPORT = '/sport/bby/',
  WHEEL_GAME = '/wheel-game',
}

export const ROUTE_URLS = {
  MAIN: () => generatePath(ROUTE_PATHS.MAIN),
  PROFILE: () => generatePath(ROUTE_PATHS.PROFILE),
  PROFILE_USERINFO: () => generatePath(ROUTE_PATHS.PROFILE_USERINFO),
  PROFILE_WITHDRAW: () => generatePath(ROUTE_PATHS.PROFILE_WITHDRAW),
  PROFILE_TRANSACTIONS: () => generatePath(ROUTE_PATHS.PROFILE_TRANSACTIONS),
  PROFILE_TRANSACTIONS_ID: (id: number) => generatePath(ROUTE_PATHS.PROFILE_TRANSACTIONS_ID, { id }),
  PROFILE_BONUSHISTORY: () => generatePath(ROUTE_PATHS.PROFILE_BONUSHISTORY),
  PROFILE_CASHBACKHISTORY: () => generatePath(ROUTE_PATHS.PROFILE_CASHBACKHISTORY),
  PROFILE_PROMOCODE: () => generatePath(ROUTE_PATHS.PROFILE_PROMOCODE),
  PROFILE_LIMITS: () => generatePath(ROUTE_PATHS.PROFILE_LIMITS),
  PROFILE_REPLENISHMENT: () => generatePath(ROUTE_PATHS.PROFILE_REPLENISHMENT),
  PROFILE_CASHIER_WITHDRAW: () => generatePath(ROUTE_PATHS.PROFILE_CASHIER_WITHDRAW),
  PROFILE_BETDETAIL: () => generatePath(ROUTE_PATHS.PROFILE_BETDETAIL),
  PROFILE_KYC_VERIFICATION: () => generatePath(ROUTE_PATHS.PROFILE_KYC_VERIFICATION),
  PROFILE_EXCHANGE_BONUS_BALANCE: () => generatePath(ROUTE_PATHS.PROFILE_EXCHANGE_BONUS_BALANCE),
  PROFILE_SLOTS_HISTORY: () => generatePath(ROUTE_PATHS.PROFILE_SLOTS_HISTORY),
  PROFILE_SPIN_HISTORY: () => generatePath(ROUTE_PATHS.PROFILE_SPIN_HISTORY),
  BALANCE: () => generatePath(ROUTE_PATHS.BALANCE),
  HELP: () => generatePath(ROUTE_PATHS.HELP),
  HELP_FAQ: () => generatePath(ROUTE_PATHS.HELP_FAQ),
  HELP_IGROVOI_KONT: () => generatePath(ROUTE_PATHS.HELP_IGROVOI_KONT),
  HELP_DEPOSITS_WITHDRAWALS: () => generatePath(ROUTE_PATHS.HELP_DEPOSITS_WITHDRAWALS),
  VERIFY: () => generatePath(ROUTE_PATHS.VERIFY),
  VERIFY_EMAIL: (key: string) => generatePath(ROUTE_PATHS.VERIFY_EMAIL, { key }),
  TERMSANDCONDITIONS: () => generatePath(ROUTE_PATHS.TERMSANDCONDITIONS),
  TERMSANDCONDITIONS_POINT: (pointId: string) => generatePath(ROUTE_PATHS.TERMSANDCONDITIONS_POINT, { pointId }),
  TERMSANDCONDITIONS_GENERALTERMS: () => generatePath(ROUTE_PATHS.TERMSANDCONDITIONS_GENERALTERMS),
  TERMSANDCONDITIONS_PRIVACYPOLICY: () => generatePath(ROUTE_PATHS.TERMSANDCONDITIONS_PRIVACYPOLICY),
  LIVECASINO: () => generatePath(ROUTE_PATHS.LIVECASINO),
  PERSONS: () => generatePath(ROUTE_PATHS.PERSONS),
  PROMOTIONS: () => generatePath(ROUTE_PATHS.PROMOTIONS),
  PROMOTIONS_DETAIL: (type = 'deposit') => generatePath(ROUTE_PATHS.PROMOTIONS_DETAIL, { type }),
  PROMOTIONS_OFFER_ONE: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_ONE),
  PROMOTIONS_OFFER_TWO: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_TWO),
  PROMOTIONS_OFFER_THREE: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_THREE),
  PROMOTIONS_OFFER_FOUR: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_FOUR),
  PROMOTIONS_OFFER_FIVE: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_FIVE),
  PROMOTIONS_OFFER_SIX: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_SIX),
  PROMOTIONS_OFFER_SEVEN: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_SEVEN),
  PROMOTIONS_OFFER_EIGHT: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_EIGHT),
  PROMOTIONS_OFFER_NINE: () => generatePath(ROUTE_PATHS.PROMOTIONS_OFFER_NINE),
  SLOTS: () => generatePath(ROUTE_PATHS.SLOTS),
  SLOTS_CASINO: () => generatePath(ROUTE_PATHS.SLOTS_CASINO),
  SLOTS_ALL: () => generatePath(ROUTE_PATHS.SLOTS_ALL),
  SLOTS_SECTION: (sectionId: string, id: string) => generatePath(ROUTE_PATHS.SLOTS_SECTION, { sectionId, id }),
  SLOTS_PROVIDERS: (provider: string) => generatePath(ROUTE_PATHS.SLOTS_PROVIDERS, { provider }),
  SLOTS_CATEGORIES: (category: string) => generatePath(ROUTE_PATHS.SLOTS_CATEGORIES, { category }),
  SLOTS_LIVE_PROVIDERS: (provider: string) => generatePath(ROUTE_PATHS.SLOTS_LIVE_PROVIDERS, { provider }),
  SLOTS_PROVIDER: (provider: string, gameId: string, demoStart?: string) =>
    generatePath(ROUTE_PATHS.SLOTS_PROVIDER, {
      provider,
      gameId,
    }),
  SLOTS_PROVIDER_DEMO_START: (provider: string, gameId: string, demoStart?: string) =>
    generatePath(ROUTE_PATHS.SLOTS_PROVIDER_DEMO_START, {
      provider,
      gameId,
      demoStart: demoStart || '',
    }),
  SLOTS_PROVIDER_FREE_SPIN: (provider: string, gameId: string, freeSpin: 'free-spin') =>
    generatePath(ROUTE_PATHS.SLOTS_PROVIDER_FREE_SPIN, {
      provider,
      gameId,
      freeSpin,
    }),
  SLOTS_GAMETYPE: (gameType: ISlotsGameType) => generatePath(ROUTE_PATHS.SLOTS_GAMETYPE, { gameType }),
  DIGITAIN_SPORT_PAGE: (page: PageTypes, view?: SportViewType, id?: string | SportIds) => {
    const isMobile = window.innerWidth < Number(mediaSize.tablet.replace('px', ''))
    console.log('page', page)
    return generatePath(ROUTE_PATHS.DIGITAIN_SPORT_PAGE, {
      view: convertView(isMobile, view),
      page,
      id,
    })
  },
  PROFILE_BETHISTORY_DGT: () => generatePath(ROUTE_PATHS.PROFILE_BETHISTORY_DGT),
  BETBY_SPORT: (page?: BetByPageTypes) => {
    const convertedPage = page ? `/${SportIndexes[page as SportTypes] || page}` : '/'
    return `${generatePath(ROUTE_PATHS.BETBY_SPORT)}?bt-path=${convertedPage}`
  },
  WHEEL_GAME: () => generatePath(ROUTE_PATHS.WHEEL_GAME),
}
