import PtPNG from './img/br.png'
import EsPNG from './img/spain.png'
import EnPNG from './img/us.png'

export type languagesType = 'es-ES' | 'en-US' | 'pt-BR'

interface LangOptions {
  lang: languagesType
  icon: string
  text: string
  label: string
  isArabic?: boolean
}

export const languagesForApi: { [key: string]: languagesType } = {
  'es-ES': 'es-ES',
  'en-US': 'en-US',
  'pt-BR': 'pt-BR',
}

export const languages: { [key in 'ES' | 'EN' | 'PT']: languagesType } = {
  ES: 'es-ES',
  EN: 'en-US',
  PT: 'pt-BR',
}

export const languagesWithIcons: { [key in languagesType]: LangOptions } = {
  'es-ES': { lang: languages.ES, icon: EsPNG, text: 'Español', label: 'ES', },
  'en-US': { lang: languages.EN, icon: EnPNG, text: 'English', label: 'EN', },
  'pt-BR': { lang: languages.PT, icon: PtPNG, text: 'Português', label: 'PT', },
}

export const shortLanguages: { [key: string]: string } = {
  'es-ES': 'es',
  'en-US': 'en',
  'pt-BR': 'pt',
}

export const transLang: { [key in languagesType]: string } = {
  'es-ES': 'es-ES',
  'en-US': 'en-US',
  'pt-BR': 'pt-BR',
}

export const defaultLanguage: languagesType = languages.ES
