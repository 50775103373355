import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { useOutsideClick } from 'shared/hooks/useOutsideClick'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { INavigationItem, ISubItem } from '../data'
import { ReactComponent as ArrowSVG } from '../img/arrow-down.svg'

import './NavigationItem.scss'

interface INavigationItemProps {
  item: INavigationItem
  isScrolled: boolean
  onScrollComplete: () => void
}

const b = block('navigation-header__item')
const b2 = block('navigation-header__dropdown')

const NavigationItem: React.FC<INavigationItemProps> = ({ item, isScrolled, onScrollComplete }) => {
  const { pathname, search } = useLocation()

  const dropdownRef = useRef<HTMLDivElement>(null)
  
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dropdownOffset, setDropdownOffset] = useState<number>(0)

  const { text, route, refRoutes, subItems, authReq } = item

  const { pushNewModal } = useModalContext()

  useOutsideClick(dropdownRef, () => setIsOpen(false))

  const locale = useSelector(selectLocaleDict).common
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  const isActive = useMemo(
    () =>
      `${pathname}${search}`.indexOf(route) !== -1 ||
      (refRoutes && refRoutes.includes(`${pathname}${search}`)),
    [pathname, refRoutes, route, search],
  )

  const handleItemClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (authReq && !isAuth) {
        e.preventDefault()

        pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())()
      }

      if (subItems) {
        e.preventDefault()
        
        setIsOpen(!isOpen)
      }
    },
    [authReq, isAuth, pushNewModal, subItems, isOpen],
  )

  useEffect(() => {
    if (dropdownRef.current) {
      const offset = dropdownRef.current?.getBoundingClientRect().x
      setDropdownOffset(offset)
    }
  }, [])

  useEffect(() => {
    if (isScrolled && dropdownRef) {
      const offset = dropdownRef.current?.getBoundingClientRect().x
      setDropdownOffset(offset || 0)
      onScrollComplete()
    }
  }, [isScrolled, onScrollComplete])

  return useMemo(
    () => {
      if (!subItems) {
        return (
          <Link className={b({ active: isActive })} to={route} onClick={handleItemClick}>
            {locale[text] || text}
          </Link>
        )
      }

      return (
        <div className={b2()} ref={dropdownRef} onClick={handleItemClick}>
          <div className={b2('top', { active: isActive })}>
            <span className={b2('top-text')}>
              {locale[text] || text}
            </span>
            <ArrowSVG className={b2('top-arrow', { open: isOpen })} />
          </div>
          <div className={b2('items', { open: isOpen })} style={{ left: `${dropdownOffset - 16}px` }}>
            {subItems.map((subItem: ISubItem, id: number) => (
              <Link
                key={`sub_${subItem.text}_${id}`}
                to={subItem.route}
                className={b({ active: `${pathname}${search}` === subItem.route, dropdown: true })}
              >
                {locale[subItem.text] || subItem.text}
              </Link>
            ))}
          </div>
        </div>
      )
    }, [dropdownOffset, handleItemClick, isActive, isOpen, locale, pathname, route, search, subItems, text])
}

export default NavigationItem
