import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { ISettingsState } from '../types'

export default combineReducers<ISettingsState['communications']>({
  settings: makeCommunicationReducer(actions.loadSettings),
  getCurrenciesList: makeCommunicationReducer(actions.getCurrenciesList),
})
