import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IBonusHistoryState } from '../types'

const initialState: IBonusHistoryState['data'] = {
  bonusHistory: {
    page: 1,
    count: 0,
    history: [],
  },
  cashbackHistory: {
    page: 1,
    count: 0,
    history: [],
  },
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getBonusHistory.success, (state, action) => {
      state.bonusHistory = action.payload
    })
    .addCase(actions.getCashbackHistory.success, (state, action) => {
      state.cashbackHistory = action.payload
    })

})
