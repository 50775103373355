import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import { ROUTE_PATHS } from 'router/routes'

import DefineDeviceComponent from 'components/DefineDeviceComponent'

const SlotFrame = React.lazy(() => import('./components/SlotFrame/SlotFrame'))
const FreeSpinFrame = React.lazy(() => import('./components/FreeSpinFrame/FreeSpinFrame'))
const SlotRedirect = React.lazy(() => import('./components/SlotRedirect/SlotRedirect'))
const SlotsLayout = React.lazy(() => import('./components/SlotsLayout/SlotsLayout'))

export class SlotsModule {
  getRoutes() {
    return (
      <Route key="slots" path={ROUTE_PATHS.SLOTS}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route
              key={ROUTE_PATHS.SLOTS_PROVIDER}
              exact
              path={ROUTE_PATHS.SLOTS_PROVIDER}
              component={() => (
                <DefineDeviceComponent mobileComponent={<SlotRedirect />} desktopComponent={<SlotFrame />} />
              )}
            />
            <Route
              key={ROUTE_PATHS.SLOTS_PROVIDER_DEMO_START}
              exact
              path={ROUTE_PATHS.SLOTS_PROVIDER_DEMO_START}
              component={() => (
                <DefineDeviceComponent mobileComponent={<SlotRedirect />} desktopComponent={<SlotFrame />} />
              )}
            />
            <Route
              exact
              key={ROUTE_PATHS.SLOTS_PROVIDER_FREE_SPIN}
              path={ROUTE_PATHS.SLOTS_PROVIDER_FREE_SPIN}
              component={FreeSpinFrame}
            />
            <Route exact path={ROUTE_PATHS.SLOTS_GAMETYPE}>
              <SlotsLayout />
            </Route>
            <Route exact path={ROUTE_PATHS.SLOTS}>
              <Redirect to={ROUTE_PATHS.SLOTS_CASINO} />
            </Route>
          </Switch>
        </QueryParamProvider>
      </Route>
    )
  }
}
