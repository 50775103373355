import { toast } from 'react-toastify'

interface INotification {
  text: string
  type: string
  id: string
  needClose: boolean
}

const _addNotify = ({ notification }: { notification: INotification }) => {

  switch (notification.type) {
    case 'default':
      toast.info(notification.text)
      break
    case 'error':
      toast.error(notification.text)
      break
    case 'warn':
      toast.warn(notification.text)
      break
    case 'success':
      toast.success(notification.text)
      break
    default:
      toast.info(notification.text)
  }
}

export function addNotify(text: string, type = 'default', needClose = true) {
  const id = new Date().toISOString()
  const notification = { text, type, id, needClose }
  _addNotify({ notification })
}
