import { renameProducerForBetshistory } from 'shared/utils/slotsHistory'

import { ProvidersType } from 'modules/SlotsRoot/types/configuration'
import { IProducer, IProducersList } from 'modules/SlotsRoot/types/interfaces/Api'
import { IGetGameListDTO, ISlotDTO } from 'modules/SlotsRoot/types/interfaces/Slots/GameCatalog'
import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { getGameCatalogImage } from 'modules/SlotsRoot/utils/images'

import { IConvertGameList, ISlotConverter } from '../../types/converter'
import { getTabLive } from '../Softswiss/SoftwissUtils'
import { blackListGames, getGameCatalogTab, getSlotProviderName, providersWhiteList } from './GameCatalogUtils'
import { uniqGameId } from './uniqGameId'

class GameCatalogConverter implements ISlotConverter {
  convertGameList = ({ data, platform }: IConvertGameList<IGetGameListDTO>): ISlotViewItem[] =>
    data.games
      .map((item: ISlotDTO) => ({
        id: String(item.id),
        gameId: uniqGameId(item.integration, item.provider, item.game_id),
        description: item.description,
        gameName: item.name,
        tab: getGameCatalogTab(item.game_id, item.drops_and_wins),
        tabLive: getTabLive(item.game_id, item.drops_and_wins),
        provider: getSlotProviderName(item),
        imageUrl: getGameCatalogImage(item.game_id, item.image, item.image_desktop, platform),
        routeProvider: item.integration,
        additionalData: {
          system: item.slug.split('/')?.[0],
        },
      }))
      .filter(t => providersWhiteList.indexOf(t.provider) !== -1 && blackListGames.indexOf(t.gameId) === -1)

  convertGameUrl = ({ data }: { data: string }) => data

  convertGameUrlPragmatic = ({ data }: { data: { gameURL: string } }) => data.gameURL

  // eslint-disable-next-line camelcase
  convertGameUrlBcraft = ({ data }: { data: { game_url: string } }) => data.game_url

  convertProducers = (data: IProducersList): IProducer[] => {
    const producers = data.producers
      .filter(
        item =>
          !item?.integrations?.includes('89fa86ca' as ProvidersType) &&
          item.active,
      )
      .map(item => ({ ...item, name: renameProducerForBetshistory[item.name] || item.name }))

    return producers
  }
}

export default GameCatalogConverter
