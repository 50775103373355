export const betHistory: { [key: string]: string } = {
  yourBetSelling: 'Your bet is selling',
  betHistory: 'Bet history',
  infoTitle: 'Select bid about to show detailed information.',
  setBet: 'Select a bet in the list of your bets on the left',
  sold: 'Sold',
  showBets: 'Show bets',
  winned: 'Winnings',
  lossed: 'Losses',
  showSold: 'Sold',
  betSoldSuccess: 'Successful sale',
  freeBetCantSell: 'Free bets cannot be sold',
  yes: 'Yes',
  no: 'No',
  sellBet: 'Sell bet',
  sell: 'Cashout',
  cancel: 'Cancel',
  cantUndone: 'It cannot be undone.',
  betNotSell: 'Bet is not for sale',
  sureToSell: 'Did you really want to cashout this bet',
  for: 'for',
  notCalculated: 'Not calculatated',
  dateFilter: 'Filter by date',
  from: 'From',
  to: 'To',
  show: 'Show',
  date: 'Date',
  bet: 'Bet',
  outcome: 'Outcome',
  amount: 'Amount',
  coef: 'Odd',
  possibleWin: 'Possible win',
  possibilityWin: 'Possibility win',
  coefShort: 'Odd',
  possible: 'Possible',
  resultWin: 'Winned',
  page: 'Page',
  hideFilters: 'Hide filters',
  showFilters: 'Show filters',
  express: 'Express',
  eventsTypeOne: 'Events',
  eventsTypeTwo: 'Events',
  eventsTypeThree: 'Events',
  startMatch: 'Match start',
  score: 'Score',
  totalOdds: 'Total odds',
  type: 'Type',
  costWill: 'The value of your bet will be: ',
  single: 'Single',
  overallCoef: 'Overall coef.',
  status: 'Status',
  of: 'of',
  winnedResult: 'Winned',

  winAmount: 'Win amount',
  details: 'Details',
  view: 'View',
  code: 'Code',
  match: 'Match',
  tourney: 'Tourney',

  //

  filters: 'Filters',
  pleaseAuth: 'Please, auth',
  backToProfile: 'Back to profile',
  oddsType: 'Odds Type',
  markets: 'Markets',
  ok: 'OK',
  all: 'All',
  sftBetStatuses: {
    Wait: 'Wait',
    Lost: 'Lost',
    Return: 'Returned',
    Win: 'Win',
    CashOut: 'Sold',
  },
  close: 'Close',
  confirm: 'Confirm',
  confQuestion: 'Are you sure ?',
  cashoutValue: 'Cashout value',
  cashout: 'Cashout',
  showAll: 'Show all',
  running: 'Running',
  settled: 'Settled',
  playerActivities: 'Player Activities',
  ticketID: 'Ticket ID',
  winning: 'Original winning',
  betslip: 'Bet Slip',
  bets: 'Bets',
  bethistoryDetail: 'Bet History Detail',
  back: 'Back',
  odds: 'Odds',
  stake: 'Stake',
  result: 'Result',
}
