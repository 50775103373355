import { combineReducers } from 'redux'

import { IBetbyState } from '../types'
import communications from './communications'
import data from './data'

export const reducer = combineReducers<IBetbyState>({
  communications,
  data,
})
