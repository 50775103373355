export function getRouteParamsByStrings<Params extends { [K in keyof Params]?: string } = {}>(
  route: string,
  path: string,
): Params | null {
  const splittedRoute = route.split('/').slice(1)

  const splittedPath = path.split('/').slice(1)
  const paramsIndexes = splittedRoute.map((t, i) => (t.indexOf(':') !== -1 ? i : null)).filter(t => t !== null)
  let paramsObject = {}
  if (splittedRoute[0] !== splittedPath[0]) return null
  paramsIndexes.forEach(t => {
    paramsObject = {
      ...paramsObject,
      [splittedRoute[t as number].replace(':', '')]: splittedPath[t as number],
    }
  })
  const cleanedParamsObject = clean(paramsObject)
  return Object.keys(cleanedParamsObject).length ? (paramsObject as Params) : null
}

function clean(obj: Record<string, string>): Record<string, string> {
  Object.entries(obj).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete obj[key]
    }
  })
  return obj
}
