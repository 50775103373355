import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IBetbyState } from '../types'

export default combineReducers<IBetbyState['communications']>({
  getBetbySportToken: makeCommunicationReducer(actions.getBetbySportToken),
})
