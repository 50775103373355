import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IAuthState } from '../types'

const initialState: IAuthState['data'] = {
  user: null,
  isAuth: false,
  session: null,
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.logout.success, state => {
      state.isAuth = false
      state.user = null
      state.session = null
    })
    .addCase(actions.signIn.success, (state, action) => {
      state.user = action.payload.user
      state.session = action.payload.session
      state.isAuth = true
    })
    .addCase(actions.refreshSession.success, (state, action) => {
      if (state.session && action.payload) {
        state.session.access = action.payload.access
      }
    })
    .addCase(actions.getUserData.success, (state, action) => {
      state.user = action.payload
    })
    .addCase(actions.updateUserData.success, (state, action) => {
      state.user = action.payload
    })
})
