export const bonusCounts = [
  { key: 'EUR', count: '500' },
  { key: 'TND', count: '1.500' },
  { key: 'KGS', count: '40.000' },
  { key: 'UZS', count: '5.500.000' },
  { key: 'TJS', count: '5.000' },
  { key: 'UAH', count: '15.000' },
  { key: 'RUB', count: '25.000' },
  { key: 'GBP', count: '400' },
  { key: 'EUR', count: '500' },
  { key: 'USD', count: '750' },
  { key: 'AUD', count: '750' },
]

export const getBonusCountByCurrencies = (currency: string) =>
  bonusCounts.find(v => v.key === currency) || bonusCounts[0]
