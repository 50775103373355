import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_PATHS } from 'router/routes'

export type ILinkSubItem = {
  label: string
  route: string
  modalRoute?: string
}

export type ILinksItem = {
  [key: string]: ILinkSubItem[]
}

const linksItems: ILinksItem = {
  about: [
    {
      label: 'privacyPolicy',
      route: ROUTE_PATHS.TERMSANDCONDITIONS_PRIVACYPOLICY,
    },
    {
      label: 'termsConditions',
      route: ROUTE_PATHS.TERMSANDCONDITIONS_GENERALTERMS,
    },
  ],
  products: [
    {
      label: 'casino',
      route: ROUTE_PATHS.SLOTS_CASINO,
    },
    {
      label: 'liveCasino',
      route: ROUTE_PATHS.LIVECASINO,
    },
    {
      label: 'sport',
      route: '',
      modalRoute: MODAL_ROUTE_URLS.CHOOSE_SPORT('sport')
    },
    {
      label: 'liveSport',
      route: '',
      modalRoute: MODAL_ROUTE_URLS.CHOOSE_SPORT('live')
    },
  ],
}

export { linksItems }
