import uniq from 'lodash.uniq'

import { tabs } from 'modules/SlotsRoot/data/tabs'
import { tabsLive } from 'modules/SlotsRoot/data/tabsLive'

export const excludeSoftwissGameIds: string[] = ['yggdrasil:RiseoftheValkyrieSplitz']

export const excludeSoftwissList: string[] = ['evolution', 'pragmatic', 'pragmaticplay', 'pragmaticplaylive']

export function getTab(slotId: string, slotName: string): string[] {
  let additionalTabs = []
  if (slotId.toLowerCase().indexOf('megaways') !== -1 || slotName.toLowerCase().indexOf('megaways') !== -1) {
    additionalTabs.push('megaways')
  }
  const trueTab = Object.keys(tabs).reduce(
    (acc: string[], tab: string) =>
      tabs[tab].findIndex((item: string) => item === slotId) === -1 ? acc : [...acc, tab],
    [],
  )
  return uniq([...additionalTabs, ...trueTab])
}

export function getTabLive(slotId: string, dropAndWins?: boolean): string[] {
  let additionalTabs: string[] = dropAndWins ? ['Drops & Wins'] : []

  const trueTab = Object.keys(tabsLive).reduce(
    (acc: string[], tab: string) =>
      tabsLive[tab].findIndex((item: string) => item === slotId) === -1 ? acc : [...acc, tab],
    [],
  )

  return trueTab.concat(additionalTabs)
}
