// import BaseApi from 'shared/api/BaseApi'
import { BaseHttpService } from 'services/api/BaseHttpService'
import { QueryTypes } from 'services/api/data'

import { newBCraftServicesUrl } from 'shared/constants'

import {
  IProducer,
  IProducersList,
  IServerFavorite,
  IServerResponse,
  ServerGameType,
} from 'modules/SlotsRoot/types/interfaces/Api'
import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { slotProvidersConfiguration } from './configuration'
import FavoriteConverter from './providersData/Favorite/Favorite'
import GameCatalogConverter from './providersData/GameCatalog/GameCatalogConverter'
import { ProvidersType } from './types/configuration'
import { IFavoriteDTO } from './types/interfaces/Slots/Favorite'
import { IFreeSpinInfoResponse, IFreeSpinInfoView } from './types/interfaces/Slots/Outcome'
import { IGetGameUrl } from './types/slot'

export interface IGetGameList {
  id: string
  provider: string
  extraData?: unknown
  platform?: 'desktop' | 'mobile'
}

export interface IGetExtraData {
  url: string
  converter?: (data: unknown) => unknown
}

export interface IGetSessionUrl {
  provider: ProvidersType
  gameId: string | number
  hasFreeSpins?: boolean
  params: object
  extraData: object
  gameList: ISlotViewItem[]
  demoStart?: string
}

export interface IGetFreeSpinSessionUrl {
  provider: ProvidersType
  gameId: string | number
  hasFreeSpins?: boolean
  params: object
  extraData: object
  gameList: ISlotViewItem[]
  demoStart?: string
}

// interface ISlotApi {
//   getGameList({ provider }: IGetGameList): IServerResponse<ISlotViewItem[]>
//   getExtraData({ url, converter }: IGetExtraData): Promise<IServerResponse<unknown>>
//   getSessionUrl({ provider, gameId, params, extraData, gameList }: IGetSessionUrl): Promise<IServerResponse<string>>
// }

export class SlotsApi extends BaseHttpService {
  private favoriteConverter

  private gamecatalogConverter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = baseUrl
    this.favoriteConverter = new FavoriteConverter()
    this.gamecatalogConverter = new GameCatalogConverter()
  }

  async getGameList({ provider, extraData, platform }: IGetGameList) {
    const { gameListUrl, converter } = slotProvidersConfiguration[provider]

    // if (Array.isArray(gameListUrl)) {
    //   const response = await Promise.all(
    //     gameListUrl.map(url =>
    //       this.sendQuery<ServerGameType, ConvertedGameType>({
    //         type: QueryTypes.GET,
    //         url: `${this.baseUrl}/${url}`,
    //         converterSuccess: data => converter.convertGameList({ data, extraData, platform }),
    //       }),
    //     ),
    //   )
    //   return {
    //     success: true,
    //     data: response.reduce((acc, t: any) => [...acc, ...t.data], [] as ISlotViewItem[]),
    //     errorMessage: '',
    //     codeStatus: '',
    //     responseStatus: true,
    //   }
    // }
    if (gameListUrl.includes('https://')) {
      return this.sendQuery<ServerGameType, ISlotViewItem[]>({
        type: QueryTypes.GET,
        url: `${gameListUrl}`,
        converterSuccess: data => converter.convertGameList({ data, extraData, platform }),
      })
    }
    return this.sendQuery<ServerGameType, ISlotViewItem[]>({
      type: QueryTypes.GET,
      url: `${this.baseUrl}/${gameListUrl}`,
      converterSuccess: data => converter.convertGameList({ data, extraData, platform }),
    })
  }

  async getFavoriteGameList() {
    return this.sendQuery<IFavoriteDTO[], string[]>({
      url: `${this.baseUrl}/api/users/favouritegames/`,
      type: QueryTypes.GET,
      converterSuccess: this.favoriteConverter.convertGameList,
    })
  }

  async getExtraData({ url, converter }: IGetExtraData): Promise<IServerResponse<unknown>> {
    return this.sendQuery({ type: QueryTypes.GET, url: `${this.baseUrl}/${url}`, converterSuccess: converter })
  }

  async getFreeSpinSessionUrl({
    gameId,
    provider,
    params = {},
    extraData = {},
    gameList,
  }: IGetFreeSpinSessionUrl): Promise<IServerResponse<string>> {
    const { sessionUrlData: { freeSpinUrl, getBody, converter } = {} } = slotProvidersConfiguration[provider]
    const body = getBody ? getBody({ gameId, ...params, gameList }) : undefined
    if (freeSpinUrl) {
      return this.sendQuery({
        type: QueryTypes.POST,
        url: `${this.baseUrl}/${freeSpinUrl}`,
        data: body,
        converterSuccess: (data: any) =>
          converter
            ? converter({ data, extraData, gameId, gameList, lang: SlotsApi.lang } as IGetGameUrl<unknown, unknown>)
            : data,
      })
    }
    return new Promise(resolve => {
      resolve({
        success: true,
        data: converter
          ? converter({ data: {}, extraData, gameId, gameList, lang: SlotsApi.lang } as IGetGameUrl<unknown, unknown>)
          : '',
        errorMessage: '',
        codeStatus: '',
      })
    })
  }

  async getSessionUrl({
    provider,
    gameId,
    params = {},
    extraData = {},
    gameList,
    demoStart,
    hasFreeSpins,
  }: IGetSessionUrl): Promise<IServerResponse<string>> {
    const { sessionUrlData: { url, converter, getBody, getQuery, method, demoUrl } = {} } =
      slotProvidersConfiguration[provider]
    const body = getBody ? getBody({ gameId, ...params, gameList }) : undefined
    const urlQuery = getQuery ? getQuery({ gameId, ...params }) : ''
    const activeUrl = demoStart ? demoUrl : url
    if (activeUrl) {
      return this.sendQuery({
        type: method || QueryTypes.POST,
        url: `${this.baseUrl}/${activeUrl}${urlQuery}`,
        data: body,
        // options: { headers }, // TODO
        converterSuccess: (data: any) =>
          converter
            ? converter({ data, extraData, gameId, gameList, lang: SlotsApi.lang } as IGetGameUrl<unknown, unknown>)
            : data,
      })
    }
    return new Promise(resolve => {
      resolve({
        success: true,
        data: converter
          ? converter({ data: {}, extraData, gameId, gameList, lang: SlotsApi.lang } as IGetGameUrl<unknown, unknown>)
          : '',
        errorMessage: '',
        codeStatus: '',
      })
    })
  }

  async getFreeSpinInfo(provider: ProvidersType) {
    const { sessionUrlData: { freeSpinInfoUrl, freeSpinInfoConverter } = {} } = slotProvidersConfiguration[provider]
    return this.sendQuery({
      type: QueryTypes.GET,
      url: `${this.baseUrl}/${freeSpinInfoUrl}`,
      converterSuccess: freeSpinInfoConverter,
    })
  }

  async addSlot(provider: string, gameId: string) {
    return this.sendQuery<IServerFavorite, boolean>({
      type: QueryTypes.POST,
      url: `${this.baseUrl}/api/users/favouritegames/`,
      data: {
        provider,
        game_id: gameId,
      },
    })
  }

  async removeSlot(provider: string, gameId: string) {
    return this.sendQuery<IServerFavorite, boolean>({
      type: QueryTypes.DELETE,
      url: `${this.baseUrl}/api/users/favouritegames/`,
      data: {
        data: {
          provider,
          game_id: gameId,
        },
      },
    })
  }

  async loadProducers() {
    return this.sendQuery<IProducersList, IProducer[]>({
      type: QueryTypes.GET,
      url: `${newBCraftServicesUrl}/services/game-catalog/producer`,
      converterSuccess: this.gamecatalogConverter.convertProducers,
    })
  }
}
