/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { selectIsUserAuthenticated } from 'features/auth/selectors'

import { MODAL_ROUTE_URLS } from '../../../router/modalRoutes'

interface IProtectedRouteProps {
  component: React.FC<any> | React.ComponentType<any>
  [key: string]: any
  forAuth?: boolean
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ forAuth = true, component: Component, ...restOfProps }) => {
  const isAuthenticated = useSelector(selectIsUserAuthenticated)

  const tExp = forAuth ? isAuthenticated : !isAuthenticated
  if (isAuthenticated && !forAuth) return <Redirect to="/main" />
  return <Route {...restOfProps} render={props => (tExp ? <Component {...props} /> : <Redirect to="/main" />)} />
}

export default ProtectedRoute
