import { INotifiesStatePersisted } from 'features/notifies/types'

type RetunMigrationType = (state: INotifiesStatePersisted) => INotifiesStatePersisted

export const notifiesMigration: Record<string, RetunMigrationType> = {
  1: state => ({
    ...state,
    data: {
      ...state.data,
      firstDeposit: {
        item: null,
        isShowed: false,
      },
      cashback: {
        all: {},
        hide: [],
      },
      personalJackpot: {
        all: {},
        hide: [],
      },
    },
  }),
}
