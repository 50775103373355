import { createReducer } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import * as actions from '../actions'
import { IPromocodesState } from '../types'

const initialState: IPromocodesState['data'] = {
  partnerId: '',
}

export default createReducer(initialState, builder => {
  builder.addCase(actions.checkRegistrationValidate.success, (state, action) => {
    state.partnerId = action.payload.partnerId
  })
})
