import expireIn from 'lib/redux-persist-transform'
import { combineReducers } from 'redux'
import { getPersistConfig } from 'redux-deep-persist'
import { MigrationManifest } from 'redux-persist'
import createMigrate from 'redux-persist/es/createMigrate'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

import { loadSliderPeriodMiliseconds } from 'shared/constants/slider'

import { ISliderState } from '../types'
import communications from './communications'
import data from './data'
import { sliderMigration } from './migrations/migrations'

const reducerWithComm = combineReducers<ISliderState>({
  communications,
  data,
})

const expireInValue = loadSliderPeriodMiliseconds
const expirationKey = 'sliderExpirationKeyTransform'

const persistConfig = getPersistConfig({
  key: 'slider',
  storage,
  version: 1,
  // migrate: createMigrate(sliderMigration as unknown as MigrationManifest, {debug: false}),
  blacklist: ['communications'],
  rootReducer: reducerWithComm,
  transforms: [expireIn(expireInValue, expirationKey, [])],
})

export const reducer = persistReducer(persistConfig, reducerWithComm)
