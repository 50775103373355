import QueryTypes from 'services/api/QueryTypes'

import { BaseHttpService } from '../BaseHttpService'
import { BetbyConverter } from './BetbyConverter'

class BetbyApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/integrations/betby`
    this.converter = new BetbyConverter()
  }

  // язык в двух символьном формате
  getToken = (language: string) =>
    this.sendQuery({
      type: QueryTypes.POST,
      url: `${this.baseUrl}/login`,
      converterSuccess: this.converter.convertToken,
      data: { language },
    })
}

export default BetbyApi
