import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { ISettingsState } from '../types'

const initialState: ISettingsState['data'] = {
  settings: {
    rates: [],
    limits: {
      maxBet: null,
      minBet: null,
      maxWin: null,
      maxTransaction: null,
    },
  },
  currencies: [],
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.loadSettings.success, (state, action) => {
      state.settings = action.payload
    })
    .addCase(actions.getCurrenciesList.success, (state, action) => {
      state.currencies = action.payload
    })
})
