import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IDigitainState } from '../types'

const initialState: IDigitainState['data'] = {
  token: '-',
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getSportToken.success, (state, action) => {
      state.token = action.payload
    })
    .addCase(actions.removeSportToken, state => {
      state.token = '-'
    })
})
