export const promocode = {
  inputPromocode: 'Please, enter a promocode',
  promocode: 'Promocode',
  activate: 'Activate',
  promocodes: 'Promocodes',
  success: 'You have successfully activated the promo code',
  notFinded: 'not found',
  backToProfile: 'Back to profile',
  noSuch: 'There is no such promo code',
  info: 'Info',
  activatePromocode: 'Activate promocode',
  id: 'ID',
  site: 'Website',
  description: 'Description',
  code: 'Code',
  generatePromo: 'Generate promo code',
  promocodeValid: 'Promocode is valid',
}
