import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ISession } from 'shared/models/Auth'

import { refreshSession } from 'features/auth/actions'
import { selectIsUserAuthenticated, selectSessionData } from 'features/auth/selectors'

import Spinner from 'components/Spinner'

import { getTimeoutAccessToken } from './utils'

export const SessionPolling: FC = ({ children }) => {
  const dispatch = useDispatch()

  const timeoutId = useRef<NodeJS.Timeout>()

  const currentSession = useSelector(selectSessionData, shallowEqual)

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  const [isSessionPreloading, setIsSessionPreloading] = useState(Boolean(currentSession))

  const handleUpdateTimeoutId = useCallback((session: ISession | null, callback: () => void) => {
    if (!session) {
      setIsSessionPreloading(false)
      return
    }

    const timeoutAccessToken = getTimeoutAccessToken(session)

    if (!timeoutAccessToken.isNeedUpdate || (timeoutAccessToken.isNeedUpdate && timeoutAccessToken.ms > 0)) {
      setIsSessionPreloading(false)
    }

    if (timeoutAccessToken.isNeedUpdate) {
      timeoutId.current = setTimeout(callback, timeoutAccessToken.ms)
    }
  }, [])

  const handleRefreshSession = useCallback(() => {
    dispatch(
      refreshSession(undefined, {
        successCallback: session => {
          setTimeout(() => {
            setIsSessionPreloading(false)
            handleUpdateTimeoutId(session, handleRefreshSession)
          }, 0)
        },
        failureCallback: () => {
          setIsSessionPreloading(false)
        },
      }),
    )
  }, [dispatch, handleUpdateTimeoutId])

  useEffect(() => {
    if (isAuth) {
      handleUpdateTimeoutId(currentSession, handleRefreshSession)
    } else {
      setIsSessionPreloading(false)
    }
  }, [isAuth])

  useEffect(
    () => () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    },
    [],
  )

  useEffect(() => {
    if (timeoutId.current && !isAuth) {
      clearTimeout(timeoutId.current)
    }
  }, [isAuth])

  if (isSessionPreloading) {
    return <Spinner isLoading />
  }

  return <>{children}</>
}
