import React from 'react'

import { MODAL_ROUTE_PATH } from 'router/modalRoutes'

import { ConfirmBirthDate } from 'pages/Auth/ConfirmBirthDate'
import { ResetEmail } from 'pages/Auth/ResetEmail/ResetEmail'
import SignIn from 'pages/Auth/SignIn'
import Registration from 'pages/Registration/Registration'

import { ChooseSport } from 'components/ChooseSport/ChooseSport'

interface IModalRoutesItem {
  id: string
  route: string
  Component: React.FC<any>
  bgColor?: string
  hideCloseBtn?: boolean
  shadow?: boolean
  isFull?: boolean
  bordered?: boolean
  position?: 'default' | 'center'
}

export const modalRoutes: IModalRoutesItem[] = [
  {
    id: 'sign-in',
    route: MODAL_ROUTE_PATH.SIGN_IN,
    Component: SignIn,
    bgColor: '#151515',
    hideCloseBtn: true,
    bordered: true,
    position: 'center',
  },
  {
    id: 'sign-up',
    route: MODAL_ROUTE_PATH.SIGN_UP,
    Component: Registration,
    bgColor: '#343434',
    hideCloseBtn: true,
    shadow: true,
    position: 'center',
  },
  {
    id: 'sign-up/promocode',
    route: MODAL_ROUTE_PATH.SIGN_UP_WITH_PROMOCODE,
    Component: Registration,
    bgColor: '#343434',
    hideCloseBtn: true,
    shadow: true,
    position: 'center',
  },
  {
    id: 'resend-email',
    route: MODAL_ROUTE_PATH.RESEND_EMAIL,
    Component: ResetEmail,
    bgColor: '#343434',
    shadow: true,
    position: 'center',
  },
  {
    id: 'choose-sport',
    route: MODAL_ROUTE_PATH.CHOOSE_SPORT,
    Component: ChooseSport,
    bgColor: '#343434',
    hideCloseBtn: true,
    shadow: true,
    position: 'center',
  },
  {
    id: 'confirm-birthday',
    route: MODAL_ROUTE_PATH.CONFIRM_BIRTHDAY,
    Component: ConfirmBirthDate,
    bgColor: '#151515',
    hideCloseBtn: true,
    position: 'center',
    bordered: true,
    isFull: true,
  },
]
