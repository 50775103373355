import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IDigitainState } from '../types'

export default combineReducers<IDigitainState['communications']>({
  getSportToken: makeCommunicationReducer(actions.getSportToken),
})
