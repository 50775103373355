import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import block from 'bem-cn'

import { ROUTE_URLS } from 'router/routes'

import { ReactComponent as SpinIconSVG } from './img/spin-icon.svg'

import './WheelButton.scss'

const b = block('wheel-button')

const WheelButton: React.FC<{ onCloseProfileMenu?: () => void }> = ({ onCloseProfileMenu }) => {
  const { push } = useHistory()

  const onRouletteIconClick = useCallback(() => {
    push(ROUTE_URLS.WHEEL_GAME())
    if (onCloseProfileMenu) {
      onCloseProfileMenu()
    }
  }, [onCloseProfileMenu, push])

  return <SpinIconSVG onClick={onRouletteIconClick} className={b()} />
}

export default WheelButton
