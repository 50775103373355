import { ISettings, ISettingsDTO } from 'shared/models/Settings'

export class SettingsConverter {
  convertSettings(data: ISettingsDTO): ISettings {
    return {
      rates: data.rates,
      limits: {
        maxBet: (Math.floor(+data.limits.max_bet * 100) / 100),
        minBet: (Math.ceil(+data.limits.min_bet * 100) / 100),
        maxWin: (Math.floor(+data.limits.max_win * 100) / 100),
        maxTransaction: (Math.floor(+data.limits.max_transaction * 100) / 100),
      },
    }
  }
}
