import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTE_PATHS } from 'router/routes'
// import { lazyImportComponent } from 'shared/helpers/lazyImportComponent'

const FaqPage = React.lazy(() => import('pages/FaqPage/FaqPage'))

export class FaqPageModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.HELP_FAQ} path={ROUTE_PATHS.HELP_FAQ}>
        <Switch>
          <Route key={ROUTE_PATHS.HELP_FAQ} path={ROUTE_PATHS.HELP_FAQ} component={FaqPage} />
        </Switch>
      </Route>
    )
  }
}
