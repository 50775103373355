import { IServerEvents } from 'shared/models/Collector'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

export const postCollectorEvents = makeCommunicationActionCreator({
  loading: '@collector/POST_COLLECTOR_EVENTS',
  success: '@collector/POST_COLLECTOR_EVENTS',
  error: '@collector/POST_COLLECTOR_EVENTS',
  reset: '@collector/POST_COLLECTOR_EVENTS',
})<IServerEvents[], unknown>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.collector.postEvents(payload)
    return response.data
  },
)
