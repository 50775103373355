import { SlotsApi } from 'modules/SlotsRoot/api'

import AuthApi from './AuthApi'
import BetbyApi from './BetbyApi/BetbyApi'
import BonusHistoryApi from './BonusHistoryApi/BonusHistoryApi'
import BonusSystemApi from './BonusSystem/BonusSystem'
import CollectorApi from './CollectorApi/CollectorApi'
import DigitainApi from './DigitainApi/DigitainApi'
import JackpotsApi from './JackpotsApi/JackpotsApi'
import PaymentApi from './PaymentApi/PaymentApi'
import ProfileApi from './ProfileApi/ProfileApi'
import PromocodeApi from './PromocodeApi/PromocodeApi'
import RegisterApi from './RegisterApi/RegisterApi'
import SettingsApi from './SettingsApi/SettingsApi'
import SliderApi from './SliderApi/SliderApi'
import WheelGameApi from './WheelGameApi/WheelGameApi'

class Api {
  public auth: AuthApi

  public settings: SettingsApi

  public register: RegisterApi

  public profile: ProfileApi

  public slots: SlotsApi

  public payment: PaymentApi

  public promocode: PromocodeApi

  public bonusHistory: BonusHistoryApi

  public wheelGame: WheelGameApi

  public bonusSystem: BonusSystemApi

  public collector: CollectorApi

  public jackpots: JackpotsApi

  public digitain: DigitainApi

  public slider: SliderApi

  public betby: BetbyApi

  constructor(baseUrl = '') {
    this.auth = new AuthApi(baseUrl)
    this.settings = new SettingsApi(baseUrl)
    this.register = new RegisterApi(baseUrl)
    this.profile = new ProfileApi(baseUrl)
    this.slots = new SlotsApi(baseUrl)
    this.payment = new PaymentApi(baseUrl)
    this.promocode = new PromocodeApi(baseUrl)
    this.bonusHistory = new BonusHistoryApi(baseUrl)
    this.wheelGame = new WheelGameApi(baseUrl)
    this.bonusSystem = new BonusSystemApi(baseUrl)
    this.collector = new CollectorApi(baseUrl)
    this.jackpots = new JackpotsApi(baseUrl)
    this.digitain = new DigitainApi(baseUrl)
    this.slider = new SliderApi(baseUrl)
    this.betby = new BetbyApi(baseUrl)
  }
}

export default Api
