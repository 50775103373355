export const favorites: { [key: string]: string } = {
  myFavorites: 'My favorites',
  removeAll: 'Remove all',
  infoText1: 'At the moment you do not have selected leagues or events in the "My favorites" group. Add a ',
  infoText2: 'event or league to your group by clicking',
  infoText3: 'from the group by clicking',
  infoText4: 'icon.',
  sportBettings: 'Sports betting',
  liveBetting: 'Live betting',
}
