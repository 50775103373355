/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { createTheme, Input as MInput, InputAdornment, ThemeProvider } from '@mui/material'
import block from 'bem-cn'

import { ReactComponent as CloseIcon } from 'shared/img/close-icon.svg'

import { selectLocaleDict } from 'features/locale/selectors'

import { ReactComponent as EyeIcon } from './img/eye-icon.svg'
import { ReactComponent as EyeSlashIcon } from './img/eye-icon-slash.svg'

import './Input.scss'

interface IInputProps {
  label?: string
  placeholder?: string
  value: string | number
  onChange: (e: React.ChangeEvent<{ value: string }>) => void
  name?: string
  isRequired?: boolean
  type?: string
  errors?: any
  error?: string | boolean
  isAllowedToDelete?: boolean
  deleteInputCallback?: (e: any) => void
  rounded?: boolean
  bottomText?: string | boolean
  height?: string
  svg?: { icon: any } | null
  [x: string]: any
}

export const Input: React.FC<IInputProps> = ({
  label,
  placeholder,
  value,
  type = 'text',
  onChange,
  isRequired = false,
  errors,
  error,
  isAllowedToDelete = false,
  deleteInputCallback,
  rounded = true,
  bottomText,
  height,
  svg,
  ...props
}) => {
  const [visible, setVisible] = React.useState(false)

  const b = block('input')

  const theme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
            border: '1px solid #9aafb0',
            borderRadius: rounded ? 24 : 0,
            overflow: 'hidden',
            color: '#000',
            height: height || '5rem',
            textTransform: 'none',
            fontVariant: 'inherit',
            fontStretch: 'inherit',
            fontFamily: 'inherit',
            fontSize: 14,
            lineHeight: 1.43,
            transition: 'all .3s ease',
            '&:hover': {
              borderColor: '#63b75d',
            },
            '&.Mui-focused': {
              borderColor: '#63b75d',
              boxShadow: '0 0 0 3px rgba(99,183,93,.25)',
            },
            '&.Mui-disabled': {
              input: {
                opacity: 0.6,
                cursor: 'not-allowed',
              },
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
    },
  })

  const errorLocale = useSelector(selectLocaleDict, shallowEqual).errorStatusMessage
  const isPasswordInput = type === 'password'
  const inputType = isPasswordInput ? (visible ? 'text' : 'password') : type

  const changeVisiblePassword = () => {
    setVisible(!visible)
  }

  const onChangeCallback = useCallback(
    e => {
      onChange(e)
    },
    [onChange],
  )

  const currErr = useMemo(() => {
    const { name } = props
    return errors?.listOfErrors.find((err: any) => err.field === name)
  }, [errors, props.name, errorLocale])

  return (
    <div className={b('wrapper', { isFlex: !!svg })}>
      {svg && (
        <div className={b('left-icon_wrapper')}>
          <svg.icon className={b('left-icon')} />
        </div>
      )}
      <ThemeProvider theme={theme}>
        <MInput
          fullWidth
          disableUnderline
          type={inputType}
          value={value}
          onChange={onChangeCallback}
          placeholder={placeholder}
          required={isRequired}
          className={b({ password: isPasswordInput }).toString()}
          endAdornment={
            (isPasswordInput && (
              <InputAdornment position="end" onClick={changeVisiblePassword}>
                {visible ? <EyeIcon /> : <EyeSlashIcon />}
              </InputAdornment>
            )) ||
            (isAllowedToDelete && (
              <InputAdornment position="end" onClick={deleteInputCallback}>
                <CloseIcon />
              </InputAdornment>
            ))
          }
          {...props}
        />
        <div className={b('error')}>{error || errorLocale[currErr?.code]}&nbsp;</div>
        <div className={b('bottom-text')}>{bottomText}</div>
      </ThemeProvider>
    </div>
  )
}

/* export const InputPassword: React.FC<IInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  isRequired = false,
  ...props
}) => {
  const [visible, setVisible] = useState(false)

  const handleChange = () => {
    setVisible(!visible)
  }
  return (
    // <InputGroup inside>
    <>
      <Input
        onChange={onChange}
        type={visible ? 'text' : 'password'}
        value={value}
        isRequired={isRequired}
        {...props}
      />
      <InputGroup.Button onClick={handleChange}>{visible ? <EyeIcon /> : <EyeSlashIcon />}</InputGroup.Button>
    </>
    // </InputGroup>
  )
} */
