import React from 'react'
import asyncPoll from 'react-async-poll'
import { connect } from 'react-redux'
import { AppDispatch } from 'index'
import { bindActionCreators } from 'redux'

import { IApplicationState } from 'setup/store'

import { actions as authActions } from 'features/auth'

const BalancePolling = () => null

function mapDispatchToProps(dispatch: AppDispatch) {
  const actions = {
    getUserData: authActions.getUserData,
  }
  return bindActionCreators(actions, dispatch)
}

const mapStateToProps = (state: IApplicationState) => ({
  isAuth: state.auth.data.isAuth,
  logoutIsLoading: state.auth.communications.logout.isLoading,
})

async function onPollInterval(props: any) {
  if (!props.isAuth || props.logoutIsLoading) {
    return props.stopPolling()
  }
  return props.getUserData()
}
export default connect(mapStateToProps, mapDispatchToProps)(asyncPoll(5 * 1000, onPollInterval)(BalancePolling))
