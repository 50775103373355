/* eslint-disable max-len */
import { languagesType } from 'shared/locale'

type avaliableLangsWithNot = languagesType | 'ru-RU' | 'de-de'

const notificationsHtml = (lang: languagesType, count: string) => {
  const notifications: Record<any, string> = {
    'en-US': `You will receive +100% first deposit bonus up to ${count} and every day cashback`,

    'fr-fr': `Vous recevrez un bonus de premier dépôt de +100% jusqu'à ${count} et un cashback quotidien`,

    'ru-RU': `Вы получите +100% бонус на первый депозит до ${count} и ежедневный кэшбэк`,

    'es-es': `Recibirás un bono de primer depósito de +100 % hasta ${count} y reembolsos diarios`,

    'de-de': `Sie erhalten einen Ersteinzahlungsbonus von +100 % bis zu ${count} und jeden Tag Cashback`,
  }
  return notifications[lang]
}

export const createNotificationsMarkup = (lang: languagesType, count: string) => ({
  __html: notificationsHtml(lang, count),
})
