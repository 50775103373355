import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'

import { ISession, ISessionRefreshToken } from 'shared/models/Auth'

export const getIsRefreshTokenExpired = (refreshToken: ISession['refresh']): boolean => {
  try {
    const decodedRefreshToken = jwtDecode<ISessionRefreshToken>(refreshToken)

    const isRefreshTokenExpired = dayjs(decodedRefreshToken.exp * 1000).isBefore(dayjs())

    return isRefreshTokenExpired
  } catch {
    return true
  }
}
