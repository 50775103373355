import { IConvertedValidateCodeResponse } from 'shared/models/Registration'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

export const checkRegistrationValidate = makeCommunicationActionCreator({
  loading: '@promocode/CHECK_REGISTRATION_VALIDATE_LOADING',
  success: '@promocode/CHECK_REGISTRATION_SUCCESS',
  error: '@promocode/CHECK_REGISTRATION_ERROR',
  reset: '@promocode/CHECK_REGISTRATION_RESET',
})<string, IConvertedValidateCodeResponse>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.promocode.checkRegistrationValidate(payload)
    return response.data
  },
)
