/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import TextField from '@mui/material/TextField'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import block from 'bem-cn'
import dayjs from 'dayjs'

import { mediaSize } from 'shared/style/var'

import './DatePicker.scss'

type CustomDatePickerProps = {
  defaultValue: Date
  callback: (date: string) => void
  format?: string
  datePickerStylesOverride?: string
  [x: string]: any
}

const b = block('date-picker')

const DatePicker: React.FC<CustomDatePickerProps> = ({
  defaultValue,
  callback,
  format = 'YYYY-MM-DDTHH:mmZ',
  datePickerStylesOverride,
  ...props
}) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })

  const onChangeDate = (newDate: any) => {
    if (dayjs(newDate).format() !== 'Invalid Date') callback(dayjs(newDate.$d).format(format))
    else callback('')
  }

  return (
    <div className={b()}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {isMobileOrTablet ? (
          <MobileDatePicker
            minDate={dayjs('2017-01-01')}
            value={defaultValue}
            onChange={newValue => onChangeDate(newValue)}
            renderInput={params => <TextField {...params} />}
            className={datePickerStylesOverride}
            inputFormat="DD-MM-YYYY"
            {...props}
          />
        ) : (
          <DesktopDatePicker
            minDate={dayjs('2017-01-01')}
            value={defaultValue}
            onChange={newValue => onChangeDate(newValue)}
            renderInput={params => <TextField {...params} />}
            className={datePickerStylesOverride}
            inputFormat="DD.MM.YYYY"
            {...props}
          />
        )}
      </LocalizationProvider>
    </div>
  )
}

export default DatePicker
