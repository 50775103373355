import { sliderUrl } from 'shared/constants'
import { Carousel, ICarouselDTO, ISlide, ISlideDTO } from 'shared/models/Slider'
import { getProjectSlug } from 'shared/utils/slider'

import { BaseHttpService } from '../BaseHttpService'
import QueryTypes from '../data/QueryTypes'
import { SliderConverter } from './SliderConverter'

class SliderApi extends BaseHttpService {
  private converter

  private contentUrl

  constructor(baseUrl: string) {
    super(baseUrl)
    this.contentUrl = `${sliderUrl}/${getProjectSlug()}`
    this.converter = new SliderConverter()
  }

  async getSlides() {
    return this.sendQuery<ISlideDTO[], ISlide[]>({
      url: `${this.contentUrl}/slide/`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertSlides,
    })
  }

  async getCarousels(slides: ISlide[]) {
    return this.sendQuery<ICarouselDTO[], Carousel>({
      url: `${this.contentUrl}/carousel/`,
      type: QueryTypes.GET,
      converterSuccess: carouselData => this.converter.convertCarousels(carouselData, slides),
    })
  }
}

export default SliderApi
