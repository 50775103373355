import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IPromocodesState } from '../types'

export default combineReducers<IPromocodesState['communications']>({
  checkRegistrationValidate: makeCommunicationReducer(actions.checkRegistrationValidate),
})
