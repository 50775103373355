import React, { useEffect } from 'react'
import { isMacOs } from 'react-device-detect'

export const DeviceDetect: React.FC = () => {
  useEffect(() => {
    const html = document.getElementsByTagName('html')?.[0]
    if (html) {
      if (isMacOs) {
        html.classList.add('html-mac')
      }
    }
  }, [])
  return null
}
