export const lineTimeFilter: { [key: string]: string } = {
  time: 'Time',
  0: 'Long-Term',
  60: '1 hour',
  180: '3 hours',
  360: '6 hours',
  720: '12 hours',
  1440: 'Today',
  1441: '24 hours',
  4320: '3 days',
  today: 'Today',
  halfHour: 'Half hour',
  '1hour': '1 hour',
  '2hours': '2 hours',
  '3hours': '3 hours',
  '4hours': '4 hours',
  '5hours': '5 hours',
}
