import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { AnyAction } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThunkDispatch } from 'redux-thunk'
import { QueryParamProvider } from 'use-query-params'
import * as Sentry from '@sentry/react'

import { configureStore, IApplicationState } from 'setup/store'

import {
  AuthModule,
  BetbySportModule,
  DigitainSportModule,
  FaqPageModule,
  HelpPageModule,
  MainPageModule,
  ProfilePageModule,
  SlotsModule,
  TermsPageModule,
  WheelGamePageModule,
} from 'modules'
import { createRoutes } from 'modules/createRoutes'
import { sentryDSN } from 'shared/constants'

import { SessionPolling } from 'components/SessionPolling'

import App from './App'
import Api from './services/api'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'

import 'react-toastify/dist/ReactToastify.css'
import './index.scss'

Sentry.init({
  dsn: sentryDSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

dayjs.extend(utc)
dayjs.extend(timezone)

const api = new Api('')

const modules = [
  new AuthModule(),
  new ProfilePageModule(),
  new TermsPageModule(),
  new HelpPageModule(),
  new FaqPageModule(),
  new MainPageModule(),
  new SlotsModule(),
  new DigitainSportModule(),
  new BetbySportModule(),
  new WheelGamePageModule(),
]

const childrens = createRoutes(modules)

export const { store, persistor } = configureStore({ api })

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<ThunkDispatch<IApplicationState, any, AnyAction>>()

ReactDOM.render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <SessionPolling>
              <App>{childrens}</App>
            </SessionPolling>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </QueryParamProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)
