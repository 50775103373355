import { createTransform, Transform, TransformInbound, TransformOutbound } from 'redux-persist'

/**
 * `redux-persist` transformer that reset the persisted redux state after a specific period of time.
 * @param {number} expireIn For how long the state is going to be preserved
 * @param {string} [expireKey="persistencyExpiration"] Key used by the localStorage
 * @param {any} defaultValue Value to which state will be cleared to
 */

interface IExpirationValue {
  timestamp: string
  expireInValue: string
}

const transformExpire = (
  expireIn: number,
  expireKey: string = 'persistencyExpiration',
  defaultValue = {},
): Transform<any, any> => {
  const storedExpiration: IExpirationValue = JSON.parse(localStorage.getItem(expireKey) || '{}')
  let expired = false

  if (storedExpiration.timestamp) {
    // eslint-disable-next-line radix
    const expiring = parseInt(storedExpiration.timestamp)
    const now = new Date().getTime()
    expired =
      (!isNaN(expiring) && now > expiring) ||
      storedExpiration === null ||
      now + expireIn < expiring || // если у юзера сменилось время на устройстве назад - считаем его устаревшим
      +storedExpiration.expireInValue !== expireIn
    // если значение, через которое нужно очистить поменялось - считаем устаревшим
  }

  return createTransform(
    (inboundState: TransformInbound<string, string>): TransformOutbound<string, string> => {
      setTimeout((): void => {
        if (expired || !storedExpiration.timestamp) {
          const expireValue = (new Date().getTime() + expireIn).toString()
          const newStoredExpiration: IExpirationValue = {
            timestamp: expireValue,
            expireInValue: `${expireIn}`,
          }
          localStorage.setItem(expireKey, JSON.stringify(newStoredExpiration))
        }
      }, 0)

      return inboundState
    },
    (outboundState: TransformInbound<string, string>): TransformOutbound<string, string> | any =>
      expired ? defaultValue : outboundState,
  )
}

export default transformExpire
