/* eslint-disable max-len */
import { defaultLanguage } from 'shared/locale'

type IFormatCurrencyPayload = {
  amount: number
  currency: string
  showSymbol?: boolean
}

type IFormatBalancePayload = {
  balance: string
  currency: string
}

export const balanceSeparator = ' → '

export const getFormattedCurrency = ({ amount, currency, showSymbol = true }: IFormatCurrencyPayload): string =>
  new Intl.NumberFormat(defaultLanguage, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  })
    .format(amount)
    .replace(showSymbol ? '' : currency, '')

export const getFormattedBalance = ({ balance, currency }: IFormatBalancePayload): any => {
  const balances = balance.split(balanceSeparator)

  const formattedBalances = balances.map((item, index) =>
    getFormattedCurrency({
      amount: +item.replace(currency, ''),
      currency,
      showSymbol: index === 1,
    }),
  )

  return formattedBalances.join(balanceSeparator.trimStart())
}
