import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import block from 'bem-cn'
import { Formik } from 'formik'
import { Persist as FormikPersist } from 'formik-persist'

import { MODAL_ROUTE_PATH, MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_PATHS, ROUTE_URLS } from 'router/routes'

import BonusIcon from 'shared/img/bonus777.png'

import { projectKey, projectStyledName } from 'shared/constants'
import { validateConfirmPassword } from 'shared/helpers/validateConfirmPassword'
import { useDebounce } from 'shared/hooks/useDebounce'
import { transLang } from 'shared/locale'
import { ICommonError } from 'shared/models/AppError'
import { eventTypeEnum, parameterNameEnum } from 'shared/models/Collector'
import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { actions as authActions } from 'features/auth'
import { selectAuthCommunication } from 'features/auth/selectors'
import { postCollectorEvents } from 'features/collector/actions'
import { selectLocaleDict } from 'features/locale/selectors'
import { checkRegistrationValidate } from 'features/promocode/actions'
import { selectCheckPromocodeRegistrationComm, selectPartnerId } from 'features/promocode/selectors'
import { actions as settingsActions } from 'features/settings'
import { selectCurrenciesList } from 'features/settings/selectors'
import { selectLanguage } from 'features/userSettings/selectors'

import {
  StyledAuthForm,
  StyledAuthHeader,
  StyledAuthHeaderLocale,
  StyledAuthInputWrapper,
  StyledAuthModal,
  StyledAuthPaginator,
  StyledAuthPaginatorItem,
  StyledAuthPaginatorLink,
  StyledButtonContainer,
} from 'pages/Auth/styles/AuthStyles'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Loader from 'components/Loader/Loader'
import Button from 'components/UIKit/Button/Button'
import { Checkbox } from 'components/UIKit/Checkbox/Checkbox'
import { Input } from 'components/UIKit/Input/Input'
import { Select } from 'components/UIKit/Select/Select'

import { getBonusCountByCurrencies } from './data'
import { createNotificationsMarkup } from './data/notifications'

import './Registration.scss'

const b = block('registration')

interface ParamsMatch {
  promocode: string
}

interface IRegistrationProps {
  path: string
}

const Registration: React.FC<IRegistrationProps> = ({ path }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { replaceLastModal } = useContext(ModalContext)

  const params = getRouteParamsByStrings<ParamsMatch>(MODAL_ROUTE_PATH.SIGN_UP_WITH_PROMOCODE, path)

  const isEventSent = useRef(false)

  const locale = useSelector(selectLocaleDict, shallowEqual).auth
  const localePromocode = useSelector(selectLocaleDict, shallowEqual).promocode
  const errorLocale = useSelector(selectLocaleDict, shallowEqual).errorStatusMessage
  const checkPromocodeComm = useSelector(selectCheckPromocodeRegistrationComm, shallowEqual)
  const partnerId = useSelector(selectPartnerId, shallowEqual)
  const currenciesList = useSelector(selectCurrenciesList, shallowEqual)
  const currenciesListSelectData = currenciesList.map(t => ({
    value: t,
  }))
  const { isLoading } = useSelector(selectAuthCommunication, shallowEqual).signUpWithCode
  const lang = useSelector(selectLanguage, shallowEqual)

  const [isActivePromocode, setIsActivePromocode] = useState(params !== null)

  const [state, setState] = useState({
    username: '',
    password: '',
    email: '',
    // repeatPassword: '',
    condition: true,
    bonus: true,
    promocode: params !== null ? params.promocode : '',
    currency: '',
  })

  const debouncedPromocode = useDebounce(state.promocode, 1200)

  const onPromocodeChange = useCallback((value: string) => {
    setState(prevState => ({ ...prevState, promocode: value }))
  }, [])

  const promocodeErrors = React.useMemo(() => {
    if (!state.promocode) return ''
    if (checkPromocodeComm.error !== undefined && !checkPromocodeComm.isLoading) {
      const errorCheckPromocode = checkPromocodeComm.error as unknown as ICommonError
      return errorLocale[errorCheckPromocode.code]
    }
    if (checkPromocodeComm.isLoading) return `${locale.checking}..`
    return ''
  }, [checkPromocodeComm.isLoading, errorLocale, state.promocode, checkPromocodeComm.error, locale])

  const onSubmit = useCallback(
    (values: typeof state) => {
      dispatch(
        authActions.signUpWithCode(
          {
            username: values.email,
            password1: values.password,
            password2: values.password,
            referral_code: (isActivePromocode && state.promocode) || undefined,
            email: values.email,
            currency: values.currency,
            language: transLang[lang],
          },
          { callback: () => history.replace(ROUTE_PATHS.MAIN) },
        ),
      )
    },
    [dispatch, lang, state, isActivePromocode],
  )

  const changeIsActivePromocode = useCallback(() => {
    setIsActivePromocode(!isActivePromocode)
  }, [setIsActivePromocode, isActivePromocode])

  useEffect(() => {
    if (debouncedPromocode) {
      dispatch(checkRegistrationValidate(state.promocode))
    }
  }, [debouncedPromocode])

  useEffect(() => {
    if (params?.promocode && checkPromocodeComm.isSuccess && partnerId && !isEventSent.current) {
      dispatch(
        postCollectorEvents([
          {
            event_type: eventTypeEnum.REF_LINK,
            project_key: projectKey,
            parameters: [
              { parameter_name: parameterNameEnum.URL, parameter_value: location.href },
              { parameter_name: parameterNameEnum.CODE, parameter_value: params.promocode },
            ],
            // user_id: '',
            // session_id: '',
            // event_id: '',
            partner_id: partnerId,
            // timestamp: dayjs(Date.now()).format('YYYY-MM-DDTHH:mm:ssZ'),
          },
        ]),
      )
      isEventSent.current = true
    }
  }, [partnerId, params, checkPromocodeComm, dispatch])

  useEffect(() => {
    if (!state.currency && currenciesListSelectData.length) {
      setState({ ...state, currency: currenciesListSelectData[0]?.value })
    }
  }, [currenciesListSelectData])

  useEffect(() => {
    dispatch(settingsActions.getCurrenciesList())
  }, [])

  return (
    <StyledAuthModal>
      <div className={b()}>
        <Formik
          key={state.currency} // нужен чтобы работал ререндер когда приходит список валют + работал persist
          initialValues={{
            username: state.username,
            password: state.password,
            // repeatPassword: state.repeatPassword,
            bonus: state.bonus,
            email: state.email,
            condition: state.condition,
            promocode: state.promocode,
            currency: state.currency,
          }}
          validate={values => {
            const errors: Record<string, string> = {}
            // if (validateConfirmPassword(values.password, values.repeatPassword)) {
            //   errors.repeatPassword = locale.passwordsNotMatched
            // }
            if (!values.condition) errors.condition = locale.requiredToAccept
            if (!values.password.match('[^ ]{8,}$')) {
              errors.password = `${locale.minSymbols}: 8`
            }
            return errors
          }}
          onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
            const { key: bonusCurrency, count: bonusCount } = getBonusCountByCurrencies(values.currency)
            return (
              <StyledAuthForm onSubmit={handleSubmit}>
                <StyledAuthHeader>
                  {projectStyledName}
                  <StyledAuthHeaderLocale>{locale.registration}</StyledAuthHeaderLocale>
                </StyledAuthHeader>
                <div className={b('description')}>{locale.twoClicksRegister}</div>
                {/* <StyledAuthInputWrapper>
                <Input
                  type="text"
                  name="username"
                  placeholder={`${locale.username} *`}
                  value={values.username}
                  onChange={handleChange}
                  isRequired
                  onBlur={handleBlur}
                />
              </StyledAuthInputWrapper> */}
                {values.promocode && checkPromocodeComm.isSuccess ? (
                  <div className={b('bonus-container')}>
                    <img src={BonusIcon} alt="bonus" />
                    <div
                      dangerouslySetInnerHTML={createNotificationsMarkup(lang, `${bonusCount} ${bonusCurrency}`)}
                      className={b('description')}
                    />
                  </div>
                ) : (
                  ''
                )}
                <StyledAuthInputWrapper>
                  <Input
                    type="email"
                    name="email"
                    placeholder={`${locale.email} *`}
                    value={values.email}
                    onChange={handleChange}
                    isRequired
                    onBlur={handleBlur}
                  />
                </StyledAuthInputWrapper>
                <StyledAuthInputWrapper>
                  <Input
                    name="password"
                    type="password"
                    placeholder={`${locale.password} *`}
                    value={values.password}
                    isRequired
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password && touched.password && errors.password}
                  />
                </StyledAuthInputWrapper>
                {/* <StyledAuthInputWrapper>
                <Input
                  name="repeatPassword"
                  type="password"
                  placeholder={`${locale.repeatPassword} *`}
                  value={values.repeatPassword}
                  isRequired
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.repeatPassword && touched.repeatPassword && errors.repeatPassword}
                />
              </StyledAuthInputWrapper> */}
                <StyledAuthInputWrapper>
                  {!currenciesListSelectData.length ? (
                    <Loader />
                  ) : (
                    <Select
                      data={currenciesListSelectData}
                      onSelect={handleChange}
                      valueKey="value"
                      labelKey="value"
                      value={values.currency}
                      defaultValue={currenciesListSelectData.find(t => t.value === values.currency)?.value || ''}
                      isDefault
                      name="currency"
                    />
                  )}
                </StyledAuthInputWrapper>
                <br />
                <div className={b('checkbox-wrapper', { promocode: true })}>
                  <Checkbox
                    disabled={params !== null}
                    type="checkbox"
                    name="isActivePromocode"
                    value={!isActivePromocode ? locale.doYouHaveBonusCode : ''}
                    checkedValue={isActivePromocode}
                    onChange={() => {
                      changeIsActivePromocode()
                      handleChange({ target: { name: 'promocode', value: '' } })
                    }}
                  />
                  {isActivePromocode && (
                    <Input
                      name="promocode"
                      type="text"
                      disabled={params !== null}
                      placeholder={`${locale.bonusCode} *`}
                      value={params?.promocode || values.promocode || ''}
                      onChange={e => {
                        onPromocodeChange(e.currentTarget.value)
                        handleChange(e)
                      }}
                      isAllowedToDelete={!params}
                      deleteInputCallback={() => {
                        changeIsActivePromocode()
                        handleChange({ target: { name: 'promocode', value: '' } })
                      }}
                      onBlur={handleBlur}
                      error={promocodeErrors}
                      // bottomText={

                      // }
                    />
                  )}
                </div>
                <div className={b('checkbox-wrapper')}>
                  <Checkbox
                    type="checkbox"
                    value={locale.accept}
                    name="condition"
                    checkedValue={values.condition}
                    onChange={handleChange}
                  />
                  <Link to={ROUTE_URLS.TERMSANDCONDITIONS_GENERALTERMS()} className={b('term')}>
                    <u>{locale.acceptConditions}</u>
                  </Link>
                  <div className={b('error')}>{errors.condition}</div>
                </div>
                <div className={b('checkbox-wrapper')}>
                  <Checkbox
                    type="checkbox"
                    name="bonus"
                    value={locale.getBonuse}
                    checkedValue={values.bonus}
                    onChange={handleChange}
                  />
                </div>
                <StyledButtonContainer className={b('button-container')}>
                  <Button
                    type="submit"
                    colorType="main"
                    text={locale.register}
                    disabled={isLoading}
                    loading={isLoading}
                  />
                </StyledButtonContainer>
                {state.currency && <FormikPersist name="signup-form-2" />}
              </StyledAuthForm>
            )
          }}
        </Formik>
        <StyledAuthPaginator>
          <StyledAuthPaginatorItem>
            {locale.signInDescription}
            <StyledAuthPaginatorLink onClick={replaceLastModal(MODAL_ROUTE_URLS.SIGN_IN())}>
              {locale.signInLink}
            </StyledAuthPaginatorLink>
          </StyledAuthPaginatorItem>
          <StyledAuthPaginatorItem>
            {locale.resendEmailRegistration}
            <StyledAuthPaginatorLink onClick={replaceLastModal(MODAL_ROUTE_URLS.RESEND_EMAIL())}>
              {locale.resendEmail}
            </StyledAuthPaginatorLink>
          </StyledAuthPaginatorItem>
        </StyledAuthPaginator>
      </div>
    </StyledAuthModal>
  )
}

export default Registration
