import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useLocation } from 'react-router-dom'
import block from 'bem-cn'

import { mediaSize } from 'shared/style/var'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { addNotify } from 'components/Notify'
import { listMenu } from 'components/SideMenu/data'

import './NavMenuMobile.scss'

const b = block('nav-menu-mobile')

const NavMenuMobile: React.FC = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { pushNewModal } = useModalContext()

  const locale = useSelector(selectLocaleDict, shallowEqual).home
  const localeSlots = useSelector(selectLocaleDict, shallowEqual).slots
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })

  const navItems = useMemo(
    () =>
      listMenu
        .filter(item => !item.skipForModalNav)
        .map(item => {
          const isActive =
            `${pathname}${search}`.indexOf(item.route || (item.mobileRoute as string)) !== -1 ||
            (item.refRoutes && item.refRoutes.includes(`${pathname}${search}`))

          const pushItemPage = () => {
            if (item.authRequired && !isAuth) {
              addNotify(localeSlots.pleaseAuth)
              return
            }

            if (item.isModal) {
              pushNewModal(item.route)()
              return
            }

            history.push(isMobileOrTablet && item.mobileRoute ? (item.mobileRoute as string) : item.route)
          }

          return (
            <li
              key={item.title}
              onClick={pushItemPage}
              className={b('item', { isActive })}>
              {item.icon && <item.icon className={b('item-icon')} />}
              <span className={b('item-title')}>{locale[item.title] || item.title}</span>
            </li>
          )
        }),
    [locale, history, pathname, isAuth, isMobileOrTablet, localeSlots, search, pushNewModal],
  )

  return (
    <div className={b('wrapper')}>
      <ul className={b()}>{navItems}</ul>
    </div>
  )
}

export default NavMenuMobile
