import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidv4 } from 'uuid'

import { mediaSize } from 'shared/style/var'
const className = 'html-overflow-hidden'

window.overflowModeCount = []

/**
 * @file Info.
 * @author Dmitry
 *
 * Бывает такое, что нужно открыть выезжающее sidemenu, футер и т.д внутри которых контент со скроллом,
 * При открытии можно было скроллить основной контент, скроллив по открытому меню.
 *
 * Добавляет/удаляет html'y класс.

 * Хук от 2-ых скроллов
 * Как работает и пример: Вызывем хук, допустим в футере, передаем условие useHideOverFlow(isOpen || др.условия)
 * При первом рендере в стейт записывается instanceId и, если условие, которое передали в хук = true,
 * в массиве window.overFlowModeCount записывается instanceId и добавляется класс html'y.
 *
 * Может быть такое что мы переходим из открытого футера к примеру на спорт и там нужен overflow:hidden
 * даже после закрытия футера, поэтому считается длина массива и если он пустой, то класс удаляется,
 * если не пустой класс добавляется
 *
 *
 */

const useHideOverflow = (needOverflow = true) => {
  const [instanceID] = useState<string>(uuidv4())

  const isMobileOfTablet = useMediaQuery({ maxWidth: mediaSize.tablet })

  useEffect(() => {
    if (window.overflowModeCount && isMobileOfTablet) {
      if (needOverflow) {
        document.documentElement.classList.add(className)
        window.overflowModeCount = [...window.overflowModeCount, instanceID]
      } else {
        window.overflowModeCount = [...window.overflowModeCount.filter(t => t !== instanceID)]
        if (!window.overflowModeCount.length) {
          document.documentElement.classList.remove(className)
        }
      }
    }
    return () => {
      window.overflowModeCount = [...window.overflowModeCount.filter(t => t !== instanceID)]
      if (!window.overflowModeCount.length) {
        document.documentElement.classList.remove(className)
      }
    }
  }, [needOverflow, isMobileOfTablet])
}

export default useHideOverflow
