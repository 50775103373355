import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { usePoll } from 'shared/hooks/usePoll'

import { loadCarousels } from 'features/slider/actions'

const CarouselSliderPolling: React.FC = () => {
  const dispatch = useDispatch()

  const handleLoadCarousels = useCallback(async () => {
    dispatch(loadCarousels())
  }, [dispatch])

  usePoll(handleLoadCarousels, { delay: 3 * 60 })

  return null
}

export default CarouselSliderPolling
