import { useCallback, useEffect, useMemo, useRef } from 'react'

type LoadFunc = () => Promise<void>
type Options =
  | {
      delay: number
    }
  | undefined

export const usePoll = (loaderFunc: LoadFunc, options: Options = { delay: 20 }, ...pollDependencies: any) => {
  const pollDependency = useMemo(() => {
    if (pollDependencies) {
      return pollDependencies
    }

    return pollDependencies
  }, [pollDependencies])

  // eslint-disable-next-line no-undef
  const currentTimeoutId = useRef<NodeJS.Timeout>()
  // eslint-disable-next-line no-undef
  const previousTimeoutId = useRef<number>()

  const getTimedOutFunc = useMemo(
    () => () => {
      currentTimeoutId.current = setTimeout(() => {
        previousTimeoutId.current = Number(currentTimeoutId.current)
        loaderFunc().then(() => {
          if (previousTimeoutId.current === currentTimeoutId.current) getTimedOutFunc()
        })
      }, options.delay * 1000)
    },
    [loaderFunc, options],
  )

  const getLimitsFunc = useCallback(() => {
    getTimedOutFunc()
    loaderFunc()
  }, [getTimedOutFunc])

  useEffect(() => {
    if (currentTimeoutId.current) {
      clearTimeout(currentTimeoutId.current)
      previousTimeoutId.current = 0
    }

    getLimitsFunc()

    return () => {
      if (currentTimeoutId.current) {
        clearTimeout(currentTimeoutId.current)
        previousTimeoutId.current = 0
      }
    }
  }, [...pollDependency])
}
