export const producersSortArray = ['amatic', 'evolution', 'pragmaticplaylive', 'pragmaticplay']

export const excludeProducersArray = []

export const intergrationsExcludeArray = []

export const renameProducerForBetshistory: Record<string, string> = {
  evosw: 'evolution',
}

export const changeProviderBeforeSend = (provider: string) => {
  switch (provider) {
    case 'all':
      return undefined
    default:
      return provider
  }
}
