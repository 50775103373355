import { IFreeSpinInfoResponse, IFreeSpinInfoView } from './interfaces/Slots/Outcome';
import { ISlotViewItem } from './interfaces/Slots/Slots'

export interface IExtraData {
  url: string
  authRequired?: boolean
  converter?: (data: any) => any
}

export interface ISessionUrlData {
  url: string
  method?: string
  freeSpinUrl?: string
  freeSpinInfoUrl?: string
  freeSpinInfoConverter?: (data: IFreeSpinInfoResponse) => IFreeSpinInfoView,
  demoUrl?: string
  getQuery?: (params: any) => string
  converter?: (data: any) => string
  getBody?: (params: any) => object
  getHeaders?: (params: any) => object
}

export interface ISlotProviderConfigurationItem<CT> {
  multiProvider?: boolean
  liveGamesProviders?: string[]
  uniqueField?: boolean
  platform?: string[]
  gameListUrl: string | string[]
  extraData?: IExtraData[]
  defaultList?: ISlotViewItem[]
  converter: CT
  withoutLoading?: boolean
  sessionUrlData?: ISessionUrlData
  authRequired?: boolean
  slotsCount?: number
  withoutActionProcessing?: boolean
}

export const Providers = {
  otc: 'otc',
  // softwiss: 'softwiss',
  // '028142ca': '028142ca',
  '08f21347': '08f21347',
} as const

export type ProvidersType = keyof typeof Providers

export type ISlotProvidersConfiguration<CT> = {
  [key: string]: ISlotProviderConfigurationItem<CT>
}
