import { IUserSignUp, IUserSignUpDTO } from 'shared/models/Registration'

export class RegisterConverter {
  convertSignUpWithCode(data: IUserSignUpDTO): IUserSignUp {
    return data
  }

  convertSignUpWithoutCode(data: IUserSignUpDTO): IUserSignUp {
    return data
  }
}
