import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { usePoll } from 'shared/hooks/usePoll'

import { getFirstDeposit } from 'features/notifies/actions'

const FirstDepositPolling: React.FC = () => {
  const dispatch = useDispatch()

  const handleLoadFirstDeposit = useCallback(async () => {
    dispatch(getFirstDeposit())
  }, [dispatch])

  usePoll(handleLoadFirstDeposit, { delay: 15 })

  return null
}

export default FirstDepositPolling
