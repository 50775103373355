import { SliderPlatforms } from 'shared/models/Slider'

export const getProjectSlug = () => {
  const hostName = window.location.hostname === 'localhost' ? 'hoyganas.com' : window.location.hostname

  const hostNameSplitted = hostName.split('.')
  const pureHostName = hostNameSplitted.length > 2 ? hostNameSplitted.slice(-2).join('') : hostNameSplitted.join('')

  return pureHostName
}

export const getCarouselParameters = (carouselSlug: string): { language: string; platform: SliderPlatforms } => {
  const carouselSlugSplited = carouselSlug.split('^')

  return {
    language: carouselSlugSplited.find(item => item.startsWith('lg:'))?.replace('lg:', '') || '',
    platform:
      (carouselSlugSplited.find(item => item.startsWith('pt:'))?.replace('pt:', '') as SliderPlatforms) ||
      SliderPlatforms.NOT_FOUND,
  }
}
