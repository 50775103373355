import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { ISliderState } from '../types'

const initialState: ISliderState['data'] = {
  carousels: {},
  lastLoadTime: null,
}

export default createReducer(initialState, builder => {
  builder.addCase(actions.loadCarousels.success, (state, action) => {
    state.carousels = action.payload
  })
  builder.addCase(actions.checkSlider, (state, action) => {
    state.lastLoadTime = action.payload
  })
})
