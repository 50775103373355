import { auth } from './auth'
import { basket } from './basket'
import { betHistory } from './betHistory'
import { bonuses } from './bonuses'
import { common } from './common'
import { errorStatusMessage } from './errorStatusMessage'
import { favorites } from './favorites'
import { fullEvent } from './fullEvent'
import { home } from './home'
import { jackpots } from './jackpots'
import { line } from './line'
import { lineTimeFilter } from './lineTimeFilter'
import { live } from './live'
import { mainPageDescription } from './mainPageDescription'
import { payment } from './payment'
import { profile } from './profile'
import { promocode } from './promocode'
import { promotionsPage } from './promotionsPage'
import { slots } from './slots'
import { spinner } from './spinner'
import { wheelGame } from './wheelGame'

export type localeAuthType = typeof auth
export type localeErrorStatusMessageType = typeof errorStatusMessage
export type localeBetHistoryType = typeof betHistory
export type localeProfileType = typeof profile
export type localeWheelGameType = typeof wheelGame
export type localeHomeType = typeof home

export type localeAuthKeysType = keyof typeof auth
export type localeErrorStatusMessageKeysType = keyof typeof errorStatusMessage
export type localeBetHistoryKeysType = keyof typeof betHistory
export type localeProfileKeysType = keyof typeof profile
export type localeWheelGameKeysType = keyof typeof wheelGame
export type localeHomeKeysType = keyof typeof home

export const locale = {
  auth,
  common,
  line,
  live,
  profile,
  payment,
  betHistory,
  basket,
  slots,
  promocode,
  errorStatusMessage,
  bonuses,
  lineTimeFilter,
  fullEvent,
  spinner,
  home,
  favorites,
  promotionsPage,
  mainPageDescription,
  wheelGame,
  jackpots,
}

export type Locale = typeof locale
