import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { INotifiesState } from '../types'

const initialState: INotifiesState['data'] = {
  firstDeposit: {
    item: null,
    isShowed: false,
  },
  cashback: {
    all: {},
    hide: [],
  },
  personalJackpot: {
    all: {},
    hide: [],
  },
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getFirstDeposit.success, (state, action) => {
      state.firstDeposit.item = action.payload.history.length ? action.payload.history[0] : null
    })
    .addCase(actions.hideFirstDepositNotify, state => {
      state.firstDeposit.isShowed = true
    })
    .addCase(actions.getCashbackList.success, (state, action) => {
      state.cashback.all = Object.fromEntries(action.payload.history.map(t => [t.id, { ...t }]))
    })
    .addCase(actions.hideCashbackNotify, (state, action) => {
      state.cashback.hide?.push(action.payload)
    })
    .addCase(actions.getPersonalJackpots.success, (state, action) => {
      state.personalJackpot.all = Object.fromEntries(action.payload.history.map(t => [t.id, { ...t }]))
    })
    .addCase(actions.hidePersonalJackpotNotify, (state, action) => {
      state.personalJackpot.hide = [...state.personalJackpot.hide, action.payload]
    })
})
