import { combineReducers } from 'redux'

import { IBonusSystemState } from '../types'
import communications from './communications'
import data from './data'

export const reducer = combineReducers<IBonusSystemState>({
  communications,
  data,
})
