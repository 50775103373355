import { projectStyledName } from '../../constants'

/* eslint-disable max-len */
export const profile: { [key: string]: any } = {
  betHistory1: 'Bet history 1',
  betHistory2: 'Bet history 2',

  promocode: 'Promocode',

  basicInformation: 'Basic information',
  email: 'Email address',
  login: 'Login',
  name: 'Name',
  dateOfRegistration: 'Date of registration',
  empty: 'empty *',
  required: 'required *',
  invalidEmail: 'invalid email *',
  m: 'Male',
  f: 'Female',
  countryLabel: 'Country (two-letter format)',

  replenishment: 'Replenishment',
  backToProfile: 'Back to profile',
  myBonus: 'My Bonus',
  withdraw: 'Withdraw',
  transactions: 'Transactions',
  kyc: 'Account Verification',
  limits: 'Limits',
  selfExclusion: 'Close an account',
  userInfo: 'Personal data',

  lk: 'Your account',
  accountNum: 'Account number',
  gender: 'Gender',
  balance: 'Balance',
  bonusBalance: 'Bonus balance',
  fullName: 'Full name',
  currency: 'Currency',
  betslip: 'Betslip',
  bonusHistory: 'Bonus history',
  cashbackHistory: 'Cashback history',
  additionalInfo: 'Additional info',
  yandex: 'Yandex (account number)',
  card: 'Card VISA/MASTER CARD (account number)',
  phone: 'Phone number',
  numberDocument: 'Document number',
  address: 'Registration Address',
  save: 'Save',
  cancel: 'Cancel',
  infoWasUpdated: 'Information successfully updated',
  changePassword: 'Password change',
  changePasswordAdditional: 'Change your account password',
  enterOldPassword: 'Current password',
  enterNewPassword: 'New password',
  repeatNewPassword: 'Repeat new password',
  topUp: 'TopUp account',
  withdrawalMoney: 'Withdrawal money',
  withdrawal: 'Withdrawal money',
  payHistory: 'Operations history',
  betHistory: 'Bet history',
  slotsHistory: 'Slots history',
  promotionalCodes: 'Activate promocode',
  logout: 'Logout',
  changedPassword: 'The password change is successful',
  changedPasswordDoNotMatch: 'Passwords do not match',
  promotionalCodesAdditional: 'Enter a promotional code to receive bonuses',
  betHistoryAdditional: 'All your recent bets',
  payHistoryAdditional: 'All operations that affected the change in balance',
  additionalInfoAdditional: 'Ability to add name, date of birth and phone number',
  ourCashiersAdditional: 'Addresses of our cash desks',
  ourCashiers: 'Our box office',
  country: 'Country',
  seriesDocument: 'A series of document',
  documentNumber: 'Document number',
  exit: 'Go out',
  exitAdditional: 'Log Out',
  registrationAddress: 'Registration address',
  firstName: 'First name',
  lastName: 'Last name',
  middleName: 'Middle name',
  birthDate: 'Birth date',
  city: 'City',
  phoneInfo: 'After the phone has been accepted, your login should not be registered earlier on the site.',
  dataIsNotEditable: 'Editing registration data is not available. Contact tech support.',
  personal: 'Personal',
  favoriteLinks: 'Favorite links',
  accountActivity: 'Account activity',
  personalDetails: 'Personal details',
  personalInformation: 'Personal information',
  currentPassword: 'Current Password',
  newPassword: 'New password',
  newPasswordConfirmation: 'New password(Confirm)',
  payment: 'Payments',
  contactDetails: 'Contact details',
  accountSettings: 'User account settings',
  playersActivities: 'Player Activities',
  payments: 'Payments',
  bookmakersSettings: 'Bookmakers Settings',
  username: 'Username',
  edit: 'Edit',
  reqForPasswordTitle: 'Requirements for valid password',
  reqForPassword1: 'must consist of at least 5, at most 40 characters',
  reqForPassword2: 'allowed special characters: !#$()@?{}|*+,^.-+&=%_:;~',
  reqForPassword3: 'cannot contain spaces',
  reqForPassword4: 'cannot be similar to or contain other personal data (e.g. first name, last name, username, etc.)',
  tips: 'Tips',
  tips1: 'longer and more complex passwords are securer',
  tips2: 'select password you do not use to log in to other online accounts',
  tips3: 'do not share password with others',
  passwordStrength: 'Password strength',
  title: 'Title',
  dateOfBirth: 'Date of birth (MM-DD-YYYY)',
  street: 'Street',
  houseNumber: 'House number',
  zipCode: 'ZIP code',
  townCity: 'Town/City',
  contactLanguage: 'Contact language',
  mobilePhone: 'Mobile phone number',
  userData: 'User data',
  myBets: 'My bets',
  fundsTransfer: 'Funds transfer',
  informationForPassword: 'Information for password',
  questions: 'Questions',
  openGamingAccountTextOne: `How to open a ${projectStyledName} gaming account? How to make a deposit or withdraw funds?
  In this section you will find answers to these and other frequently asked questions.`,
  openGamingAccountTextTwo: `However, if you haven't found the answer to your question, our support and
  customer service team will be happy to help!`,
  gameProfile: 'Gaming profile',
  responsibleGame: 'Responsible gaming',
  depositAndWithdrawal: 'Deposit & Withdrawal',
  callUs: 'Call us',
  contactUs: 'Contact us via Telegram',
  sendEmail: 'Send us an email',
  contactUsChat: 'Contact us via Whatsapp',
  cantFind: "Can't find what you are looking for?",

  termsConditionsContent: {
    generalTerms: {
      label: 'General Terms',
    },
    eligibilityUse: {
      label: `Eligibility to use ${projectStyledName} Services`,
    },
    calculationWinning: {
      label: 'Winnings and Calculation of Winnings',
    },
    userAccount: {
      label: 'User Account',
    },
    customerObligations: {
      label: 'The Customer’s obligations',
    },
    responsibleGaming: {
      label: 'Responsible Gaming',
    },
    casinoBets: {
      label: 'Casino Bets',
    },
    sportsBets: {
      label: 'Sports Bets',
    },
    bettingSoftware: {
      label: 'Betting Software Error',
    },
    privacyPolicy: {
      label: 'Privacy Policy',
    },
    settingLimit: {
      label: 'Setting up Limits',
    },
    complaints: {
      label: 'Complaints',
    },
    softwareProtection: {
      label: 'Software Protection',
    },
    limitationLiability: {
      label: 'Limitation of Liability',
    },
    breachesPenalties: {
      label: 'Breaches, Penalties and Termination',
    },
    severability: {
      label: 'Severability',
    },
    assignment: {
      label: 'Assignment',
    },
    problemGambiling: {
      label: 'Problem Gambling',
    },
  },

  example: 'Example',
  faq: {
    gameProfile: {
      item1: {
        heading: "Can't sign in?",
        text1: 'Your gaming profile may be blocked because The wrong password has been entered multiple times.',
        text2:
          "Don't worry, the account has been blocked for security purposes. Contact our support center using the Live Chat option or email us",
        text3:
          'Your profile may be blocked because the closing period you requested has not yet ended. In this case, our support center will not be able to restore your game profile and you will need to wait until the set closing time expires.',
      },
      item2: {
        heading: 'Need a break or want to close your gaming profile?',
        text1: "We're sorry you want to close your gaming profile, but we respect your choice.",
        text2: 'Contact our support center so that we can offer you a suitable option to close your profile.',
        text3: "Don't forget to enter your details: ID and username.",
      },
      item3: {
        heading: 'Why were you asked for documents?',
        text1:
          'For security reasons and based on legal requirements, we may ask you to send us documents to verify your gaming profile. This is a standard procedure during which we may request: a photo of an identity card (ID card or passport), bank account confirmation (payment order from your bank), a photo of a payment / credit card.',
        text2: 'You will receive an e-mail notification from us about which documents you need to provide.',
      },
    },
    paymentProfile: {
      item1: {
        heading: 'Deposit',
        text1: 'We offer fast deposit methods:',
        li1: 'Deposit by bank card (Visa/MasterCard). Minimum deposit amount 5 EUR',
        li2: 'Deposit through available cryptocurrencies',
        text2:
          'If your deposit has not been added to your gaming profile, feel free to contact the support center and prepare a payment order in PDF format or a screenshot with all the details of the payment.',
        text3: 'Important! Deposits can only be made from your credit cards and bank accounts registered in your name.',
      },
      item2: {
        heading: 'Withdrawal',
        text1:
          'Payment can be requested to the card number, which is indicated in the withdrawal request form. Or to the wallet of one of the cryptocurrencies available for withdrawal. Funds will be lost if the wrong wallet is specified.',
        text2: 'Payments are confirmed from our side within the next hour after your request.',
        text3:
          'Bank funds are received within 5 minutes after confirmation from our side. Due to various circumstances, the payment may be delayed.',
        text4: 'The minimum amount you can request is 10 EUR.',
        // text5: 'If you have any questions, please contact us via email support@hoyganas.com',
        text5: '',
      },
      item3: {
        heading: 'Why was your payout cancelled?',
        text1: 'It may take up to 24 hours for us to confirm the payment.',
        text2:
          'We may contact you by e-mail and request some documents to verify your gaming profile. If you have been asked for documents, you will need to wait while we check them and confirm the requested payment. This procedure may take up to 24 hours.',
        text3:
          'You have the opportunity to contact our support center if you have additional questions regarding the verification of documents.',
      },
      item4: {
        heading: 'Closed loop principle',
        text1: 'We use a closed loop principle that applies to payouts in the following ways:',
        li1text1: 'Withdrawal must be requested by the method used for the last deposit.',
        li1text2: 'Last deposit from a Visa bank card → payout to a Visa bank card',
        li2text1:
          'If the player has multiple payout methods available, then the payout can be split between the methods as follows: the player must claim the full amount of the deposit from which the winnings were received, for the deposit method last used before winning. The rest of the amount can be requested in any other verified way.',
        li2text2:
          '€100 winnings received from a deposit of €20 with a Visa bank card → 1. payout to a Visa bank card in the amount of €20 (deposit amount) → 2. payout to a cryptocurrency wallet €80 (balance of winnings)',
        li3text1: 'We reserve the right to decide which payout method should be used.',
      },
    },
  },

  send: 'Send',
  acceptedFormats: 'Accepted formats',
  maximumSize: 'Maximum size',
  'invalid-size': 'The file size does not meet the limits',
  'invalid-type': 'The type of file is invalid',
  pleaseSelectFile: 'Please select a file',
  kycSendedSuccess: 'The file have been sent successfully',
  exchangeBonusBalance: 'Exchange bonus balance',
  oldBetHistory: 'Old bet history',

  //

  id: 'Id',
  time: 'Time',
  type: 'Type',
  description: 'Description',
  noData: 'No data show!',
  editProfile: 'Edit profile',
  bonusTransfer: 'Transfer bonus',
  totalFunds: 'Total Funds',
  myWidgets: 'My widgets',
  close: 'Close',
  tel: 'Tel',
  code: 'Code',
  statement: 'Statement',
  deposit: 'Deposit',
  filter: 'Filter',
  startDate: 'Start Date',
  endDate: 'End Date',
  reload: 'Reload',
  count: 'Count',
  amount: 'Amount',
  balanceDetails: 'Balance Details',
  details: 'Details',
  transactionType: 'Transaction Type',
  view: 'View',
  total: 'Total',
  manualTransferUp: 'Manual Transfer Up',
  overdraft: 'Overdraft',
  pendings: 'Pending',
  myBet: 'My Bet',
  exchangeSuccess: 'Successful exchange',
  exchangeBonusToBalance: 'Exchange Bonus to Balance',
  amountBonusLess: 'Amount bonus less 3 TND',
  exchange: 'Exchange',
  balanceMore: 'The transfer is available if the balance is less than 1 TND',
  transferSuccess: 'Successfully, the money has been credited to your balance',
  sessions: 'Sessions',
  date: 'Date',
  myProfile: 'My profile',
  transactionHistory: 'Transaction History',
  transactionDetail: 'Transaction Detail',
  transactionId: 'Transaction Id',
  provider: 'Provider',
  oldBalance: 'Old Balance',
  newBalance: 'New Balance',
  status: 'Status',
  allTransactions: 'All transactions',
  dateRange: 'Date range',
  transactionsType: 'Transactions type',
  back: 'Back',
  page: 'Page',
  balanceBefore: 'Balance before',
  balanceAfter: 'Balance after',
  transaction_replenishment: 'Replenishment',
  TransferBonusesToBalance: 'Transfer bonuses to balance',
  IncreasedBySO: 'Replenishment by SO',
  cashback: 'Cashback',
  SportDiscount: 'Sport Discount',
  FirstDeposit: 'First Deposit',
  DetractedFirstDepositBonusByWithdrawal: 'Detracted First Deposit Bonus By Withdrawal',
  bet: 'Bet',
  win: 'Win',
  game: 'Game',
  spinHistory: 'Spin history',
  oldPasswordMissing: 'Old password missing',
  newPasswordMissing: 'New password missing',
}
