import { combineReducers } from 'redux'

import { ISettingsState } from '../types'
import communications from './communications'
import data from './data'

export const reducer = combineReducers<ISettingsState>({
  communications,
  data,
})
