/* eslint-disable max-len */
import { projectStyledName } from 'shared/constants'

export const home: { [key: string]: string } = {
  review: 'Review',
  bc: 'New Pari Sportif',
  sportsBetting: 'Sports betting',
  liveBetting: 'Live betting',
  highlights: 'Highlights',
  liveCasino: 'Live casino',
  casino: 'Casino',
  sport: 'Sport',
  prematch: 'Prematch',
  liveSport: 'Live sport',
  liveSport1: 'Live sport 1',
  liveSport2: 'Live sport 2',
  sport1: 'Sport 1',
  sport2: 'Sport 2',
  vitrualGames: 'Virtual games',
  firecrash: 'Firecrash',
  newVirtuals: 'New virtuals',
  results: 'Results',
  search: 'Search',
  contact: 'Contact',
  settings: 'Settings',
  other: 'Other',
  auth: 'Auth',
  logOut: 'Log out',
  crash: 'Crash',
  evoAutoGames: 'Evo auto games',
  registration: 'Registration',
  signIn: 'Sign in',
  bingo: 'Bingo',
  promotions: 'Promotions',
  allPromotions: 'All promotions',
  menu: 'Menu',
  deposit: 'Deposit',
  logout: 'Logout',
  firstSlideHomeText: `Join the ${projectStyledName} circle of friends and get 200 Winspins, cash back up to $200 and a special
  surprise on Thursdays within a month of signing up!`,
  secondSlideHomeText: `Play online casino and get 20 risk free bingo cards! That's right, it's really that easy to try
  your luck!`,
  thirdSlideHomeText: 'Big jackpots and themed quizzes with prizes.',

  firstSlide2HomeText: `Join ${projectStyledName} now and receive the biggest no wagering bonus on your deposit`,
  secondSlide2HomeText: `Yes, that’s really easy and that’s every day`,
  thirdSlide2HomeText: 'Visa, Mastercard and a lot of crypto currencies available for deposit and fast payouts',

  noWagering: 'NO WAGERING START-UP BONUS',
  everydayCashback: 'EVERYDAY CASHBACK',
  winBigWithGonzos: 'WIN BIG WITH GONZOS 100+ CRYPTOS',
  winBigWithGonzosMini: 'WIN BIG WITH GONZOS',
  joinNow: 'Join Now',
  largestBonus: 'Largest start-up bonus',
  luckyBonusBingo: 'Lucky bingo bonus',
  nightBingoParty: 'Night bingo party',
  learnMore: 'Learn more',
  moreDetail: 'More Detail',
  virtual: 'Virtual',
  home: 'Home',
  playNow: 'Play now',
  liveBet: 'Live bet',
  sports: 'Sports',
  main: 'Main',
}
