/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ExpandMore } from '@mui/icons-material'
import { createTheme, MenuItem, Select as MSelect, styled, ThemeProvider } from '@mui/material'
import block from 'bem-cn'

import { ReactComponent as ArrowIcon } from 'pages/ProfilePage/img/arrow.svg'

import './Select.scss'

const b = block('select-input')

interface ISelectProps {
  data: object[]
  onSelect: (val: unknown, select: { name: string; value: any }) => void
  defaultValue?: any
  value?: any
  labelKey: string
  valueKey: string
  label?: string
  size?: 'small' | 'medium'
  minWidth?: number | string
  name?: string
  isDefault?: boolean
  isLoading?: boolean
}

const CustomMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'left',
  fontFamily: 'inherit !important',
  fontSize: 14,
  color: '#575757',
})

const CustomSelect = styled(MSelect)({
  backgroundColor: 'transparent',
  borderRadius: 24,
  cursor: 'pointer',
  fontSize: '1.4rem',
  height: '100%',
  position: 'relative',
  width: '100%',
  '& .MuiSelect-select': {
    padding: '0 2rem',
    height: '100% !important',
    display: 'flex',
    alignItems: 'center',
  },
  '&	.MuiSelect-icon': {
    fontSize: 28,
    verticalAlign: 'middle',
  },
})

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #9aafb0',
          // borderRadius: '0 0 24px 24px',
        },
      },
    },
  },
})

export const SelectComponent: React.FC<ISelectProps> = ({
  data,
  onSelect,
  defaultValue = '',
  labelKey,
  valueKey,
  label,
  size,
  minWidth = 'auto',
  isDefault = false,
  name,
  value: selectValue,
  isLoading = false,
  ...props
}) => {
  const [opened, setOpened] = useState(false)

  const [activeValue, setActiveValue] = useState(defaultValue)

  const onChange = useCallback(
    (event: any) => {
      const ev = {
        ...event,
        target: {
          element: event.target,
          name,
          value: event.target.value,
        },
      }
      const select = {
        name: name || 'name',
        value: event.target.value,
      }

      onSelect(ev, select)
    },
    [name],
  )

  const onChangeDefaultSelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setActiveValue(e.target.value)
    onChange(e)
  }, [])

  const defaultSelectItems = useMemo(
    () =>
      data.map((item: any, i: number) => (
        <option className={b('option')} key={item.value} value={item[valueKey]}>
          {item[labelKey]}
        </option>
      )),
    [data, labelKey, valueKey],
  )

  const dataList = useMemo(
    () =>
      data.map((item: any) => (
        <CustomMenuItem key={item.value} value={item[valueKey]}>
          {item[labelKey]}
        </CustomMenuItem>
      )),
    [data, labelKey, valueKey],
  )

  return (
    <div className={b()}>
      <ThemeProvider theme={theme}>
        {label ? <h4 className={b('label')}>{label}</h4> : null}
        {isDefault ? (
          <label>
            <div className={b('default-select')}>
              <div className={b('value')}>{selectValue}</div>
              <ArrowIcon className={b('arrow')} />
              <select {...props} value={selectValue} onChange={onChangeDefaultSelect}>
                {defaultSelectItems}
              </select>
            </div>
          </label>
        ) : (
          <CustomSelect
            {...props}
            fullWidth
            className={b('custom-select', { opened })}
            onChange={ev => onChange(ev)}
            open={opened}
            onClick={() => setOpened(!opened)}
            IconComponent={ExpandMore}
            size={size}
            value={selectValue}
            style={{ minWidth }}>
            {dataList}
          </CustomSelect>
        )}
      </ThemeProvider>
    </div>
  )
}

const Select = React.memo(SelectComponent)

export { Select }
