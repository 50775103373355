import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

import { addNotify } from 'components/Notify'

export const exchangeBonusBalanceToBalance = makeCommunicationActionCreator({
  loading: '@bonusSystem/EXCHANGET_BONUS_BALANCE_TO_BALANCE_LOADING',
  success: '@bonusSystem/EXCHANGET_BONUS_BALANCE_TO_BALANCE_SUCCESS',
  error: '@bonusSystem/EXCHANGET_BONUS_BALANCE_TO_BALANCE_ERROR',
  reset: '@bonusSystem/EXCHANGET_BONUS_BALANCE_TO_BALANCE_RESET',
})<{ amount: number }, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const state = getState()
    const locale = state.locale.data.bonuses
    const response = await api.bonusSystem.exchangeBonusBalanceToBalance(payload)
    if (response.success) {
      addNotify(locale.balanceConvertedSuccess, 'success')
    }
    if (args?.callback) {
      args.callback()
    }
  },
)
