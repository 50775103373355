import { generatePath } from 'react-router-dom'

import { ChooseSportType } from 'components/ChooseSport/ChooseSport'

export enum MODAL_ROUTE_PATH {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  SEARCH_SLOTS = '/search-slots',
  FORGOT_PASSWORD = '/forgot-password',
  SIGN_UP_WITH_PROMOCODE = '/sign-up/:promocode',
  DEPOSIT = '/deposit/:paymentSystem',
  WITHDRAWAL = '/withdrawal/:payoutSystem',
  RESEND_EMAIL = '/resend-email',
  VERIFY_EMAIL = '/verify-email',

  RESET_PASSWORD = '/recovery-password',
  RESET_PASSWORD_CONFIRM = '/emails/verify/password/confirm/:token',

  CHOOSE_SPORT = '/choose-sport/:type',

  CONFIRM_BIRTHDAY = '/confirm-birthday',
}

export const MODAL_ROUTE_URLS = {
  SIGN_IN: () => generatePath(MODAL_ROUTE_PATH.SIGN_IN),
  SIGN_UP: () => generatePath(MODAL_ROUTE_PATH.SIGN_UP),
  VERIFY_EMAIL: () => generatePath(MODAL_ROUTE_PATH.VERIFY_EMAIL),
  SEARCH_SLOTS: () => generatePath(MODAL_ROUTE_PATH.SEARCH_SLOTS),
  FORGOT_PASSWORD: () => generatePath(MODAL_ROUTE_PATH.FORGOT_PASSWORD),
  SIGN_UP_WITH_PROMOCODE: (promocode: string) => generatePath(MODAL_ROUTE_PATH.SIGN_UP_WITH_PROMOCODE, { promocode }),
  DEPOSIT: (paymentSystem: string) => generatePath(MODAL_ROUTE_PATH.DEPOSIT, { paymentSystem }),
  WITHDRAWAL: (payoutSystem: string) => generatePath(MODAL_ROUTE_PATH.WITHDRAWAL, { payoutSystem }),
  RESET_PASSWORD: (uid: string, token: string) => generatePath(MODAL_ROUTE_PATH.RESET_PASSWORD, { uid, token }),
  RESEND_EMAIL: () => generatePath(MODAL_ROUTE_PATH.RESEND_EMAIL),

  RESET_PASSWORD_CONFIRM: (token: string) => generatePath(MODAL_ROUTE_PATH.RESET_PASSWORD_CONFIRM, { token }),
  CHOOSE_SPORT: (type: ChooseSportType) => generatePath(MODAL_ROUTE_PATH.CHOOSE_SPORT, { type }),

  CONFIRM_BIRTHDAY: () => generatePath(MODAL_ROUTE_PATH.CONFIRM_BIRTHDAY),
}
