import { IFavoriteDTO, IFavoriteItem } from 'modules/SlotsRoot/types/interfaces/Slots/Favorite'
import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { IConvertGameList, ISlotConverter } from '../../types/converter'

class FavoriteConverter {
  convertGameList = (data: IFavoriteDTO[]): string[] => data.map(t => `${t.game_id}_${t.provider}`)
}

export default FavoriteConverter
