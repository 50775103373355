import React, { useCallback, useContext, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

import { ReactComponent as BurgerIcon } from 'shared/img/burger.svg'
import { ReactComponent as CloseIcon } from 'shared/img/close.svg'
import { ReactComponent as MainLogoMobileIcon } from 'shared/img/logo-mobile.svg'
import { ReactComponent as UserIcon } from 'shared/img/user.svg'

import { defaultCurrency, logoSvgPath } from 'shared/constants'
import { languages } from 'shared/locale'
import { mediaSize } from 'shared/style/var'
import { getFormattedCurrency } from 'shared/utils/user'

import { selectIsUserAuthenticated, selectUserData } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { addNotify } from 'components/Notify'
import SetLanguage from 'components/SetLanguage/SetLanguage'
import { IListMenuItem, listMenu } from 'components/SideMenu/data'
import WheelButton from 'components/WheelButton/WheelButton'

import './Header.scss'

interface IHeaderProps {
  isOpenSideMenu: boolean
  onOpenSideMenu: () => void
  isOpenProfileMenu: boolean
  onToggleProfileMenu: (val?: boolean) => void
}

const b = block('header-block')

export const Header: React.FC<IHeaderProps> = ({
  isOpenSideMenu,
  onOpenSideMenu,
  isOpenProfileMenu,
  onToggleProfileMenu,
}) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { pushNewModal, openedModals } = useContext(ModalContext)
  const isDesktop = useMediaQuery({ minWidth: mediaSize.laptop })

  const localeCommon = useSelector(selectLocaleDict, shallowEqual).common
  const locale = useSelector(selectLocaleDict, shallowEqual).home
  const localeSlots = useSelector(selectLocaleDict, shallowEqual).slots
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const user = useSelector(selectUserData, shallowEqual)

  const onCloseProfileMenu = useCallback(() => {
    onToggleProfileMenu(false)
  }, [onToggleProfileMenu])

  const onBurgerClick = useCallback(() => {
    onOpenSideMenu()
    onCloseProfileMenu()
  }, [onCloseProfileMenu, onOpenSideMenu])

  const onClickUser = useCallback(
    () => (!isDesktop ? onToggleProfileMenu() : history.push(ROUTE_URLS.PROFILE_USERINFO())),
    [history, isDesktop, onToggleProfileMenu],
  )

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: IListMenuItem) => {
      if (link.authRequired && !isAuth) {
        e.preventDefault()
        addNotify(localeSlots.pleaseAuth)
      }
      if (link.isModal) {
        e.preventDefault()
        pushNewModal(link.route)()
      }
    },
    [isAuth, localeSlots, pushNewModal],
  )

  const navItems = useMemo(
    () =>
      listMenu
        .filter(item => !item.skipForModalNav)
        .map(item => {
          const isActive =
            `${pathname}${search}`.indexOf(item.route) !== -1 ||
            (item.refRoutes && item.refRoutes.includes(`${pathname}${search}`))

          return (
            <Link
              to={item.route}
              onClick={e => handleLinkClick(e, item)}
              className={b('nav-link', { isActive })}
              key={item.route}>
              {locale[item.title] || item.title}
            </Link>
          )
        }),
    [pathname, locale, search, handleLinkClick],
  )

  const isHasOpenedModal = useMemo(() => !!openedModals.length, [openedModals])

  return (
    <>
      <div className={b('empty-for-fixed', { isFixed: isHasOpenedModal || isOpenProfileMenu })} />
      <header className={b({ isFixed: isHasOpenedModal || isOpenProfileMenu })}>
        <div className={b('top')}>
          <div className={b('top-left')}>
            <div className={b('burger')} onClick={onBurgerClick}>
              {isOpenSideMenu ? (
                <CloseIcon className={b('burger-icon')} />
              ) : (
                <BurgerIcon className={b('burger-icon')} />
              )}
            </div>
            <NavLink
              onClick={onCloseProfileMenu}
              className={b('logo-link-wrapper').toString()}
              activeClassName={b('logo-link-wrapper_active').toString()}
              to={ROUTE_URLS.MAIN()}>
              <div className={b('logo', { desktop: true })}>
                <img src={logoSvgPath} alt="logo" />
              </div>
              <MainLogoMobileIcon className={b('logo', { mobile: true })} />
            </NavLink>
            <div className={b('lang')}>
              <SetLanguage />
            </div>
            <div className={b('wheel')}>
              <WheelButton />
            </div>
          </div>
          <nav className={b('nav')}>{navItems}</nav>
          <div className={b('auth-block')}>
            {isAuth ? (
              <div className={b('auth-btn', { isAuth })} onClick={onClickUser}>
                <div className={b('info-block')}>
                  <div className={b('balance-block')}>
                    <div className={b('balance')}>
                      {getFormattedCurrency({
                        amount: user?.balance || 0,
                        currency: user?.currency || defaultCurrency,
                      })}
                    </div>
                    <div className={b('balance-bonus')}>
                      {`${user?.bonusBalance !== undefined ? `+ ${user.bonusBalance}` : '-'} B`}
                    </div>
                  </div>
                  <div className={b('username')}>{user?.username}</div>
                </div>
                <div className={b('user-icon-wrapper')}>
                  <UserIcon className={b('user-icon', { open: isOpenProfileMenu }).toString()} />
                </div>
              </div>
            ) : (
              <div className={b('auth-btn')} onClick={pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())}>
                {localeCommon.login}
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  )
}
