import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { ReactComponent as LogoSVG } from 'shared/img/logo.svg'

import { selectLocaleDict } from 'features/locale/selectors'

import Button from 'components/UIKit/Button/Button'

import './NotifyContainer.scss'

interface INotifyContainerProps {
  title: string
  amount?: number
  currency?: string
  callback: () => void
  backgroundCallback: () => void
}

const b = block('notify-container')

const NotifyContainer: React.FC<INotifyContainerProps> = ({
  title, amount, currency, callback, backgroundCallback,
}) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).bonuses

  return (
    <div className={b()}>
      <div className={b('background')} onClick={backgroundCallback} />
      <div className={b('content')}>
        <div className={b('header')}>
          {title}
        </div>
        <div className={b('value')}>
          <div className={b('value-title')}>
            {locale.youGot}
          </div>
          <div className={b('value-amount')}>
            <p>{amount}</p>
            <p>{currency}</p>
          </div>
        </div>
        <div className={b('button-wrapper')}>
          <Button text={locale.thankYou} callback={callback} />
        </div>
        <div className={b('footer')}>
          <LogoSVG className={b('footer-logo')} />
        </div>
      </div>
    </div>
  )
}

export default NotifyContainer
