import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IAuthState } from '../types'

export default combineReducers<IAuthState['communications']>({
  login: makeCommunicationReducer(actions.signIn),
  signUpWithCode: makeCommunicationReducer(actions.signUpWithCode),
  verifyEmail: makeCommunicationReducer(actions.verifyEmail),
  logout: makeCommunicationReducer(actions.logout),
  resetPassword: makeCommunicationReducer(actions.resetPassword),
  resetPasswordConfirm: makeCommunicationReducer(actions.resetPasswordConfirm),
  updateUserData: makeCommunicationReducer(actions.updateUserData),
  resendEmail: makeCommunicationReducer(actions.resendEmail),
  refreshSession: makeCommunicationReducer(actions.refreshSession),
  changeEmail: makeCommunicationReducer(actions.changeEmail),
})
