import { ISignUpWithCodePayload, ISignUpWithoutCodePayload, IUserSignUp } from 'shared/models/Registration'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { RegisterConverter } from './RegisterConverter'

class RegisterApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/accounts`
    this.converter = new RegisterConverter()
  }

  async signUpWithCode({ data }: { data: IUserSignUp }) {
    return this.sendQuery({
      url: `${this.baseUrl}/registration/`,
      type: QueryTypes.POST,
      data,
    })
  }
}

export default RegisterApi
