/* eslint-disable max-len */
/* eslint-disable no-debugger */
import { toast } from 'react-toastify'

import { IEmailPayload, ISession, ISignInPayload, IVerifyEmailPayload } from 'shared/models/Auth'
import { IUserSignUp } from 'shared/models/Registration'
import { IServerUser, IUser } from 'shared/models/User'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'
import { makeCommunicationByIdActionCreator } from 'shared/utils/communication/actions/makeCommunicationByIdActionCreator'
import { ISignIn, ISignUpPayload } from 'shared/utils/types/Auth.type'

import { IUpdateUserPayload } from 'features/profile/types'

import { addNotify } from 'components/Notify'
import { getIsAccessTokenExpired, getIsRefreshTokenExpired } from 'components/SessionPolling/utils'

import { RoleTypes } from '../../shared/models/Roles'
import { IResetPasswordConfirmPayload, IResetPasswordPayload } from './types'

export const logoutSuccessActionType = '@auth/LOGOUT_SUCCESS'

export const logout = makeCommunicationActionCreator({
  loading: '@auth/LOGOUT_LOADING',
  success: '@auth/LOGOUT_SUCCESS',
  error: '@auth/LOGOUT_LOADING_ERROR',
  reset: '@auth/LOGOUT_LOADING_RESET',
})(
  async ({
    deps: {
      getState,
      extra: { api },
    },
  }) => {
    const { session } = getState().auth.data

    if (session) {
      const isRefreshTokenExpired = getIsRefreshTokenExpired(session.refresh)
      const isAccessTokenExpired = getIsAccessTokenExpired(session.access)

      if (!isRefreshTokenExpired && !isAccessTokenExpired) {
        try {
          await api.auth.logout({
            refresh: session.refresh,
          })
          // eslint-disable-next-line no-empty
        } catch {}
      }
    }
  },
)

export const resetPassword = makeCommunicationActionCreator({
  loading: '@auth/RESET_PASSWORD_LOADING',
  success: '@auth/RESET_PASSWORD_SUCCESS',
  error: '@auth/RESET_PASSWORD_ERROR',
  reset: '@auth/RESET_PASSWORD_RESET',
})<IResetPasswordPayload, void>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const locale = getState().locale.data.auth

    const response = await api.auth.passwordReset({
      email: payload.email,
    })

    if (response.success) {
      addNotify(locale.resetPasswordEmailSent, 'success')
      payload.callback()
    }
  },
)

export const resetPasswordConfirm = makeCommunicationActionCreator({
  loading: '@auth/RESET_PASSWORD_CONFIRM_LOADING',
  success: '@auth/RESET_PASSWORD_CONFIRM_SUCCESS',
  error: '@auth/RESET_PASSWORD_CONFIRM_ERROR',
  reset: '@auth/RESET_PASSWORD_CONFIRM_RESET',
})<IResetPasswordConfirmPayload, void>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const locale = getState().locale.data.auth

    try {
      await api.auth.passwordResetConfirm(payload.data)

      addNotify(locale.recoverySuccess, 'success')
      payload.callback()
    } catch {
      addNotify(locale.recoveryError, 'error')
    }
  },
)

export const signIn = makeCommunicationActionCreator({
  loading: '@auth/SIGN_IN_LOADING',
  success: '@auth/SIGN_IN_SUCCESS',
  error: '@auth/SIGN_IN_ERROR',
  reset: '@auth/SIGN_IN_RESET',
})<ISignInPayload, ISignIn, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
    args,
  }) => {
    const locale = getState().locale.data.auth

    const response = await api.auth.signIn(payload)

    if (response.success) {
      if (response.data.user.role !== RoleTypes[RoleTypes.P]) {
        dispatch(logout())
        addNotify(locale.onlyUsersCanUse)
      }
    }

    if (args?.callback) {
      args.callback()
    }

    return response.data
  },
)

export const signUp = makeCommunicationByIdActionCreator({
  loading: '@auth/SIGN_UP_LOADING',
  success: '@auth/SIGN_UP_SUCCESS',
  error: '@auth/SIGN_UP_ERROR',
  reset: '@auth/SIGN_UP_RESET',
})<ISignUpPayload, { id: string }, { callback: any }>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
    args,
  }) => {
    const { id, ...data } = payload

    const response = await api.auth.signUp(data)

    if (response.success) {
      if (args?.callback) {
        args.callback()
      }
    }

    return { id }
  },
)

export const refreshSession = makeCommunicationActionCreator({
  loading: '@auth/REFRESH_SESSION_LOADING',
  success: '@auth/REFRESH_SESSION_SUCCESS',
  error: '@auth/REFRESH_SESSION_ERROR',
  reset: '@auth/REFRESH_SESSION_RESET',
})<
  never,
  Pick<ISession, 'access'> | null,
  { successCallback?: (session: ISession) => void; failureCallback?: () => void }
>(
  async ({
    deps: {
      dispatch,
      extra: { api },
      getState,
    },
    args,
  }) => {
    const { session } = getState().auth.data

    if (!session) return null

    try {
      const response = await api.auth.refreshSession({ refresh: session.refresh })

      args?.successCallback?.({
        refresh: session.refresh,
        access: response.data.access,
      })

      return response.data
    } catch (e) {
      dispatch(logout.success())
      args?.failureCallback?.()
      return null
    }
  },
)

export const getUserData = makeCommunicationActionCreator({
  loading: '@auth/GET_USER_DATA_LOADING',
  success: '@auth/GET_USER_DATA_SUCCESS',
  error: '@auth/GET_USER_DATA_ERROR',
  reset: '@auth/GET_USER_DATA_RESET',
})<IServerUser, IUser>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
  }) => {
    const locale = getState().locale.data.auth

    const response = await api.auth.getUserData()

    if (response.success) {
      if (response.data.role !== RoleTypes[RoleTypes.P]) {
        dispatch(logout())
        addNotify(locale.onlyUsersCanUse)
      }
    }
    return response.data
  },
)

export const updateUserData = makeCommunicationActionCreator({
  loading: '@auth/UPDATE_USER_DATA_LOADING',
  success: '@auth/UPDATE_USER_DATA_SUCCESS',
  error: '@auth/UPDATE_USER_DATA_ERROR',
  reset: '@auth/UPDATE_USER_DATA_RESET',
})<IUpdateUserPayload, IUser, { successCallback?: () => void }>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
    args,
  }) => {
    const response = await api.auth.updateUserData(payload)

    if (response.success) {
      if (args?.successCallback) {
        args.successCallback()
      }
    }

    return response.data
  },
)

export const signUpWithCode = makeCommunicationActionCreator({
  loading: '@auth/USERNAME_SIGN_UP_WITH_CODE_LOADING',
  success: '@auth/USERNAME_SIGN_UP_WITH_CODE_SUCCESS',
  error: '@auth/USERNAME_SIGN_UP_WITH_CODE_ERROR',
  reset: '@auth/USERNAME_SIGN_UP_WITH_CODE_RESET',
})<IUserSignUp, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const response = await api.register.signUpWithCode({ data: payload })
    const state = getState()
    if (response.success) {
      toast(state.locale.data.auth.checkEmail)
      if (args?.callback) {
        args.callback()
      }
    }
  },
)

export const verifyEmail = makeCommunicationActionCreator({
  loading: '@auth/VERIFY_EMAIL_LOADING',
  success: '@auth/VERIFY_EMAIL_SUCCESS',
  error: '@auth/VERIFY_EMAIL_ERROR',
  reset: '@auth/VERIFY_EMAIL_RESET',
})<IVerifyEmailPayload, void, { callback?: () => void; errorCallback?: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const { locale } = getState()

    try {
      await api.auth.verifyEmail(payload)

      addNotify(locale.data.auth.verifyEmailSuccess, 'success')
      args?.callback?.()
    } catch {
      addNotify(locale.data.auth.verifyEmailError, 'error')
      args?.errorCallback?.()
    }
  },
)

export const resendEmail = makeCommunicationActionCreator({
  loading: '@auth/RESEND_EMAIL_LOADING',
  success: '@auth/RESEND_EMAILL_SUCCESS',
  error: '@auth/RESEND_EMAIL_ERROR',
  reset: '@auth/RESEND_EMAIL_RESET',
})<IEmailPayload, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const { locale } = getState()

    const response = await api.auth.resendEmail(payload)

    if (response.success) {
      addNotify(locale.data.auth.resendEmailSuccess)

      if (args?.callback) {
        args.callback()
      }
    }
  },
)

export const changeEmail = makeCommunicationActionCreator({
  loading: '@auth/CHANGE_EMAIL_LOADING',
  success: '@auth/CHANGE_EMAIL_SUCCESS',
  error: '@auth/CHANGE_EMAIL_ERROR',
  reset: '@auth/CHANGE_EMAIL_RESET',
})<IEmailPayload, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
    args,
  }) => {
    const response = await api.auth.changeEmail(payload)

    if (response.success) {
      if (args?.callback) {
        args.callback()
      }
    }
  },
)
