import dayjs from 'dayjs'

import {IBonusHistoryFilters,
  ICashbackHistoryFilters,
  IConvertedBonusHistory} from 'services/api/BonusHistoryApi/types/types'

import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'


export const getBonusHistory = makeCommunicationActionCreator({
  loading: '@bonusHistory/GET_BONUS_HISTORY_LOADING',
  success: '@bonusHistory/GET_BONUS_HISTORY_SUCCESS',
  error: '@bonusHistory/GET_BONUS_HISTORY_ERROR',
  reset: '@bonusHistory/GET_BONUS_HISTORY_RESET',
})<IBonusHistoryFilters, IConvertedBonusHistory>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {

    const data = {
      ...payload,
      date_from: payload.date_from ? dayjs(payload.date_from).startOf('day').format('YYYY-MM-DDTHH:mmZ') : undefined,
      date_to: payload.date_to ? dayjs(payload.date_to).endOf('day').format('YYYY-MM-DDTHH:mmZ') : undefined,
    }

    const response = await api.bonusHistory.loadBonusHistory(data)
    return response.data
  },
)

export const getCashbackHistory = makeCommunicationActionCreator({
  loading: '@bonusHistory/GET_CASHBACK_HISTORY_LOADING',
  success: '@bonusHistory/GET_CASHBACK_HISTORY_SUCCESS',
  error: '@bonusHistory/GET_CASHBACK_HISTORY_ERROR',
  reset: '@bonusHistory/GET_CASHBACK_HISTORY_RESET',
})<ICashbackHistoryFilters, IConvertedBonusHistory>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {

    const data = {
      ...payload,
      date_from: payload.date_from ? dayjs(payload.date_from).startOf('day').format('YYYY-MM-DDTHH:mmZ') : undefined,
      date_to: payload.date_to ? dayjs(payload.date_to).endOf('day').format('YYYY-MM-DDTHH:mmZ') : undefined,
    }

    const response = await api.bonusHistory.loadCashbackHistory(data)
    return response.data
  },
)
