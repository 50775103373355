import { combineReducers } from 'redux'
import { MigrationManifest } from 'redux-persist'
import createMigrate from 'redux-persist/es/createMigrate'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { IAuthState } from '../types'
import communications from './communications'
import data from './data'
import { authMigrations } from './migrations/migrations'

const persistConfig = {
  key: 'auth',
  storage,
  migrate: createMigrate(authMigrations as unknown as MigrationManifest, { debug: false }),
  version: 1,
  blacklist: ['communications'],
}

export const reducer = persistReducer(
  persistConfig,
  combineReducers<IAuthState>({
    communications,
    data,
  }),
)
