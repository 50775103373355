import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import { lazyImportComponent } from 'shared/helpers/lazyImportComponent'
import { ROUTE_PATHS } from '../../router/routes'

const TermsPage = React.lazy(() => import('pages/TermsPage/TermsPage'))

export class TermsPageModule {
  getRoutes() {
    return (
      <Route key="/terms" path={ROUTE_PATHS.TERMSANDCONDITIONS}>
        <Switch>
          <Route
            key={ROUTE_PATHS.TERMSANDCONDITIONS}
            path={ROUTE_PATHS.TERMSANDCONDITIONS}
            component={TermsPage}
            exact
          />
          <Route
            key={ROUTE_PATHS.TERMSANDCONDITIONS_POINT}
            path={ROUTE_PATHS.TERMSANDCONDITIONS_POINT}
            component={TermsPage}
          />
        </Switch>
      </Route>
    )
  }
}
