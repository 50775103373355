import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import block from 'bem-cn'

import { modalRoutes } from 'router/components/modalRoutes'

import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'
import Modal from 'components/Modal/Modal'

import './ModalManager.scss'

const b = block('modal-manager')

const ModalManager: React.FC = () => {
  const { openedModals, closeModal } = useContext(ModalContext)

  const onCloseModal = useCallback(
    (modalRoute: string) => {
      closeModal(modalRoute)()
    },
    [closeModal],
  )

  useEffect(() => {
    const html = document.getElementsByTagName('html')?.[0]
    if (html) {
      if (openedModals.length) {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden'
      } else {
        document.getElementsByTagName('html')[0].style.overflow = 'initial'
      }
    }
  }, [openedModals])

  const routes = useMemo(
    () =>
      modalRoutes.map(t => {
        const paramsArray = openedModals
          .map((openedPath, index) => {
            const params = getRouteParamsByStrings(t.route, openedPath)
            return params !== null ? { index, params, route: t.route, path: openedPath } : null
          })
          .filter(tI => tI !== null)
        const indexInOpenedModalsList = openedModals.indexOf(t.route)
        const indexInParamsArray = paramsArray.findIndex(pArrayItem => pArrayItem?.route === t.route)
        return (
          <Modal
            key={t.id}
            open={indexInOpenedModalsList !== -1 || Boolean(paramsArray.length)}
            bgColor={t.bgColor}
            handleClose={() =>
              onCloseModal(
                indexInOpenedModalsList !== -1
                  ? openedModals[indexInOpenedModalsList]
                  : paramsArray[indexInParamsArray]?.path || '',
              )
            }
            hideCloseBtn={t.hideCloseBtn}
            shadow={t.shadow}
            position={t.position}
            bordered={t.bordered}
            isFull={t.isFull}>
            <t.Component path={paramsArray[indexInParamsArray]?.path || openedModals[indexInOpenedModalsList]} />
          </Modal>
        )
      }),
    [onCloseModal, openedModals],
  )
  return <div className={b()}>{routes}</div>
}

ModalManager.propTypes = {}

export default ModalManager
