/* eslint-disable max-classes-per-file */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Store } from 'redux'

import { IApplicationState } from 'setup/store'

import { QueryTypes } from './data'

export interface IHttpMethodRequestSettings<SR, CR, D> {
  url: string
  type: typeof QueryTypes[keyof typeof QueryTypes]
  data?: D
  options?: AxiosRequestConfig<D>
  // eslint-disable-next-line no-unused-vars
  converterSuccess?: (data: SR) => CR
  // eslint-disable-next-line no-unused-vars
  converterFailed?: (data: unknown) => unknown
}

interface IHttpResponse<CR> {
  success: boolean
  data: CR
  errorMessage: string
  codeStatus: string
}

export class BaseHttpService {
  static lang = ''

  constructor(public baseUrl: string) {
    axios.defaults.responseType = 'json'
    axios.defaults.timeout = 30000
    axios.defaults.validateStatus = status => (status >= 200 && status < 300) || status === 302
    // axios.interceptors.response.use(undefined, undefined)
    this.baseUrl = baseUrl
  }

  static setLang(lang: string) {
    this.lang = lang
  }

  protected async sendQuery<ServerResponse, ConverteredResponse, Data = unknown>({
    url,
    data,
    type,
    options: opt,
    converterSuccess,
  }: IHttpMethodRequestSettings<ServerResponse, ConverteredResponse, Data>): Promise<
    IHttpResponse<ConverteredResponse>
  > {
    const headers = {
      'Cache-Control': 'no-cache',
    }

    const options = {
      ...opt,
      headers: {
        ...(opt?.headers || {}),
        ...headers,
      },
    }

    let response
    switch (type) {
      case QueryTypes.POST:
        response = await axios.post(url, data, options)
        break
      case QueryTypes.DELETE:
        response = await axios.delete(url, { ...data, ...options })
        break
      case QueryTypes.PUT:
        response = await axios.put(url, data, options)
        break
      case QueryTypes.PATCH:
        response = await axios.patch(url, data, options)
        break
      default:
        response = await axios.get(url, { params: data, ...options })
        break
    }

    const { data: responseData, status } = response
    const success = status >= 200 && status <= 300
    const resultResponse = {
      success,
      data: success && converterSuccess ? converterSuccess(responseData as ServerResponse) : responseData,
      errorMessage: responseData.message,
      codeStatus: responseData.code || status,
      responseStatus: status,
    }
    return resultResponse
  }

  // eslint-disable-next-line no-unused-vars
  public static addResponseInterceptor = (interceptor: (value: AxiosResponse<any>) => void | Promise<void>) => {
    axios.interceptors.response.use(interceptor, undefined)
  }

  // eslint-disable-next-line no-unused-vars
  public static addErrorInterceptor = (interceptor: (error: AxiosError<unknown>) => void) => {
    axios.interceptors.response.use(undefined, interceptor)
  }

  public static addTokenInterceptor = (store: Store<IApplicationState, any>) => {
    axios.interceptors.request.use(function (config) {
      const { session } = store.getState().auth.data

      if (session) {
        return {
          ...(config ?? {}),
          headers: {
            ...(config?.headers ?? {}),
            Authorization: `Bearer ${session.access}`,
          },
        }
      }

      return config
    })
  }
}
