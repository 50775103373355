export const deleteEmptyFields = filterObj => {
  let newObj = {}
  Object.entries(filterObj).forEach(([key, value]) => {
    if (value && value !== null) {
      newObj = {
        ...newObj,
        [key]: value,
      }
    }
  })

  return newObj
}

export const returnFieldsNames = ({ fields, fieldsForReplace }) => {
  let convertedFields = {}
  let revertedFieldsForReplace = {}
  Object.entries(fieldsForReplace).forEach(([key, value]) => {
    revertedFieldsForReplace = {
      ...revertedFieldsForReplace,
      [value]: key,
    }
  })
  Object.entries(fields).forEach(([key, value]) => {
    if (revertedFieldsForReplace[key]) {
      convertedFields = {
        ...convertedFields,
        [revertedFieldsForReplace[key]]: value,
      }
    } else {
      convertedFields = {
        ...convertedFields,
        [key]: value,
      }
    }
  })
  return convertedFields
}

export const changeFieldsNames = ({ fields, fieldsForReplace }) => {
  let convertedFields = {}
  Object.entries(fields).forEach(([key, value]) => {
    if (fieldsForReplace[key]) {
      convertedFields = {
        ...convertedFields,
        [fieldsForReplace[key]]: value,
      }
    } else {
      convertedFields = {
        ...convertedFields,
        [key]: value,
      }
    }
  })
  return convertedFields
}
