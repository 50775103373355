import {
  IFreeSpinInfoResponse,
  IFreeSpinInfoView,
  IOutcomeServerGame,
  IOutcomeSessionResponse,
} from 'modules/SlotsRoot/types/interfaces/Slots/Outcome'
import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { IConvertGameList, ISlotConverter } from '../../types/converter'
import { getOtcSlotImage } from '../../utils/images'
import { getTab, getTabLive } from './OutcomeUtils'

class OutcomeConverter implements ISlotConverter {
  convertGameList = ({ data }: IConvertGameList<IOutcomeServerGame[]>): ISlotViewItem[] =>
    data
      .map((item: IOutcomeServerGame) => ({
        id: item.Id,
        gameId: item.Id,
        description: item.Description,
        format: item.Format,
        gameName: item.Name,
        tab: getTab(item.Id),
        tabLive: getTabLive(item.Id),
        provider: item.SectionId !== 'pragmatic' ? item.SectionId : 'pragmaticplay',
        imageUrl: getOtcSlotImage(item.Id, item.SectionId),
        routeProvider: 'otc',
        hasDemoMode: true,
        hasFreeSpins: item.HasFreeSpins,
      }))
      .filter(t => t.provider !== 'pragmaticplay')

  convertGameUrl = ({ data }: IOutcomeSessionResponse) => data.result.SessionUrl

  convertFreeSpinInfo = (data: IFreeSpinInfoResponse): IFreeSpinInfoView => ({
    provider: data.provider,
    sessionEnd: data.session_end,
    freeSpinLeft: data.freespin_left,
    prizeAmount: data.prize.amount,
    prizeCurrency: data.prize.currency,
  })
}

export default OutcomeConverter
