import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import block from 'bem-cn'

import { ROUTE_URLS } from 'router/routes'

import { navigationsItems } from './data'
import { ReactComponent as HomeIcon } from './img/home.svg'
import NavigationItem from './NavigationItem/NavigationItem'

import './NavigationHeader.scss'

const b = block('navigation-header')

const NavigationHeader: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const scrollRef = useRef<HTMLUListElement>(null)

  const onScrollComplete = useCallback(() => {
    setIsScrolled(false)
  }, [])

  useEffect(() => {
    let ref = scrollRef.current

    if (scrollRef.current) {
      ref = scrollRef.current

      scrollRef.current.addEventListener('scroll', () => {
        setIsScrolled(true)
      })
      
    }
    return () => ref?.removeEventListener('scroll', () => {
      setIsScrolled(true)
    })
  }, [])
  
  return (
    <div className={b('wrapper')}>
      <ul className={b()} ref={scrollRef} >
        <Link className={b('item')} to={ROUTE_URLS.MAIN()}>
          <HomeIcon className={b('item-logo')} />
        </Link>
        <div className={b('nav-items')}>
          {navigationsItems
            .filter(item => item.route)
            .map((navItem, id) => (
              <NavigationItem
                key={`${navItem.text}_${navItem.route}_${id}`}
                item={navItem}
                isScrolled={isScrolled}
                onScrollComplete={onScrollComplete}
              />
            ))}
        </div>
      </ul>
    </div>
  )
}

export default NavigationHeader
