export const SportViews = {
  eu: 'europeanView',
  af: 'africanView',
  as: 'AsianView',
  m: 'Mobile',
  es: 'ESport',
  la: 'LatinoView',
}

export const iframeSportViews = ['europeanView', 'africanView']

export const mobileSportViews = ['Mobile', 'ESport']

export const desktopSportViews = ['europeanView', 'africanView', 'AsianView', 'ESport', 'LatinoView']

export type SportViewType = keyof typeof SportViews

const pages = [
  'Home',
  'Upcoming',
  'EventView',
  'Results',
  'UpcomingDetails',
  'Calendar',
  'MultiView',
  'Overview',
  'BetsHistory',
  'GroupStage',
  'Live',
  'Sport',
] as const

export type PageTypes = (typeof pages)[number]

export const defaultPages: PageTypes[] = [
  'Home',
  'Upcoming',
  'EventView',
  'Results',
  'UpcomingDetails',
  'Calendar',
  'MultiView',
  'Overview',
  'BetsHistory',
  'GroupStage',
]

export const pagesByViews: { [key in SportViewType]: PageTypes[] } = {
  eu: defaultPages,
  m: [...defaultPages, 'Live', 'Sport'],
  la: defaultPages,
  es: defaultPages,
  as: defaultPages,
  af: defaultPages,
}

export enum SportIds {
  Footbal = 1,
  Tennis = 3,
  'Basketball' = 4,
  'Volleyball' = 12,
  'Ice Hockey' = 10,
  'Table Tennis' = 25,
  'Esports' = 53,
  'E-Football' = 94,
  'E-Basketball' = 95,
  'E-IceHockey' = 96,
  'Handball' = 13,
  'Futsal' = 17,
  'Beach Football' = 22,
  'Beach Volleyball' = 26,
  'Badminton' = 29,
  'Martial Arts' = 26,
  'Darts' = 20,
  'Cricket' = 36,
  'Rugby Union' = 14,
  'Field Hockey' = 21,
  'Mini Football' = 45,
}

export interface IToken {
  token: string
}

export type LanguageTypes =
  | 'ru'
  | 'en'
  | 'hy'
  | 'tr'
  | 'bs'
  | 'Fa'
  | 'ar'
  | 'sr'
  | 'hr'
  | 'uk'
  | 'Ku'
  | 'He'
  | 'es'
  | 'pt'
  | 'it'
  | 'fr'
  | 'de'
  | 'ko'
  | 'da'
  | 'fi'
  | 'cs'
  | 'sv'
  | 'ka'
  | 'no'
  | 'zh'
  | 'zh-CHT'

enum OddsEnum {
  'Decimal',
  'Fractional',
  'American',
  'Hong Kong',
  'Malay',
  'Indo',
}

type eventsTypes = 'successBet' | 'navigateToSport'

type eventsData = {
  id: number
  status: string
  name: string
} | null

type themesTypes = 'default' | 'alternative'

export interface INavigateCallbackProps {
  targetId: number
  pageName: PageTypes
  targetName: string
}

export interface IBalanceChangeCallbackProps {
  BonusBalance: string
  CashBackBalance: string
  TournamentBalance: string
}

export interface IEventsHandlerProps {
  sender: string
  eventType: eventsTypes
  data: eventsData
}

export interface IParametersIframe {
  server: string
  token?: Union<'-', string>
  currentPage: PageTypes
  language?: LanguageTypes
  login?: { (): void }
  registration?: { (): void }
  timeZone?: string
  eventId?: string
  isSuperTip?: boolean
  champId?: string
  deposit?: { (): void }
  customCssUrl?: string
  oddsFormat?: OddsEnum
  oddsFormatList?: OddsEnum
  onContentLoaded?: string
  sportsBookView: (typeof iframeSportViews)[number]
  fixedHeight?: boolean
  clearSiteStyles?: boolean
  balanceChangeCallback?: { (data: IBalanceChangeCallbackProps): void }
  onNavigateCallback?: { (data: INavigateCallbackProps): void }
  getBookingNumber?: { (): void }
  eventsHandler?: { (data: IEventsHandlerProps): void }
  bookingNumber?: { (): void }
  projectTheme?: themesTypes
  parent: string[]
  destroy?: { (): void }
  userId: string
  sportPartner?: string
}

export interface IParametersNative extends IParametersIframe {
  containerId: string
  hashRouterDisabled?: boolean
  externalLinksOpenInside?: boolean
}
