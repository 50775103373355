import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import block from 'bem-cn'

import { ROUTE_PATHS, ROUTE_URLS } from 'router/routes'

import { ReactComponent as MainLogoIcon } from 'shared/img/logo.svg'

import { selectLocaleDict } from 'features/locale/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { ILinkSubItem, linksItems } from './data'

import './Footer.scss'

const b = block('footer')

export const Footer: React.FC = () => {
  const { pushNewModal } = useModalContext()

  const isSportMatch = !!useRouteMatch({
    path: [ROUTE_PATHS.DIGITAIN_SPORT_PAGE, ROUTE_PATHS.BETBY_SPORT],
  })?.url

  const locale = useSelector(selectLocaleDict, shallowEqual).common

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: ILinkSubItem) => {
    if (link.modalRoute) {
      e.preventDefault()
      pushNewModal(link.modalRoute)()
    }
    },
    [pushNewModal],
  )

  const renderLinks = useCallback(
    (links: ILinkSubItem[]) =>
      links.map((link, id) => (
        <Link
          key={`footer_${link.label}_${id}`}
          to={link.route}
          className={b('link')}
          onClick={e => handleLinkClick(e, link)}
        >
          {locale[link.label] || link.label}
        </Link>
      )),
    [handleLinkClick, locale],
  )

  const links = useMemo(
    () =>
      Object
        .keys(linksItems)
        .map((item: string, id: number) => (
          <div key={`footer_${item}_${id}`} className={b('column')}>
            <div className={b('column-title')}>
              {locale[item] || item}
            </div>
            <div className={b('column-content')}>
              {renderLinks(linksItems[item])}
            </div>
          </div>
        )),
    [locale, renderLinks],
  )

  if (isSportMatch) return null

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('links')}>
          {links}
        </div>
        <div className={b('info')}>
          <Link to={ROUTE_URLS.MAIN()} className={b('logo-wrapper')}>
            <MainLogoIcon className={b('logo')} />
          </Link>
          <div className={b('info-copyright')}>
            {locale.copyright}
          </div>
          <div className={b('info-warning')}>
            {locale.footerWarning}
          </div>
        </div>
      </div>
    </div>
  )
}
