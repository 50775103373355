import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { Persist as FormikPersist } from 'formik-persist'

import { getUserData } from 'features/auth/actions'
import { selectAuthCommunication } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { updateUserInfo } from 'features/profile/actions'

import Button from 'components/UIKit/Button/Button'
import DatePicker from 'components/UIKit/DatePicker/DatePicker'

import { birthDateFields, dateOfBirthFormat, dates } from '../data'
import {
  StyledAuthForm,
  StyledAuthHeader,
  StyledAuthHeaderLocale,
  StyledAuthHr,
  StyledAuthInputLabel,
  StyledAuthInputWrapper,
  StyledAuthModal,
  StyledButtonContainer,
} from '../styles/AuthStyles'

import './ConfirmBirthDate.scss'

const b = block('confirm-birth-date')

export const ConfirmBirthDate = () => {
  const dispatch = useDispatch()
  const locale = useSelector(selectLocaleDict, shallowEqual).auth

  const { isLoading } = useSelector(selectAuthCommunication, shallowEqual).resendEmail

  const successCallback = useCallback(() => {
    dispatch(getUserData())
  }, [dispatch])

  const onSubmit = useCallback(
    (values: typeof birthDateFields) => {
      const updateData = {
        date_of_birth: values.dateOfBirth,
      }
      dispatch(updateUserInfo(updateData, { successCallback }))
    },
    [dispatch, successCallback],
  )

  return (
    <StyledAuthModal className={b()}>
      <Formik
        initialValues={birthDateFields}
        validate={values => {
          let errors = {} as Record<string, string>

          if (dayjs(values.dateOfBirth).isAfter(dates.defaultDate)) {
            errors.dateOfBirth = 'mustBe'
          }

          return errors
        }}
        onSubmit={onSubmit}>
        {({ values, isValid, handleChange, handleBlur, handleSubmit }) => (
          <StyledAuthForm onSubmit={handleSubmit} className={b('form')}>
            <StyledAuthHeader className={b('header')}>
              <StyledAuthHeaderLocale className={b('title')}>{locale.proofOfAge}</StyledAuthHeaderLocale>
            </StyledAuthHeader>
            <StyledAuthHr />
            <StyledAuthInputWrapper className={b('input-wrapper')}>
              <StyledAuthInputLabel>
                {locale.birthDate}
                <DatePicker
                  name="dateOfBirth"
                  datePickerStylesOverride={b('date-picker').toString()}
                  format={dateOfBirthFormat}
                  minDate={new Date(dates.minData)}
                  callback={e => {
                    handleChange({ target: { value: e, name: 'dateOfBirth' } })
                  }}
                  onBlur={handleBlur}
                  value={values.dateOfBirth ? new Date(values.dateOfBirth) : dates.minData}
                  defaultValue={values.dateOfBirth ? new Date(values.dateOfBirth) : new Date(dates.minData)}
                  block
                />
              </StyledAuthInputLabel>
            </StyledAuthInputWrapper>
            <StyledAuthHr />
            <StyledButtonContainer className={b('button-wrapper')}>
              <Button
                type="submit"
                colorType="main"
                text={locale.done}
                disabled={isLoading || !isValid}
                loading={isLoading}
              />
            </StyledButtonContainer>
            <FormikPersist name="confirm-birth-date" />
          </StyledAuthForm>
        )}
      </Formik>
    </StyledAuthModal>
  )
}
