import React, { FC, useEffect } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { selectLocaleDict } from '../../features/locale/selectors'

import './ErrorBoundary.scss'

type Props = {
  error: Error
  isReset?: boolean
  resetErrorBoundary: (...args: unknown[]) => void
}

const b = block('error-boundary')

const ErrorFallback: FC<Props> = ({ error, isReset, resetErrorBoundary }) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).errorStatusMessage
  const reloadCount = Number(localStorage.getItem('reloadCount') || '0')
  useEffect(() => {
    if (!isReset) {
      if (reloadCount < 3) {
        localStorage.setItem('reloadCount', (reloadCount + 1).toString())
        location.reload()
      } else if (reloadCount === 3) {
        localStorage.setItem('reloadCount', (reloadCount + 1).toString())
        location.replace('/')
      } else {
        localStorage.removeItem('reloadCount')
      }
    }
  }, [])

  return reloadCount === 4 ? (
    <div className={b()}>
      <div className={b('info')}>
        <span>{locale.defaultMessage} - </span>
        <span>{`${error.message}`}</span>
      </div>
      {!isReset && (
        <button className={b('button')} onClick={resetErrorBoundary}>
          {locale.tryAgain}
        </button>
      )}
    </div>
  ) : null
}

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>
)
