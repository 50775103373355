import dayjs from 'dayjs'
// import * as Yup from 'yup'

export const dateOfBirthFormat = 'YYYY-MM-DD'

export const minBirthDate = dayjs().subtract(18, 'years')

export const dates = {
  minData: dayjs('1900-01-01').startOf('day').format('YYYY-MM-DD'),
  defaultDate: minBirthDate.format('YYYY-MM-DD'),
}

export const birthDateFields = {
  dateOfBirth: minBirthDate.format('YYYY-MM-DD'),
}

// export const BirthDateSchema = Yup.object().shape({
//   dateOfBirth: Yup.date()
//     .typeError('mustBeDate')
//     .test('is-valid-date-of-birth', 'mustBe', (value, context) => {
//       const minDate = dates.defaultDate

//       console.log(minDate, value, context)

//       return false
//       // return context.createError({ message: 'memkek' })
//     })
//     .required('required'),
// })
