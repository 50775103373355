/* eslint-disable camelcase */
import dayjs from 'dayjs'

import { IProfile, IServerProfile, IServerUserInfo, IUserInfo } from 'shared/models/Profile'

import { IDefaultDataResponse, IDefaultDataResponseByService } from '../PaymentApi/types/types'
import {
  IConvertedTransactions,
  ISlotsHistory,
  ISlotsHistoryDTO,
  ITransactionListItemDTO,
} from './types/types'

export class ProfileConverter {
  convertProfileInfo(data: IServerProfile): IProfile {
    return {
      id: data.id,
      username: data.username,
      dateJoined: dayjs(data.date_joined).format('DD/MM/YYYY'),
      balanceCurrency: data.balance_currency,
      balance: data.balance,
      isBanned: data.is_banned,
    }
  }

  convertUserInfo(data: IServerUserInfo): IUserInfo {
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      gender: data.gender,
      email: data.email,
      dateOfBirth: data.date_of_birth,
      country: data.country,
      city: data.city,
    }
  }

  convertTransfersList = (data: IDefaultDataResponse<ITransactionListItemDTO>): IConvertedTransactions => {
    const withdrawals =
      data?.results?.map(item => {
        const { id, sender, recipient, status, amount, balance_after, balance_before, completed_at, created_at } = item

        return {
          id,
          fromId: sender.id,
          toId: recipient.id,
          amount: +(+amount).toFixed(2),
          balanceBefore: balance_before,
          balanceAfter: balance_after,
          date: dayjs(created_at).format('DD.MM.YYYY HH:mm'),
          createdAt: dayjs(created_at).format('HH:mm:ss DD.MM.YYYY'),
          completedAt: dayjs(completed_at).format('HH:mm:ss DD.MM.YYYY'),
          status,
          type: 'withdrawal',
        }
      }) || []

    return {
      page: data.page,
      count: data.count,
      history: withdrawals,
    }
  }

  convertSlotsHistory = (data: ISlotsHistoryDTO): ISlotsHistory => {
    const items =
      data.results.map(item => ({
        provider: item.producer,
        date: dayjs(item.date).format('HH:mm DD/MM/YYYY'),
        balance: `${Math.floor(+item.balance_before * 100) / 100} → ${Math.floor(+item.balance_after * 100) / 100} ${
          item.balance_after_currency
        }`,
        status: item.status,
        betAmount: `${Math.floor(+item.bet_amount * 100) / 100}`,
        winAmount: `${Math.floor(+item.win_amount * 100) / 100}`,
        gameId: item.game_id,
      })) || []
    return {
      count: data.count,
      items,
    }
  }
}
