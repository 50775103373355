import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

import { ReactComponent as AviatorSVG } from 'shared/img/aviator.svg'
import { ReactComponent as Home } from 'shared/img/home.svg'
import { ReactComponent as Casino } from 'shared/img/slots/gameTypes/casino.svg'
import { ReactComponent as LiveCasino } from 'shared/img/slots/gameTypes/casinoLive.svg'
import { ReactComponent as Sports } from 'shared/img/sports.svg'
import { ReactComponent as SportsLive } from 'shared/img/sports-live.svg'

export interface IListMenuItem {
  route: string
  icon?: any
  title: string
  authRequired?: boolean
  mobileRoute?: string
  isModal?: boolean
  refRoutes?: string[]
  skipForModalNav?: boolean
  group?: 'main' | 'sport' | 'casino'
}

export const listMenu: IListMenuItem[] = [
  {
    title: 'liveSport1',
    route: ROUTE_URLS.DIGITAIN_SPORT_PAGE('Overview'),
    mobileRoute: ROUTE_URLS.DIGITAIN_SPORT_PAGE('Live'),
    skipForModalNav: true,
    group: 'sport',
    icon: SportsLive,
  },
  {
    title: 'sport1',
    route: ROUTE_URLS.DIGITAIN_SPORT_PAGE('Home'),
    skipForModalNav: true,
    group: 'sport',
    icon: Sports,
  },
  {
    title: 'liveSport2',
    route: ROUTE_URLS.BETBY_SPORT('live'),
    skipForModalNav: true,
    group: 'sport',
    icon: SportsLive,
  },
  {
    title: 'sport2',
    route: ROUTE_URLS.BETBY_SPORT(),
    skipForModalNav: true,
    group: 'sport',
    icon: Sports,
  },
  {
    title: 'liveSport',
    route: MODAL_ROUTE_URLS.CHOOSE_SPORT('live'),
    icon: SportsLive,
    isModal: true,
    refRoutes: [
      ROUTE_URLS.DIGITAIN_SPORT_PAGE('Live'),
      ROUTE_URLS.DIGITAIN_SPORT_PAGE('Overview'),
      ROUTE_URLS.BETBY_SPORT('live'),
    ],
    group: 'sport',
  },
  {
    title: 'sport',
    route: MODAL_ROUTE_URLS.CHOOSE_SPORT('sport'),
    icon: Sports,
    isModal: true,
    refRoutes: [ROUTE_URLS.DIGITAIN_SPORT_PAGE('Home'), ROUTE_URLS.BETBY_SPORT()],
    group: 'sport',
  },
  {
    title: 'casino',
    route: ROUTE_URLS.SLOTS_GAMETYPE('casino'),
    icon: Casino,
    group: 'casino',
  },
  {
    title: 'liveCasino',
    route: ROUTE_URLS.SLOTS_GAMETYPE('live-casino'),
    icon: LiveCasino,
    group: 'casino',
  },
  {
    title: 'Aviator',
    route: ROUTE_URLS.SLOTS_PROVIDER('otc', 'aviator_spribe'),
    icon: AviatorSVG,
    authRequired: true,
    group: 'casino'
  },
]

export const navMenu: IListMenuItem[] = [
  {
    title: 'home',
    route: ROUTE_URLS.MAIN(),
    icon: Home,
    group: 'main',
  },
]

export const initialClosedGroups: ({ [key: string]: boolean }) = {
  'main': false,
  'casino': true,
  'sport': true,
}
