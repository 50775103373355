import { createAction } from '@reduxjs/toolkit'

import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

export const getBetbySportToken = makeCommunicationActionCreator({
  loading: '@betby/GET_SPORT_TOKEN_LOADING',
  success: '@betby/GET_SPORT_TOKEN_SUCCESS',
  error: '@betby/GET_SPORT_TOKEN_ERROR',
  reset: '@betby/GET_SPORT_TOKEN_RESET',
})<string, string>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.betby.getToken(payload)
    return response.data
  },
)

export const clearSportToken = createAction('@betby/CLEAR_SPORT_TOKEN')
