import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IWheelGameState } from '../types'

export default combineReducers<IWheelGameState['communications']>({
  getSpinHistory: makeCommunicationReducer(actions.getSpinHistory),
  getPrizesList: makeCommunicationReducer(actions.getPrizesList),
  getPublicSettings: makeCommunicationReducer(actions.getPublicSettings),
  spinWheel: makeCommunicationReducer(actions.spinWheel),
})
