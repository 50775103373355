/* eslint-disable max-len */
export const basket: { [key: string]: any } = {
  sport: 'Sports betting',
  live: 'Live betting',
  cancelAcceptCahngeCoefs: 'Cancel accept change odds',
  submitBet: 'Submit bet',
  express: 'Express',
  single: 'Single',
  basketType: 'Bet type',
  stake: 'Stake',
  removeAll: 'Remove all bets',
  emptyBasketText:
    'The web bet slip is currently empty. Select quotas from the sports offer for adding bets to the bet slip',
  myCoupons: 'My coupons',
  toBasket: 'Go to basket',
  totalCoef: 'Total odds',
  minAmount: (min, currency) => `Min amount: ${min} ${currency}`,
  maxAmount: (max, currency) => `Max amount: ${max} ${currency}`,
  possiblePrize: 'Possible prize',
  acceptCahngeCoefs: 'Accept change odds',
  betslip: 'Betslip',
  makeBet: 'Make bet',
  makeFreeBet: 'Make free bet',
  makeMoney: 'Make money',
  emptyFreeBet: 'No free bets',
  clearBasket: 'Clear basket',
  sum: 'Bet amount',
  pleaseAuth: 'Please, auth',
  betSuccess: 'Bet success',
  useBonusBalance: 'Use bonus balance',
  bonusRules: 'Bonus pay rules',
  betCashback: 'Cashback from bet',
  maxBetInMount: 'Max bets count in month',
  maxBonusBetAmout: 'Max bonus bet amount',
  bonusPercentFromBet: 'Bonus percent from bet',
  minimalCoef: 'Minimal coef to bonus bet',
  bonusAmount: 'Bonus amount',
  pleaseLoginToSeeInfo: 'Please login to see information',
  outcomeInCoupon: 'outcomes in the coupon',
  coupon: 'Coupon',
  bet: 'Bet',
  possiblePayout: 'Possible payout',
  collapseCoupon: 'Collapse coupon',
  basket: 'Basket',
  errorRole: 'You role must be "Player"',
  betSlipEmpty: 'The bet slip is currentlu epmty. Select quotas from the sports offer for adding bets to the bet slip.',
  emptyBasketHeader: 'Bet slip',
  totalStake: 'Total stake',
  maxBets: 'Maximum number of events (36) reached',
  pleaseAuthToMakeBet: 'To place bets, you need to be logged in',
  noBetSelected: 'No bet Selected',

  bookingCode: 'Booking code',
  loadBookedBet: 'Load Booked Bet',
  insertCodeToLoad: 'Insert the code to load',
  insertCodeHere: 'Insert the code here...',
  load: 'Load',
  save: 'Save',
  print: 'Print',
  close: 'Close',
  continue: 'Continue',
  printBooking: 'Print booking',
  saved: 'Saved',
  selectionSaved: 'Selection has been successfully saved',
  yourCode: 'Your code is',
  book: 'Book',
  betPlacedSuccess: 'Your bet has been placed',
  goodLuck: 'Good luck!',
  newBetslip: 'New betslip',
  keepSelections: 'Keep selections',
  bookingValWarning: 'Please type 4-letters booking code',
  bookNotFound: 'Booking code is not found',
  searchBy: 'Search by',
  win: 'Win',
}
