import { combineReducers } from 'redux'

import { IProfileState } from '../types'
import communications from './communications'
import data from './data'

export const reducer = combineReducers<IProfileState>({
  communications,
  data,
})
