import { slotIntegrationType } from 'modules/SlotsRoot/types/interfaces/Slots/GameCatalog'

export const uniqGameIdSeparator = '__'

export const uniqGameId = (integration: slotIntegrationType, provider: string, gameId: string): string =>
  `in:${integration}${uniqGameIdSeparator}pr:${provider}${uniqGameIdSeparator}id:${gameId}`

// TODO: когда будет время заменить на /in:(?'integration'.*){separator}pr:(?'provider'.*){separator}id:(?'gameId'.*)/
// и проверить всех провайдеров
export const getPureGameId = (gameId: string) =>
  gameId
    .split(uniqGameIdSeparator)
    .find(item => item.startsWith('id:'))
    ?.replace('id:', '') || gameId