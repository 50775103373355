import { combineReducers } from 'redux'

import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IBonusHistoryState } from '../types'

export default combineReducers<IBonusHistoryState['communications']>({
  getBonusHistory: makeCommunicationReducer(actions.getBonusHistory),
  getCashbackHistory: makeCommunicationReducer(actions.getCashbackHistory)
})
