/* eslint-disable camelcase */

export enum SliderPlatforms {
  WEB = 'web',
  MOBILE = 'mobile',
  NOT_FOUND = 'missedPlatform',
}

export type SliderPlatformsTypes = keyof typeof SliderPlatforms

export interface ISlideDTO {
  id: number
  type: SliderPlatformsTypes
  image: string
  link: string
  language: string
  is_active: boolean
  carousel_id: number
  auth_required: boolean
  order: number
}

export interface ISlide {
  id: number
  type: SliderPlatformsTypes
  image: string
  link: string
  language: string
  isActive: boolean
  carouselId: number
  authRequired: boolean
  order: number
}

export interface ICarouselDTO {
  id: number
  route: string
  slug: string
  project_slug: string
  is_active: boolean
}

export type Carousel = Record<string, Record<SliderPlatforms, Record<string, ISlide[]>>>

export interface ISliderItem {
  image: string
  route: string
  clickHandler: () => void
  title?: string
}
