import { useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectIsUserAuthenticated, selectUserBirthday } from 'features/auth/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { MODAL_ROUTE_PATH, MODAL_ROUTE_URLS } from '../../router/modalRoutes'

export const useConfirmBirthDate = () => {
  const { openedModals, pushNewModal, closeModal } = useModalContext()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const birthday = useSelector(selectUserBirthday, shallowEqual)

  const needOpenModal = useMemo(
    () => isAuth && !birthday && !openedModals.includes(MODAL_ROUTE_PATH.CONFIRM_BIRTHDAY),
    [birthday, isAuth, openedModals],
  )

  useEffect(() => {
    if (needOpenModal) {
      pushNewModal(MODAL_ROUTE_URLS.CONFIRM_BIRTHDAY())()
    }
  }, [openedModals.length, isAuth])

  useEffect(() => {
    if (birthday) {
      closeModal(MODAL_ROUTE_URLS.CONFIRM_BIRTHDAY())()
    }
  }, [birthday])
}
