import { AxiosError } from 'axios'

import { IListOfErrors, IMultiError } from 'shared/models/AppError'

export const convertInvalidField = (error: AxiosError): IListOfErrors => {
  const responseErrors: IMultiError = error.response?.data
  const url = error.request.responseURL
    .split('/')
    .filter((item: string) => item)
    .slice(-2)
    .join('_')

  const errors = Object.entries(responseErrors)
    .map(errField =>
      Array.isArray(errField[1])
        ? errField[1].map(errMess => ({
            ...errMess,
            code: `${url}_${errField[0]}_${errMess.code}`,
            field: errField[0],
          }))
        : errField[1],
    )
    .flat(1)

  return {
    listOfErrors: errors,
  }
}
