import { IPrizeItem, IPrizeItemDTO, ISpinWheelData } from 'shared/models/Wheel/Wheel.types'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { WheelGameConverter } from './WheelGameConverter'

class WheelGameApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/services/wheel`
    this.converter = new WheelGameConverter()
  }

  getSpinHistory = () =>
    this.sendQuery({
      type: QueryTypes.GET,
      url: `${this.baseUrl}/statistics/betshistory/`,
      converterSuccess: this.converter.convertSpinHistory,
    })

  getPrizesList = ({ currency }: { currency: string }) =>
    this.sendQuery<IPrizeItemDTO[], IPrizeItem[]>({
      type: QueryTypes.GET,
      url: `${this.baseUrl}/prizes/`,
      converterSuccess: data => this.converter.convertPrizesList({ data, currency }),
    })

  getPublicSettings = () =>
    this.sendQuery({
      type: QueryTypes.GET,
      url: `${this.baseUrl}/settings/public/`,
      converterSuccess: this.converter.convertPublicSettings,
    })

  spinWheel = (data: ISpinWheelData) =>
    this.sendQuery({
      type: QueryTypes.POST,
      data,
      url: `${this.baseUrl}/spin/`,
      converterSuccess: this.converter.convertSpinWheel,
    })
}

export default WheelGameApi
