export const line: { [key: string]: string } = {
  all: 'All matches',
  '1h': '1 hour',
  '3h': '3 hours',
  sport: 'Sport',
  prematch: 'Prematch',
  live: 'Live',
  line: 'Sport',
  loading: 'Loading',
  openSelected: 'Open selected',
  matches: 'Matches',
  showAll: 'Show all',
  collapse: 'Collapse',
  topEvents: 'Top events',
  upcomingEvents: 'Upcoming events',
  favorites: 'Favorites',
  cybersport: 'Cybersport',
  betNames: ['Bets', '1', 'X', '2', '1X', '12', '2X', 'U', 'Handicap', 'O', 'U', 'Total', 'O'],
  twoHours: 'Less than 2 hours',
  fourHours: 'Less than 4 hours',
  sixHours: 'Less than 6 hours',
  twelveHours: 'Less than 12 hours',
  twentyFourHours: 'Less than 24 hours',
  loadingError: 'Loading error',
  top: 'TOP',
  amountEvents: 'Amount events',
  betNamesMobile: {
    mainBets: ['1', 'X', '2'],
    total: ['U', 'Toal', 'O'],
    handicap: ['U', 'Handicap', 'O'],
    doubleChance: ['1X', '12', 'X2'],
  },
  moreEvents: 'More events',
  id: 'id',
  time: 'Time',
  events: 'Events',
  result: 'Result',
  mainCoefGroupNames: ['Result', 'Double chance', 'Handicap', 'Total'],
  mainCoefs: [
    ['1', 'X', '2'],
    ['1X', '12', 'X2'],
    [null, '+', '-'],
    [null, '+', '-'],
  ],
  lastMinute: 'Last minute',
}
