import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IJackpotsState } from '../types'

const initialState: IJackpotsState['data'] = {
  jackpotList: [],
}

export default createReducer(initialState, builder => {
  builder.addCase(actions.getJackpotList.success, (state, action) => {
    state.jackpotList = action.payload || []
  })
})
