export const live = {
  live: 'Live',
  prematch: 'Prematch',
  multiLive: 'Multi-live',
  betNames: ['Bets', '1', 'X', '2', '1X', '12', '2X', 'U', 'Handicap', 'O', 'U', 'Total', 'O'],
  betNamesGoal: ['1', 'X', '2', '1X', '12', '2X', 'U', 'Handicap', 'O', 'U', 'Total', 'O'],
  otherSports: 'Other sports',
  allSports: 'All sports',
  popularSports: 'Popular sports',
  betNamesMobile: {
    mainBets: ['1', 'X', '2'],
    total: ['U', 'Toal', 'O'],
    handicap: ['U', 'Handicap', 'O'],
    doubleChance: ['1X', '12', 'X2'],
  },
  mainBets: '1 x 2',
  total: 'Total',
  competitions: 'Competitions',
  handicap: 'Handicap',
  doubleChance: 'Double change',
  moreEvents: 'More events',
  typesSports: 'Types of sport',
  events: 'Events',
  amountEvents: 'Amount events',
  result: 'Result',
  mainCoefGroupNames: ['Result', 'Double chance', 'Handicap', 'Total'],
  mainCoefs: [
    ['1', 'X', '2'],
    ['1X', '12', 'X2'],
    [null, '+', '-'],
    [null, '+', '-'],
  ],
  liveEvents: 'Live events',
  upcomingEvents: 'Upcoming events',
  myLiveFavorites: 'My live favorites',
  liveBetting: 'Live betting',
  upcomingLive: 'Upcoming live events',
  noFavorite1: 'You have no events in the group',
  noFavorite2: 'An event can be added to the group',
  noFavorite3: 'by clicking on',
  noFavorite4: 'and removed with',
  noFavorite5: 'You can select a maximum of 10 events',
  topLeagues: 'Top leagues',
  viewAll: 'View all',
}
