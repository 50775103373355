import dayjs from 'dayjs'

import { IBonusHistoryItemDTO, IConvertedBonusHistory } from 'shared/models/BonusSystem'

import { IDefaultDataResponse } from '../PaymentApi/types/types'

export class BonusSystemConverter {
  convertBonusHistory = (data: IDefaultDataResponse<IBonusHistoryItemDTO>): IConvertedBonusHistory => {
    const bonusHistory =
      data?.results?.map(item => ({
        id: item.id,
        amount: +(+item.amount).toFixed(2),
        amountCurrency: item.amount_currency,
        date: dayjs(item.date).format('DD.MM.YYYY HH:mm'),
        type: item.type,
      })) || []

    return {
      page: data.page,
      count: data.count,
      history: bonusHistory,
    }
  }
}
